import { SmileOutlined } from '@ant-design/icons'
import { Badge, Button, ConfigProvider, Modal, Result, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import {
  useFetchNotification,
  useFetchNotificationDetail,
} from '../../../Api/Notice'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import ConfirmDeleteAlert from './ConfirmDeleteAlert'
import DetailNotification from './DetailNotification'
import FormEditAlert from './FormEditAlert'
import FormNewAlert from './FormNewAlert'

interface DataType {
  key: React.Key
  time: string
  title: string
  status: boolean | string
  description: string
}

const AlertMng: React.FC = () => {
  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openAlertDetail, setOpenAlertDetail] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [valueForEdit, setValueForEdit] = useState('')
  const [IDdelete, setIDdelete] = useState('')
  const [noticeShowDetailID, setNoticeShowDetailID] = useState('')
  const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false)

  const Navigate = useNavigate()

  const screenHeight = window.innerHeight

  let pageSize
  switch (true) {
    case screenHeight < 600:
      pageSize = 5
      break
    case screenHeight < 700:
      pageSize = 6
      break
    case screenHeight < 800:
      pageSize = 7
      break
    case screenHeight < 900:
      pageSize = 8
      break
    case screenHeight < 980:
      pageSize = 9
      break
    default:
      pageSize = 10
      break
  }

  //get current day for status
  const currentDay = dayjs().startOf('day').format('YYYYMMDD')

  // open/close modal
  const showModal = () => {
    setOpenNew(true)
  }
  const closeModal = () => {
    setOpenNew(false)
    setOpenEdit(false)
  }
  const onCloseConfirm = () => {
    setConfirmDelete(false)
  }

  //focus addNew button
  const addBtn = useRef<HTMLButtonElement | null>(null)
  useEffect(() => {
    addBtn.current?.focus()
  }, [])

  //focus button
  const btnNewRef = useRef<any>()
  const btnEditRef = useRef<any>()
  useEffect(() => {
    if (openEdit) {
      btnEditRef?.current?.focus()
    }
  }, [openEdit])

  //auto focus close btn
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 13) {
        setOpenAlertDetail(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  //call API
  const {
    data: allNotification,
    isLoading: allNotificationLoading,
    isError: allNotificationError,
  } = useFetchNotification()

  interface itemNoticeType {
    key: number
    id: number | string
    updatedDate: any
    title: string
    content: string
    publishFrom: string
    publishTo?: string
    link?: string
  }

  //handle data for table
  const dataConvert =
    allNotification &&
    allNotification.map((item: itemNoticeType, index: number) => ({
      key: index,
      id: item.id,
      time: {
        date: dayjs(item.updatedDate).format('YYYY-MM-DD'),
        time: dayjs(item.updatedDate).format('HH:mm:ss'),
      },
      title: { from: item.publishFrom, to: item.publishTo, title: item.title },
      titleEdit: item.title,
      description: item.content,
      from: item.publishFrom,
      to: item.publishTo,
      link: item.link,
      countFile: item?.link ? item.link?.split(',').length : 0,
    }))

  //detail
  const { data: notificationDetail, isLoading: notificationDetailLoading } =
    useFetchNotificationDetail(noticeShowDetailID)

  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 40,
      render: (item: number) => <span>{item + 1}</span>,
      className: 'index-row',
    },
    {
      title: '日付',
      dataIndex: 'time',
      key: 'time',
      width: 120,
      render: (time) => (
        <>
          <p style={{ margin: '0px' }}>{time.date}</p>
          <p style={{ margin: '0px' }}>{time.time}</p>
        </>
      ),
    },

    {
      title: 'タイトル',
      dataIndex: 'title',
      className: 'text-limited',
      key: 'title',
      render(value, record: any) {
        return (
          <div
            onClick={() => {
              setNoticeShowDetailID(record?.id)
              setOpenAlertDetail(true)
            }}
            style={{
              display: 'flex',
              justifyContent: 'start',
              gap: '4px',
              cursor: 'pointer',
            }}
          >
            {value.to >= currentDay >= value.from && <Badge color="green" />}
            <div className="text_limited--title">{value.title}</div>
          </div>
        )
      },
    },

    {
      title: 'ファイル数',
      dataIndex: 'countFile',
      key: 'countFile',
      width: 110,
    },
    {
      title: 'アクション',
      key: 'x',
      render: (value: any) => (
        <>
          <Button
            onClick={(e: any) => {
              e.stopPropagation()
              setOpenEdit(true)
              setValueForEdit(value)
            }}
            style={{ marginRight: '10px' }}
          >
            修正
          </Button>
          <Button
            onClick={(e: any) => {
              e.stopPropagation()
              setConfirmDelete(true)
              setIDdelete(value.id)
            }}
          >
            削除
          </Button>
        </>
      ),
      width: 180,
    },
  ]

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>データがありません</p>
    </div>
  )

  if (allNotificationError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="エラーが発生しました。"
        extra={
          <Button onClick={() => Navigate(-1)} type="primary">
            戻る
          </Button>
        }
      />
    )
  }

  return (
    <AlertModalContainer>
      <Button
        style={{
          height: '32px',
          width: '100px',

          marginBottom: '10px',
          background: 'var(--theme-color)',
          color: 'White',
        }}
        ref={addBtn}
        onClick={showModal}
      >
        新規
      </Button>
      <TableStyle>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            loading={allNotificationLoading}
            columns={columns}
            dataSource={dataConvert}
            pagination={{ defaultPageSize: pageSize }}
          />
        </ConfigProvider>
      </TableStyle>
      <Modal
        open={openNew}
        title={<TitleModal>お知らせ新規作成</TitleModal>}
        onCancel={closeModal}
        width={800}
        centered
        footer={
          <FooterModalWrapper>
            <Button
              key={'submit'}
              form="notification"
              htmlType="submit"
              type="primary"
              ref={btnNewRef}
              loading={modalBtnLoading}
            >
              登録する
            </Button>
            <Button onClick={closeModal}>戻る</Button>
          </FooterModalWrapper>
        }
      >
        <FormNewAlert
          onClose={() => closeModal()}
          onLoading={(value: boolean) => setModalBtnLoading(value)}
        />
      </Modal>
      <Modal
        open={openEdit}
        title={<TitleModal>お知らせ修正</TitleModal>}
        onCancel={closeModal}
        width={800}
        centered
        footer={
          <FooterModalWrapper>
            <Button
              key={'submit'}
              form="notificationEdit"
              htmlType="submit"
              type="primary"
              ref={btnEditRef}
              loading={modalBtnLoading}
            >
              修正する
            </Button>
            <Button onClick={closeModal}>戻る</Button>
          </FooterModalWrapper>
        }
      >
        <FormEditAlert
          data={valueForEdit}
          onClose={() => {
            closeModal()
          }}
          onLoading={(value: boolean) => setModalBtnLoading(value)}
        />
      </Modal>
      {confirmDelete && (
        <ConfirmDeleteAlert
          OnClose={() => onCloseConfirm()}
          IDdelete={IDdelete}
        />
      )}
      {openAlertDetail && (
        <DetailNotification
          data={notificationDetail}
          onClose={() => setOpenAlertDetail(false)}
          open={openAlertDetail}
          loading={notificationDetailLoading}
        />
      )}
    </AlertModalContainer>
  )
}
export default withErrorBoundary(AlertMng, {
  FallbackComponent: ErrorComponent,
})

const AlertModalContainer = styled.div`
  .ant-modal-content {
    background: red !important;
  }
  .modal-confirm {
    background: red !important;
  }
`

const FooterModalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const TitleModal = styled.p`
  text-align: center;
`
const TableStyle = styled.div`
  max-height: 88vh;
  overflow-y: auto;

  .index-row {
    padding: 0px !important;
    text-align: center !important;
  }

  .ant-table-expanded-row-level-1 {
    .ant-table-cell {
      padding-left: 136px;
    }
  }

  .ant-badge {
    line-height: 18px;
  }

  .text-limited {
    .text_limited--title {
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 18px;
      -webkit-line-clamp: 2;
      height: 36px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
`

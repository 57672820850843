import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import { routes_layout } from './routes'
import { PATH } from '../constants/common'
import { useContext } from 'react'
import { TypeAccount } from '../Components/Context'

const RouterConfig = () => {
  const { isAdmin } = useContext(TypeAccount)
  return (
    <Router>
      <Routes>
        {routes_layout.map((route, index) => {
          if (
            (route.path === PATH.ICHIRAN || route.path === PATH.INVOICE) &&
            !isAdmin
          ) {
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to={PATH.ELECTRIC} />}
              />
            )
          } else {
            return (
              <Route key={index} path={route.path} element={route.element} />
            )
          }
        })}
      </Routes>
    </Router>
  )
}

export default RouterConfig

import styled from 'styled-components'
import { handleDataCo2 } from '../../HandleDataIchiran'
import ButtonTotalCo2 from '../../../UI/Button/ButtonTotalCo2'
import { Round, formatNumber } from '../../../../Utils/Helper/FormatNumber'

interface props {
  mode: 'day' | 'month' | 'year' | 'yearly'
  data?: any
  dataNestChild?: any
  loading: boolean
}

function ButtonTotal({ mode, data, dataNestChild, loading }: props) {
  const value = handleDataCo2(data, dataNestChild, false)
  const value1 = value?.[0]?.gridPowerCo2Emissions
  const value2 = value?.[0]?.modelTCo2Emissions
  const value3 = value?.[0]?.photovoltaicRenewableEnergyRatio
  const isChangeUnit = value1 >= 10000 || value2 >= 10000

  return (
    <>
      <ButtonRow style={{ opacity: loading ? 0.5 : 1 }}>
        {mode === 'month' && (
          <>
            <ButtonWrapper>
              <ButtonTotalCo2
                title="月間CO2"
                strong="排出量"
                sup="※１"
                value={formatNumber(
                  (isChangeUnit ? value1 / 1000 : Round(value1)) || 0
                )}
                unit={isChangeUnit ? 't-CO2' : 'kg-CO2'}
              />
              <span>
                ※１系統電力電気使用量に各年
                <br />
                度のCO2排出係数を乗じて算出
              </span>
            </ButtonWrapper>
            <ButtonWrapper>
              <ButtonTotalCo2
                title="月間CO2"
                strong="削減量"
                sup="※2"
                value={formatNumber(
                  (isChangeUnit ? value2 / 1000 : Round(value2)) || 0
                )}
                unit={isChangeUnit ? 't-CO2' : 'kg-CO2'}
              />
              <span>
                ※２MODEL-T電気使用量に各年
                <br />
                度のCO2排出係数を乗じて算出
              </span>
            </ButtonWrapper>
            <ButtonWrapper>
              <ButtonTotalCo2
                title="再生可能エネルギー比率"
                sup="※3"
                percent={Number.isNaN(value3) ? 0 : value3?.toFixed(1)}
                unit="%"
              />
              <span>
                ※３月間合計電気使用量のうち
                <br />
                太陽電気使用量が占める比率
              </span>
            </ButtonWrapper>
          </>
        )}
        {mode === 'year' && (
          <>
            <ButtonWrapper>
              <ButtonTotalCo2
                title="年間CO2"
                strong="排出量"
                sup="※１"
                value={formatNumber(
                  (isChangeUnit ? value1 / 1000 : Round(value1)) || 0
                )}
                unit={isChangeUnit ? 't-CO2' : 'kg-CO2'}
              />
              <span>
                ※１系統電力電気使用量に各年
                <br />
                度のCO2排出係数を乗じて算出
              </span>
            </ButtonWrapper>
            <ButtonWrapper>
              <ButtonTotalCo2
                title="年間CO2"
                strong="削減量"
                sup="※2"
                value={formatNumber(
                  (isChangeUnit ? value2 / 1000 : Round(value2)) || 0
                )}
                unit={isChangeUnit ? 't-CO2' : 'kg-CO2'}
              />
              <span>
                ※２MODEL-T電気使用量に各年
                <br />
                度のCO2排出係数を乗じて算出
              </span>
            </ButtonWrapper>
            <ButtonWrapper>
              <ButtonTotalCo2
                title="再生可能エネルギー比率"
                sup="※3"
                percent={Number.isNaN(value3) ? 0 : value3?.toFixed(1)}
                unit="%"
              />
              <span>
                ※３年間合計電気使用量のうち
                <br />
                太陽電気使用量が占める比率
              </span>
            </ButtonWrapper>
          </>
        )}
      </ButtonRow>
    </>
  )
}

export default ButtonTotal

const ButtonRow = styled.div`
  flex-wrap: wrap;
  margin: 20px 0 40px;
  display: flex;
  gap: 40px;
  justify-content: center;
  @media (max-width: 978px) {
    gap: 20px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;

  flex-direction: column;
  span {
    margin-top: 10px;
    max-width: 240px;
    font-size: 14px;
    text-align: center;
  }
`

import { ConfigProvider } from 'antd'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import {
  checkChangeUnitCo2,
  formatColumTableCo2,
} from '../../Utils/Helper/HandleData'
import { TableData } from '../Styled/MainPage.style'
import { withErrorBoundary } from 'react-error-boundary'
import { customizeRenderEmpty } from '../../Utils/CustomHook/CustomNoData'
import { useElementWidth } from '../../Utils/CustomHook/useGetScreenWidth'

interface Prop {
  data: any
  loading: boolean
  fetchingOption: boolean
  modeTime: '時刻' | '日' | '月' | '年'
}

const Co2Detail = ({ data, loading, fetchingOption, modeTime }: Prop) => {
  let renderData: any[] = data?.data
    ? data.data.map((item: any, index: number) => {
        return { ...item, key: index }
      })
    : []

  const existingData = renderData.find((item) => item.title === '合計')
  if (!existingData && data) {
    renderData.unshift({
      key: '合計',
      title: '合計',
      gridPower: data?.totalGridPower,
      gridElectricityConsumption: data?.totalGridElectricityConsumption || 0,
      modelTElectricityUsage: data?.totalModelTElectricityUsage || 0,
      gridPowerCo2Emissions: data?.totalGridPowerCo2Emissions || 0,
      modelTCo2Emissions: data?.totalModelTCo2Emissions || 0,
      photovoltaicRenewableEnergyRatio:
        data?.totalPhotovoltaicRenewableEnergyRatio || 0,
    })
  }

  const isChangeUnit = checkChangeUnitCo2(renderData, false)
  const columns = formatColumTableCo2(isChangeUnit, modeTime)
  const loadingTable = loading || fetchingOption
  const screenWidth = useElementWidth('co2Page')

  return (
    <>
      <div
        style={{
          maxWidth: screenWidth ? screenWidth * 0.37 : 400,
          minHeight: '480px',
          fontSize: '13px',
          opacity: loadingTable ? '0.5' : '1',
        }}
      >
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <TableData
            columns={columns}
            dataSource={renderData}
            pagination={false}
            bordered
            scroll={{ x: true, y: 400 }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default withErrorBoundary(Co2Detail, {
  FallbackComponent: ErrorComponent,
})

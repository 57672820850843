import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Header from '../../Components/UI/Header/Header'
import TimeLine from '../../Components/UI/Header/TimeLine'
import AccountMng from '../../Components/UI/SelectCompanies'
import ElectricChart from '../../Components/Electrics/ElectricChart'
import TableDetail from '../../Components/Electrics/ElectricDetail'
import TimePickerElectric from '../../Components/Electrics/TimePicker'
import {
  useFetchChildElectricDay,
  useFetchChildElectricMonth,
  useFetchChildElectricYear,
  useFetchChildElectricYearly,
} from '../../Api/Electric'
import { Container } from '../../Components/Styled/Pages.style'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'

const Electric = () => {
  const [checkboxValueYear, setCheckboxValueYear] = useState<boolean>(false)
  const [checkboxValueMonth, setCheckboxValueMonth] = useState<boolean>(false)
  const [checkboxValueDay, setCheckboxValueDay] = useState<boolean>(false)

  const [fetchOption, setFetchOption] = useState<boolean>(false)
  const [modeTime, setModeTime] = useState(
    sessionStorage.getItem('electricMode') || 'day'
  )
  const [childSelected, setChildSelected] = useState<string>('')

  //set default year/month/week/day
  const [yearData, setYearData] = useState(
    sessionStorage.getItem('YearSelected') || new Date().getFullYear()
  )

  const [monthSelected, setMonthSelected] = useState<string>(
    sessionStorage.getItem('MonthSelected') ||
      dayjs().startOf('month').format('YYYY-MM')
  )

  const [daySelected, setDaySelected] = useState(
    sessionStorage.getItem('DaySelected') ||
      dayjs().startOf('day').format('YYYY-MM-DD')
  )

  const handleChangeMode = useCallback((mode: string) => {
    setModeTime(mode)
    sessionStorage.setItem('electricMode', mode)
  }, [])

  const onFetchOption = useCallback((value: boolean) => {
    setFetchOption(value)
  }, [])

  const setSelectForCallData = useCallback((value: any) => {
    setChildSelected(value)
  }, [])

  //clear check box
  useEffect(() => {
    setCheckboxValueYear(false)
    setCheckboxValueMonth(false)

    setCheckboxValueDay(false)
  }, [modeTime])

  // handle check box
  function handleCheckboxYearChange(
    checked: React.ChangeEvent<HTMLInputElement>
  ) {
    setCheckboxValueYear(checked.target.checked)
  }
  function handleCheckboxMonthChange(
    checked: React.ChangeEvent<HTMLInputElement>
  ) {
    setCheckboxValueMonth(checked.target.checked)
  }

  function handleCheckboxDayChange(
    checked: React.ChangeEvent<HTMLInputElement>
  ) {
    setCheckboxValueDay(checked.target.checked)
  }

  //callback function to get time value
  const handleYearDataChange = useCallback((data: any) => {
    const dateString = dayjs(data).format('YYYY')
    setYearData(dateString)
    sessionStorage.setItem('YearSelected', dateString)
  }, [])

  const handleChangeSelectMonth = useCallback((data: any) => {
    const dateString = dayjs(data).format('YYYY-MM')
    setMonthSelected(dateString)
    sessionStorage.setItem('MonthSelected', dateString)
  }, [])

  const handleChangeSelectDay = useCallback((data: any) => {
    const dateString = dayjs(data).format('YYYY-MM-DD')
    setDaySelected(dateString)
    sessionStorage.setItem('DaySelected', dateString)
  }, [])

  //get data by year
  const { data: ChildYearData, isLoading: isChildYearDataLoading } =
    useFetchChildElectricYear(childSelected, yearData, modeTime === 'year')

  //get data by month
  const { data: ChildMonthData, isLoading: isChildMonthDataLoading } =
    useFetchChildElectricMonth(
      childSelected,
      monthSelected,
      modeTime === 'month'
    )

  //get data by yearly
  const { data: ChildYearlyData, isLoading: isChildYearlyDataLoading } =
    useFetchChildElectricYearly(childSelected, modeTime === 'yearly')

  //get data by day
  const { data: ChildDayData, isLoading: isChildDayDataLoading } =
    useFetchChildElectricDay(childSelected, daySelected, modeTime === 'day')

  //set default year/month/week/day for compare
  const [yearCompare, setYearCompare] = useState<number | string | null>(
    sessionStorage.getItem('YearSelectedCompare') || null
  )
  const [monthCompare, setMonthCompare] = useState<string | null>(
    sessionStorage.getItem('MonthSelectedCompare') || null
  )
  const [dayCompare, setDayCompare] = useState<number | string | null>(
    sessionStorage.getItem('DaySelectedCompare') || null
  )

  //callback function to get time compare
  const handleCompareByYear = useCallback((data: any) => {
    const dateString = dayjs(data).format('YYYY')
    setYearCompare(dateString)
    sessionStorage.setItem('YearSelectedCompare', dateString)
  }, [])

  const handleCompareByMonth = useCallback((data: any) => {
    const dateString = dayjs(data).format('YYYY-MM')
    setMonthCompare(dateString)
    sessionStorage.setItem('MonthSelectedCompare', dateString)
  }, [])

  const handleCompareByDay = useCallback((data: any) => {
    const dateString = dayjs(data).format('YYYY-MM-DD')
    setDayCompare(dateString)
    sessionStorage.setItem('DaySelectedCompare', dateString)
  }, [])

  //get data compare
  const { data: yearDataCompare, isLoading: yearCompareLoading } =
    useFetchChildElectricYear(
      childSelected,
      yearCompare,
      modeTime === 'year' && checkboxValueYear
    )
  const { data: monthDataCompare, isLoading: monthCompareLoading } =
    useFetchChildElectricMonth(
      childSelected,
      monthCompare,
      modeTime === 'month' && checkboxValueMonth
    )
  const { data: dayDataCompare, isLoading: dayCompareLoading } =
    useFetchChildElectricDay(
      childSelected,
      dayCompare,
      modeTime === 'day' && checkboxValueDay
    )

  return (
    <WrapperBody>
      <Header defaultActiveBtn={0} />
      <TimeLine
        page="electric"
        modeTime={modeTime}
        onChange={(mode: string) => handleChangeMode(mode)}
      />
      <AccountMng
        option={(value: any) => setSelectForCallData(value)}
        onFetching={onFetchOption}
        page="Electric"
      />
      <TimePickerElectric
        modeTime={modeTime}
        checkedBoxDay={checkboxValueDay}
        checkedBoxMonth={checkboxValueMonth}
        checkedBoxYear={checkboxValueYear}
        onCheckboxChangeDay={handleCheckboxDayChange}
        onCheckboxChangeMonth={handleCheckboxMonthChange}
        onCheckboxChangeYear={handleCheckboxYearChange}
        onDayChange={handleChangeSelectDay}
        onDayCompare={handleCompareByDay}
        onMonthChange={handleChangeSelectMonth}
        onMonthCompare={handleCompareByMonth}
        onYearChange={handleYearDataChange}
        onYearCompare={handleCompareByYear}
      />

      <Container className="electricPage">
        {(modeTime === 'day' || !modeTime) && (
          <>
            <div className="Power__Detail">
              <TableDetail
                data={ChildDayData}
                loading={isChildDayDataLoading}
                fetchingOption={fetchOption}
                modeTime="時刻"
              />
            </div>
            <div className="Power__Chart">
              <ElectricChart
                checkboxValue={checkboxValueDay}
                data={ChildDayData}
                dataCompare={dayDataCompare}
                loadingData={isChildDayDataLoading}
                loadingCompare={dayCompareLoading}
                fetchingOption={fetchOption}
                firstName="一時間単位の使用電力"
                lastName="を表示しています。"
                modeView={modeTime}
              />
            </div>
          </>
        )}
        {modeTime === 'month' && (
          <>
            <div className="Power__Detail">
              <TableDetail
                data={ChildMonthData}
                loading={isChildMonthDataLoading}
                fetchingOption={fetchOption}
                modeTime="日"
              />
            </div>
            <div className="Power__Chart">
              <ElectricChart
                checkboxValue={checkboxValueMonth}
                data={ChildMonthData}
                dataCompare={monthDataCompare}
                loadingData={isChildMonthDataLoading}
                loadingCompare={monthCompareLoading}
                fetchingOption={fetchOption}
                firstName="日毎の使用電力量"
                lastName="を表示しています。"
                modeView={modeTime}
              />
            </div>
          </>
        )}
        {modeTime === 'year' && (
          <>
            <div className="Power__Detail">
              <TableDetail
                data={ChildYearData}
                loading={isChildYearDataLoading}
                fetchingOption={fetchOption}
                modeTime="月"
              />
            </div>
            <div className="Power__Chart">
              <ElectricChart
                checkboxValue={checkboxValueYear}
                data={ChildYearData}
                dataCompare={yearDataCompare}
                loadingData={isChildYearDataLoading}
                loadingCompare={yearCompareLoading}
                fetchingOption={fetchOption}
                firstName="月毎の使用電力量"
                lastName="を表示しています。"
                modeView={modeTime}
              />
            </div>
          </>
        )}
        {modeTime === 'yearly' && (
          <>
            <div className="Power__Detail">
              <TableDetail
                data={ChildYearlyData}
                loading={isChildYearlyDataLoading}
                fetchingOption={fetchOption}
                modeTime="年"
              />
            </div>
            <div className="Power__Chart">
              <ElectricChart
                data={ChildYearlyData}
                loadingData={isChildYearlyDataLoading}
                fetchingOption={fetchOption}
                firstName="年毎の使用電力量"
                lastName="を表示しています。"
                modeView={modeTime}
              />
            </div>
          </>
        )}
      </Container>
    </WrapperBody>
  )
}

export default withErrorBoundary(Electric, {
  FallbackComponent: ErrorComponent,
})

const WrapperBody = styled.div`
  display: block;
  margin: auto;
`

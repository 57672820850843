export const RenderLegend = (props: any) => {
  const { payload, handleClickLegend, isDataKeyActive, maxWidth } = props

  return (
    <div
      style={{
        display: 'flex',
        padding: '0 60px',
        justifyContent: 'space-evenly',
        gap: '10px',
        margin: '8px 0',
        alignItems: 'center',
      }}
    >
      {payload.map((entry: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleClickLegend(entry)}
          >
            <svg width={30} height={30}>
              <pattern
                id="pattern-stripe"
                width="8"
                height="8"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)"
              >
                <rect
                  width="4"
                  height="8"
                  transform="translate(0,0)"
                  fill="white"
                ></rect>
              </pattern>
              <mask id="mask-stripe">
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="url(#pattern-stripe)"
                />
              </mask>
              {entry.dataKey == 'ModelT' ||
              entry.dataKey == 'modelTCo2Emissions' ? (
                <rect
                  fill={
                    isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                  }
                  mask="url(#mask-stripe)"
                  x={0}
                  y={12}
                  width={28}
                  height={8}
                />
              ) : (
                <rect
                  fill={
                    isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                  }
                  // mask="url(#mask-stripe)"
                  x={0}
                  y={12}
                  width={28}
                  height={8}
                />
              )}
              {/* <text x={0} y={10} dx={25} dy={10} fill="black">
              {entry?.value}
            </text> */}
            </svg>
            <span
              style={{
                fontSize: '12px',
                color: `${
                  isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                }`,
              }}
            >
              {entry.value}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export const RenderLegendIchiranChart = (props: any) => {
  const { payload, handleClickLegend, isDataKeyActive, maxWidth } = props

  return (
    <div
      style={{
        position: 'sticky',
        left: 0,
        maxWidth: maxWidth - 20,

        display: 'flex',
        padding: '0 20px',
        justifyContent: 'center',
        gap: '20px',
        margin: '8px 0',
        alignItems: 'center',
      }}
    >
      {payload.map((entry: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleClickLegend(entry)}
          >
            <svg width={30} height={30}>
              <pattern
                id="pattern-stripe"
                width="8"
                height="8"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)"
              >
                <rect
                  width="4"
                  height="8"
                  transform="translate(0,0)"
                  fill="white"
                ></rect>
              </pattern>
              <mask id="mask-stripe">
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="url(#pattern-stripe)"
                />
              </mask>
              {entry.dataKey == 'ModelT' ||
              entry.dataKey == 'modelTCo2Emissions' ? (
                <rect
                  fill={
                    isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                  }
                  mask="url(#mask-stripe)"
                  x={0}
                  y={12}
                  width={28}
                  height={8}
                />
              ) : (
                <rect
                  fill={
                    isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                  }
                  // mask="url(#mask-stripe)"
                  x={0}
                  y={12}
                  width={28}
                  height={8}
                />
              )}
              {/* <text x={0} y={10} dx={25} dy={10} fill="black">
              {entry?.value}
            </text> */}
            </svg>
            <span
              style={{
                fontSize: '12px',
                color: `${
                  isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                }`,
              }}
            >
              {entry.value}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export const CandyBar = (props: any) => {
  const { x: oX, y: oY, width: oWidth, height: oHeight, value, fill } = props
  let x = oX
  let y = oHeight < 0 ? oY + oHeight : oY
  let width = oWidth
  let height = Math.abs(oHeight)

  return (
    <rect
      fill={fill}
      mask="url(#mask-stripe)"
      x={x}
      y={y}
      width={width}
      height={height}
    />
  )
}

export const RenderLegendCo2Chart = (props: any) => {
  const { payload, handleClickLegend, isDataKeyActive, maxWidth } = props

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        // alignItems: 'center',
      }}
    >
      {payload.map((entry: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleClickLegend(entry)}
          >
            <svg width={30} height={30}>
              <pattern
                id="pattern-stripe"
                width="8"
                height="8"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)"
              >
                <rect
                  width="4"
                  height="8"
                  transform="translate(0,0)"
                  fill="white"
                ></rect>
              </pattern>
              <mask id="mask-stripe">
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="url(#pattern-stripe)"
                />
              </mask>
              {entry.dataKey == 'ModelT' ||
              entry.dataKey == 'modelTCo2Emissions' ? (
                <rect
                  fill={
                    isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                  }
                  mask="url(#mask-stripe)"
                  x={0}
                  y={12}
                  width={28}
                  height={8}
                />
              ) : (
                <rect
                  fill={
                    isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                  }
                  // mask="url(#mask-stripe)"
                  x={0}
                  y={12}
                  width={28}
                  height={8}
                />
              )}
              {/* <text x={0} y={10} dx={25} dy={10} fill="black">
              {entry?.value}
            </text> */}
            </svg>
            <span
              style={{
                fontSize: '12px',
                color: `${
                  isDataKeyActive(entry.dataKey) ? entry?.color : '#cccccc'
                }`,
              }}
            >
              {entry.value}
            </span>
          </div>
        )
      })}
    </div>
  )
}

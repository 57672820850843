import React from 'react'
import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import { disabledDate } from '../../Utils/Helper/DisableDay'
import { Checkbox, DatePicker } from 'antd'
import styled from 'styled-components'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'

interface pageProps {
  modeTime: 'year' | 'month' | 'day' | any

  checkedBoxDay?: boolean
  onCheckboxChangeDay?: any
  checkedBoxMonth?: boolean
  onCheckboxChangeMonth?: any
  checkedBoxYear?: boolean
  onCheckboxChangeYear?: any

  onDayChange?: any
  onDayCompare?: any

  onMonthChange?: any
  onMonthCompare?: any

  onYearChange?: any
  onYearCompare?: any
}
const TimePickerElectric: React.FC<pageProps> = ({
  modeTime,
  checkedBoxDay,
  onCheckboxChangeDay,
  checkedBoxMonth,
  onCheckboxChangeMonth,
  checkedBoxYear,
  onCheckboxChangeYear,

  onDayChange,
  onDayCompare,
  onMonthChange,
  onMonthCompare,
  onYearChange,
  onYearCompare,
}) => {
  return (
    <>
      {modeTime === 'day' && (
        <DatePick>
          <DatePickerWrapper
            allowClear={false}
            locale={locale}
            onChange={onDayChange}
            picker="date"
            defaultValue={
              sessionStorage.getItem('DaySelected')
                ? dayjs(sessionStorage.getItem('DaySelected')).startOf('day')
                : dayjs().startOf('day')
            }
            defaultPickerValue={dayjs().startOf('day')}
            disabledDate={disabledDate}
          />
          <CheckBoxWrapper
            checked={checkedBoxDay}
            onChange={onCheckboxChangeDay}
          >
            比較
          </CheckBoxWrapper>
          <DatePickerWrapper
            disabled={!checkedBoxDay}
            allowClear={false}
            locale={locale}
            onChange={onDayCompare}
            picker="date"
            defaultValue={
              sessionStorage.getItem('DaySelectedCompare')
                ? dayjs(sessionStorage.getItem('DaySelectedCompare')).startOf(
                    'day'
                  )
                : undefined
            }
            defaultPickerValue={dayjs().startOf('day')}
            disabledDate={disabledDate}
            placeholder="クリック選択"
          />
        </DatePick>
      )}
      {modeTime === 'month' && (
        <DatePick>
          <DatePickerWrapper
            allowClear={false}
            locale={locale}
            onChange={onMonthChange}
            picker="month"
            defaultValue={
              sessionStorage.getItem('MonthSelected')
                ? dayjs(sessionStorage.getItem('MonthSelected')).startOf(
                    'month'
                  )
                : dayjs().startOf('month')
            }
            defaultPickerValue={dayjs().startOf('month')}
            disabledDate={disabledDate}
          />
          <CheckBoxWrapper
            checked={checkedBoxMonth}
            onChange={onCheckboxChangeMonth}
          >
            比較
          </CheckBoxWrapper>
          <DatePickerWrapper
            disabled={!checkedBoxMonth}
            allowClear={false}
            locale={locale}
            onChange={onMonthCompare}
            picker="month"
            disabledDate={disabledDate}
            placeholder="クリック選択"
            defaultValue={
              sessionStorage.getItem('MonthSelectedCompare')
                ? dayjs(sessionStorage.getItem('MonthSelectedCompare')).startOf(
                    'day'
                  )
                : undefined
            }
          />
        </DatePick>
      )}
      {modeTime === 'year' && (
        <DatePick>
          <DatePickerWrapper
            allowClear={false}
            locale={locale}
            onChange={onYearChange}
            picker="year"
            defaultValue={
              sessionStorage.getItem('YearSelected')
                ? dayjs(sessionStorage.getItem('YearSelected')).startOf('year')
                : dayjs().startOf('year')
            }
            defaultPickerValue={dayjs().startOf('year')}
            disabledDate={disabledDate}
          />
          <CheckBoxWrapper
            checked={checkedBoxYear}
            onChange={onCheckboxChangeYear}
          >
            比較
          </CheckBoxWrapper>
          <DatePickerWrapper
            disabled={!checkedBoxYear}
            allowClear={false}
            locale={locale}
            onChange={onYearCompare}
            picker="year"
            disabledDate={disabledDate}
            placeholder="クリック選択"
            defaultValue={
              sessionStorage.getItem('YearSelectedCompare')
                ? dayjs(sessionStorage.getItem('YearSelectedCompare')).startOf(
                    'day'
                  )
                : undefined
            }
          />
        </DatePick>
      )}
      {modeTime === 'yearly' && ''}
    </>
  )
}

export default withErrorBoundary(TimePickerElectric, {
  FallbackComponent: ErrorComponent,
})

const DatePick = styled.div`
  width: 37.4%;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 100%;
  }
`
const CheckBoxWrapper = styled(Checkbox)`
  padding-top: 4px;
  span:last-child {
    padding-inline-end: 0px !important;
    font-weight: bold;
  }
`
const DatePickerWrapper = styled(DatePicker)`
  flex: 1;
  background: var(--theme-color);
  input {
    color: white !important;
    font-size: 14px !important;
    @media (max-width: 1279px) {
      font-size: 12px !important;
    }
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
  }
  input::placeholder {
    color: #f5f5f5 !important;
  }
  .ant-picker-suffix {
    color: white;
  }
  &.ant-picker-disabled {
    background: #c7cbd3 !important;
  }
  .ant-picker-month-btn {
    margin-inline-start: 4px !important;
  }
`

import { saveAs } from 'file-saver'
import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

//Down Report Power
export const DownloadTemplate = async (yearSelect: string) => {
  await api({
    method: 'get',
    url: `${ENDPOINT}/Co2/CO2TemplateDown?year=${yearSelect}`,
    responseType: 'arraybuffer',
  }).then((res) => {
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, `係数入力.xlsx`)
  })
}

//fetch data excel CO2 management
export const useGetDataCo2MNG = (year: string) => {
  const fetchData = async () => {
    const response = await api
      .get(`${ENDPOINT}/Co2/CO2Data?year=${year}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['DataCo2Management', year],
    queryFn: () => fetchData(),
    staleTime: 10 * 1000,
    enabled: !!year,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

import { Modal, Spin } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../../Utils/CustomHook/CatchErrorComponent'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

interface detailProps {
  data: any
  onClose: () => void
  open: boolean
  loading: boolean
}

const DetailNotification: React.FC<detailProps> = ({
  data,
  onClose,
  open,
  loading,
}) => {
  const [listLink, setListLink] = useState([])
  const [countFile, setCountFile] = useState<number | null>(null)
  useEffect(() => {
    if (data?.link?.length > 0) {
      setListLink(data.link?.split(','))
      setCountFile(data.link?.split(',').length)
    } else {
      setListLink([])
      setCountFile(null)
    }
  }, [data])

  return (
    <>
      {loading ? (
        <Spin style={{ position: 'fixed', top: '40%', left: '50%' }} />
      ) : (
        <ModalWrapper
          okText={'閉じる'}
          cancelButtonProps={{ style: { display: 'none' } }}
          title={
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px 24px',
                  background: 'var(--theme-color)',
                }}
              >
                <h5 className="header-alert-modal">{data?.title}</h5>
                <span
                  style={{
                    fontSize: '14px',
                    color: '#fff',
                    minWidth: '100px',
                    textAlign: 'end',
                    margin: '0 30px 0px 60px',
                  }}
                >
                  {dayjs(data?.publishFrom).format('YYYY-MM-DD')}
                </span>
              </div>
            </>
          }
          centered
          open={open}
          onOk={() => onClose()}
          onCancel={() => onClose()}
          width={1000}
          closable={true}
          bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
        >
          <div
            className="modalAlert-detail"
            dangerouslySetInnerHTML={{
              __html: data?.content,
            }}
          />
          {listLink.length > 0 && (
            <>
              <p>ファイル数: {countFile}</p>
              {listLink.map((link: string, index: number) => (
                <li key={index}>
                  <a href={`${ENDPOINT}/${link}`} target="_blank">
                    {link}
                  </a>
                </li>
              ))}
            </>
          )}
        </ModalWrapper>
      )}
    </>
  )
}

export default withErrorBoundary(DetailNotification, {
  FallbackComponent: ErrorComponent,
})

const ModalWrapper = styled(Modal)`
  .ant-btn-primary {
    background: var(--theme-color);
  }
  .modalAlert-detail {
    color: #45546f;
  }
  .modalAlert-title {
    color: #45546f;
    font-weight: 600;
  }
  .header-alert-modal {
    margin: 0px;
    width: 100%;
    color: #f1f1f1;
    font-size: 16px;
  }
  .ant-modal-content {
    padding: 0px;
  }
  .ant-modal-header {
  }
  .ant-modal-body {
    padding: 0px 24px;
  }
  .ant-modal-footer {
    padding: 0px 24px 20px 24px;
    display: flex;
    justify-content: center;
  }
  .ant-modal-close {
    top: 10px;
    color: #f1f1f1;
    inset-inline-end: 10px;
  }
`

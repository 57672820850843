import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CaretUpOutlined } from '@ant-design/icons'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CalculatorYAxis } from '../../../Utils/CustomChart/CalculatorYAxisChart'
import { CustomYAxisPrice } from '../../../Utils/CustomChart/CustomYAxisChart'
import { maxBarChart } from '../HandleDataIchiran'
import { formatNumber } from '../../../Utils/Helper/FormatNumber'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import { withErrorBoundary } from 'react-error-boundary'
interface chartProps {
  data?: any
  dataNestChild: any
  firstName?: string
  nameParent?: string | undefined
}
const ChartPrice = ({
  data,
  dataNestChild,
  firstName,
  nameParent,
}: chartProps) => {
  const [maxY, setMaxY] = useState<number>(0)
  //calculateWidthChart
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => setScreenWidth(window.innerWidth), 100)
    }
    window.addEventListener('resize', handleResize)

    // cleanup event
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const calculateWidthChart = (screenWidth: number) => {
    if (screenWidth < 1025) {
      return screenWidth * 0.85
    } else {
      return screenWidth * 0.54
    }
  }
  const [widthChart, setWidthChart] = useState<number>(
    calculateWidthChart(screenWidth)
  )
  useEffect(() => {
    setWidthChart(calculateWidthChart(screenWidth))
  }, [screenWidth])

  let renderData: any = []
  data &&
    Object.keys(data).forEach((key) => {
      // Access each data object using the key
      const val = data?.[key]
      const title = dataNestChild?.find((item: any) => item.value === key)
      // Create a new object to hold the converted data
      const convertedItem = {
        title: nameParent ? nameParent : title?.title,
        value1: val.totalPowerCompanyFee,
        value2: val.totalConsumptionFee,
        difference: val.difference,
      }

      // Push the converted item to the array
      renderData.push(convertedItem)
    })

  const CustomTooltip = ({ active, payload, label }: any) => {
    const item = payload?.[0]?.payload
    if (active && payload && payload?.length) {
      return (
        <div className="custom-tooltip">
          <h1>{label}</h1>

          <p className="field" style={{ color: payload?.[0]?.color }}>
            {`${payload?.[0]?.name} : ${formatNumber(item.value1)}`}
          </p>

          <p className="field" style={{ color: payload?.[1]?.color }}>
            {`${payload?.[1]?.name} : ${formatNumber(item.value2)}`}
          </p>

          {item?.difference < 0 ? (
            <div className="field">
              料金 :
              <CaretUpOutlined style={{ color: 'red', marginLeft: '10px' }} />
              <div>
                {formatNumber(Math.abs(+item?.difference))} 千円
                <span style={{ fontSize: '12px' }}>削減</span>
              </div>
            </div>
          ) : (
            <div>
              {item?.difference} 千円
              <span style={{ fontSize: '12px' }}>削減</span>
            </div>
          )}
        </div>
      )
    }

    return null
  }

  const maxBar = maxBarChart()
  const isScrollChart = renderData?.length >= maxBar
  return (
    <GraphWrapper style={{ maxWidth: widthChart }}>
      <NameChart className="NameChartPrice">{firstName}</NameChart>
      {isScrollChart && <CustomYAxisPrice max={maxY} min={0} />}
      <BarChart
        width={isScrollChart ? renderData?.length * 120 : widthChart}
        height={400}
        data={renderData}
        barSize={16}
        barGap={8}
        margin={{ left: isScrollChart ? 5 : 60, bottom: 5, top: 5 }}
      >
        <CartesianGrid
          strokeDasharray="1 1"
          verticalCoordinatesGenerator={(props) => {
            const left: number = props?.offset?.left || 0
            const barSize: number = props?.xAxis?.bandSize
            const items = props?.xAxis?.domain

            let space = left
            let grid: any = []
            items?.forEach((element: any) => {
              space = space + barSize
              grid.push(space)
            })

            return grid
          }}
        />
        <XAxis
          dataKey="title"
          interval={0}
          tickFormatter={(value) =>
            value.length > 4 ? value.slice(0, 4) + '...' : value
          }
        />
        <YAxis
          label={{
            value: '千円',
            angle: -90,
            position: 'insideLeft',
            offset: widthChart > 704 ? -36 : -20,
            style: { textAnchor: 'middle' },
          }}
          domain={([dataMin, dataMax]) => {
            const [min, max] = CalculatorYAxis(dataMax)
            setMaxY(max)
            return [min, max]
          }}
          hide={isScrollChart}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          width={widthChart - 20}
          wrapperStyle={{
            position: 'sticky',
            left: 0,
            bottom: 14,
          }}
        />
        <Bar name="【参考】電力会社料金" dataKey="value1" fill="#f4003d" />
        <Bar name="MODEL-T" dataKey="value2" fill="#000272e6" />
      </BarChart>
    </GraphWrapper>
  )
}

export default withErrorBoundary(ChartPrice, {
  FallbackComponent: ErrorComponent,
})

const GraphWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 1em;
  /* max-height: 435px; */

  overflow-x: auto;
  overflow-y: hidden;
  .y-axis-wrapper {
    background: white;
    position: sticky;
    left: 0;
    z-index: 1003;
  }
  .recharts-wrapper {
    transform: translateX(-5px);
  }

  .recharts-legend-item-text {
    font-size: 12px !important;
  }
  .recharts-legend-wrapper {
    margin-left: -120px !important;
    padding-left: 120px !important;
  }
  .recharts-default-legend {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;

    .field {
      margin-top: 4px;
      display: flex;
    }
  }
`

export const NameChart = styled.p`
  color: #555;
  width: 100%;
  font-weight: bold;
  text-align: center;

  top: 4px;
  position: absolute;
  left: 0;

  @media (max-width: 1024px) {
    top: 0px;
  }
`

import { Button, DatePicker, Spin, notification } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import axios from 'axios'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { useContext, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'
import { ParentInfo, TypeAccount } from '../../Components/Context'
import IchiranCo2 from '../../Components/Ichiran/Co2'
import ButtonTotal from '../../Components/Ichiran/Co2/ButtonTotal'
import IchiranElectric from '../../Components/Ichiran/Electrics'
import IchiranMode from '../../Components/Ichiran/IchiranMode'
import IchiranInvoice from '../../Components/Ichiran/Invoice'
import IchiranPrice from '../../Components/Ichiran/Price'
import SelectCompanies from '../../Components/Ichiran/SelectCompanies'
import TimePicker from '../../Components/Ichiran/TimePicker'
import { WrapperBody } from '../../Components/Styled/Pages.style'
import Header from '../../Components/UI/Header/Header'
import TimeLine from '../../Components/UI/Header/TimeLine'
import {
  useFetchIchiranCo2ByMonth,
  useFetchIchiranCo2ByYear,
  useFetchIchiranElectricByMonth,
  useFetchIchiranElectricByYear,
  useFetchIchiranInvoice,
  useInfoParent,
} from '../../Api/Ichiran'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import { api } from '../../Api/Config/AxiosConfig'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

interface ListIdSelectProps {
  [parentId: string]: string[]
}

const ChiranPage = () => {
  const { isAdmin } = useContext(TypeAccount)
  const today = dayjs().startOf('day')

  const [modePage, setModePage] = useState(
    sessionStorage.getItem('IchiranModePage') || 'Electric'
  )

  const [modeTime, setModeTime] = useState<'month' | 'year'>(
    (sessionStorage.getItem('IchiranModeTime') as 'month' | 'year') || 'month'
  )

  const [valueCheck, setValueCheck] = useState<any[]>([])
  const [allNestChild, setAllNestChild] = useState<any>(null)
  const [listChild, setListChild] = useState<any>(null)
  const [listId, setListId] = useState<ListIdSelectProps>({})

  const [month, setMonth] = useState<string | null>(
    sessionStorage.getItem('IchiranElectricMonth') ||
      dayjs(today).format('YYYY-MM')
  )
  const [year, setYear] = useState<string | null>(
    sessionStorage.getItem('IchiranElectricYear') || dayjs(today).format('YYYY')
  )
  const [startDay, setStartDay] = useState<string | null>(
    dayjs(today).format('YYYY-MM-01')
  )
  const [endDay, setEndDay] = useState<string | null>(
    dayjs(today).format('YYYY-MM-DD')
  )

  //call API
  const [listIdCallApi, setListIdCallApi] = useState<ListIdSelectProps>({})
  const [listCallApiInvoice, setListCallApiInvoice] = useState<any>()
  const [monthApi, setMonthApi] = useState<string | null>(null)
  const [yearApi, setYearApi] = useState<string | null>(null)
  const [dateFrom, setDateFrom] = useState<string | null>(null)
  const [dateTo, setDateTo] = useState<string | null>(null)

  const handleGetListId = (values: ListIdSelectProps) => {
    setListId(values)
  }

  const handleGetListIdInv = (values: ListIdSelectProps) => {
    setListChild(values)
  }

  const handleChangeModeTime = (mode: 'month' | 'year') => {
    setModeTime(mode)
    sessionStorage.setItem('IchiranModeTime', mode)
  }

  const handleChangeModePage = (mode: string) => {
    setModePage(mode)
    sessionStorage.setItem('IchiranModePage', mode)
  }

  const onMonthChange = (dateString: string) => {
    setMonth(dateString)
    sessionStorage.setItem('IchiranElectricMonth', dateString)
  }

  const onYearChange = (dateString: string) => {
    setYear(dateString)
    sessionStorage.setItem('IchiranElectricYear', dateString)
  }

  const onClearDate = () => {
    setValueCheck([])
    if (modePage === 'Invoice') {
      setStartDay(null)
      setEndDay(null)
    }
    if (modeTime === 'month') {
      setMonth(null)
      sessionStorage.removeItem('IchiranElectricMonth')
    } else {
      setYear(null)
      sessionStorage.removeItem('IchiranElectricYear')
    }
  }

  const setValueCallData = () => {
    if (Object.keys(listId).length === 0) {
      notification.error({
        message: '顧客を選択してください。',
        duration: 2,
        placement: 'topRight',
      })
      return
    }

    if (modeTime === 'month' && month) {
      setMonthApi(month)
    } else if (modeTime === 'year' && year) {
      setYearApi(year)
    } else {
      if (modeTime === 'month') {
        notification.error({
          message: '月分を選択してください。',
          duration: 2,
          placement: 'topRight',
        })
      } else {
        notification.error({
          message: '年間を選択してください。',
          duration: 2,
          placement: 'topRight',
        })
      }
      return
    }

    setListIdCallApi(listId)
  }

  const setValueCallDataInvoice = () => {
    if (listChild?.length === 0) {
      notification.error({
        message: '顧客を選択してください。',
        duration: 2,
        placement: 'topRight',
      })
      return
    }

    if (!startDay) {
      notification.error({
        message: '開始年月を選択してください。',
        duration: 2,
        placement: 'topRight',
      })
      return
    }

    if (!endDay) {
      notification.error({
        message: '終了年月を選択してください。',
        duration: 2,
        placement: 'topRight',
      })
      return
    }

    setListCallApiInvoice(listChild)
    setDateFrom(startDay)
    setDateTo(endDay)
  }

  //call data
  const { data: InfoParent, isFetching: InfoParentLoading } = useInfoParent(
    allNestChild?.[0]?.value,
    !isAdmin
  )

  const { data: ElectricYearData, isFetching: ElectricYearLoading } =
    useFetchIchiranElectricByYear(
      yearApi,
      listIdCallApi,
      modePage === 'Electric' || modePage === 'Price',
      modeTime === 'year'
    )

  const { data: ElectricMonthData, isFetching: ElectricMonthLoading } =
    useFetchIchiranElectricByMonth(
      monthApi,
      listIdCallApi,
      modePage === 'Electric' || modePage === 'Price',
      modeTime === 'month'
    )

  const { data: Co2YearData, isFetching: Co2YearLoading } =
    useFetchIchiranCo2ByYear(
      yearApi,
      listIdCallApi,
      modePage === 'Co2',
      modeTime === 'year'
    )

  const { data: Co2MonthData, isFetching: Co2MonthLoading } =
    useFetchIchiranCo2ByMonth(
      monthApi,
      listIdCallApi,
      modePage === 'Co2',
      modeTime === 'month'
    )

  const { data: InvoiceData, isFetching: InvoiceLoading } =
    useFetchIchiranInvoice(
      dateFrom,
      dateTo,
      listCallApiInvoice,
      modePage === 'Invoice'
    )
  const convertedInvoiceData: any[] =
    InvoiceData &&
    Object.values(InvoiceData)
      .flat()
      .map((obj: any, index: number) => {
        obj.index = index + 1
        obj.key = `key_${index + 1}` // Add the "key" field
        return obj
      })

  const [downloadIchiranInvLoading, setDownloadIchiranInvLoading] =
    useState<boolean>(false)

  const DownloadIchiranInv = async () => {
    if (!convertedInvoiceData || convertedInvoiceData?.length === 0) {
      notification.error({
        message: 'データがありません。',
        duration: 2,
        placement: 'topRight',
      })
    } else {
      setDownloadIchiranInvLoading(true)
      await api({
        method: 'post',
        url: `${ENDPOINT}/InvoiceLog/TemplateDown`,
        data: convertedInvoiceData,
        responseType: 'arraybuffer',
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          saveAs(blob, `月額請求入金一覧案.xlsx`)
        })
        .catch((err) => {
          notification.error({
            message: err,
            duration: 2,
            placement: 'topRight',
          })
          console.log(err)
        })
        .finally(() => setDownloadIchiranInvLoading(false))
    }
  }

  const loading =
    ElectricYearLoading ||
    ElectricMonthLoading ||
    Co2YearLoading ||
    Co2MonthLoading ||
    InvoiceLoading

  // Disable all dates after the selected end date
  const disabledStartDate = (current: any) => {
    return current && current > dayjs(endDay).endOf('day')
  }

  // Disable all dates after today and before start day
  const disabledEndDate = (current: any) => {
    const startDayStart = dayjs(startDay).startOf('day')
    return current && (current > today || current < startDayStart)
  }
  // Update the selected start date
  const onChangeStartDate = (value: any, dateString: string | string[]) => {
    const startOfMonth = dayjs(dateString.toString()).format('YYYY-MM-DD')
    setStartDay(dayjs(dateString.toString()).isValid() ? startOfMonth : null)
  }

  // Update the selected end date
  const onChangeEndDate = (value: any, dateString: string | string[]) => {
    const endOfMonth = dayjs(dateString.toString())
      .endOf('month')
      .format('YYYY-MM-DD')
    setEndDay(dayjs(dateString.toString()).isValid() ? endOfMonth : null)
  }

  const onChangeCheck = (newValue: string[]) => {
    setValueCheck(newValue)
  }

  return (
    <>
      <ParentInfo.Provider value={InfoParent?.results?.[0]?.properties?.name}>
        <WrapperBody>
          <Header defaultActiveBtn={3} />
          <IchiranMode
            onChange={(mode: string) => handleChangeModePage(mode)}
            mode={modePage}
          />
          {modePage === 'Invoice' ? null : (
            <TimeLine
              page="Ichiran"
              modeTime={modeTime}
              onChange={(mode: 'month' | 'year') => handleChangeModeTime(mode)}
            />
          )}

          {modePage === 'Co2' && (
            <>
              {modeTime === 'month' && (
                <ButtonTotal
                  loading={loading}
                  mode="month"
                  data={Co2MonthData}
                  dataNestChild={allNestChild}
                />
              )}
              {modeTime === 'year' && (
                <ButtonTotal
                  loading={loading}
                  mode="year"
                  data={Co2YearData}
                  dataNestChild={allNestChild}
                />
              )}
            </>
          )}

          {/* {isAdmin ? (
            <SelectCompanies
              valueCheck={valueCheck}
              onChangeCheck={onChangeCheck}
              onSelect={handleGetListId}
              onSelect1={handleGetListIdInv}
              setNestChild={setAllNestChild}
              modePage={modePage}
              loadingBtnDownload={downloadIchiranInvLoading}
              onDownload={DownloadIchiranInv}
            />
          ) : (
            <SelectChild
              // value={value}
              onSelect={handleGetListId}
              setNestChild={setAllNestChild}
            />
          )} */}

          <SelectCompanies
            valueCheck={valueCheck}
            onChangeCheck={onChangeCheck}
            onSelect={handleGetListId}
            onSelect1={handleGetListIdInv}
            setNestChild={setAllNestChild}
            modePage={modePage}
            loadingBtnDownload={downloadIchiranInvLoading}
            onDownload={DownloadIchiranInv}
          />
          <div
            style={{
              width: '37.4%',
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {modePage === 'Invoice' ? (
              <>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button
                    type="text"
                    style={{
                      width: '120px',
                      fontWeight: 600,
                      background: 'rgba(0, 0, 0, 0.06)',
                      cursor: 'default',
                    }}
                  >
                    期間
                  </Button>
                  <DatePick>
                    <DatePickerWrapper
                      value={startDay ? dayjs(startDay) : null}
                      locale={locale}
                      onChange={onChangeStartDate}
                      picker="month"
                      placeholder="開始年月を選択"
                      disabledDate={disabledStartDate}
                    />
                    <span
                      style={{
                        fontSize: '20px',
                        padding: '0 10px 4px 10px',
                        fontWeight: '600',
                      }}
                    >
                      ~
                    </span>
                    <DatePickerWrapper
                      value={endDay ? dayjs(endDay) : null}
                      locale={locale}
                      allowClear
                      onChange={onChangeEndDate}
                      picker="month"
                      placeholder="終了年月を選択"
                      disabledDate={disabledEndDate}
                    />
                  </DatePick>
                </div>
              </>
            ) : (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                  type="text"
                  style={{
                    width: '120px',
                    fontWeight: 600,
                    background: 'rgba(0, 0, 0, 0.06)',
                    cursor: 'default',
                  }}
                >
                  期間
                </Button>
                <TimePicker
                  month={month}
                  year={year}
                  onMonthChange={(_: any, dateString: string | string[]) =>
                    onMonthChange(dateString.toString())
                  }
                  onYearChange={(_: any, dateString: string | string[]) =>
                    onYearChange(dateString.toString())
                  }
                  modeTime={modeTime}
                />
              </div>
            )}

            {modePage === 'Invoice' ? (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonStyle
                  disabled={loading}
                  className="btn-choose btn-bold"
                  onClick={setValueCallDataInvoice}
                >
                  出力
                </ButtonStyle>
                <ButtonStyle
                  disabled={loading}
                  className="btn-clear"
                  onClick={onClearDate}
                >
                  クリア
                </ButtonStyle>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonStyle
                  disabled={loading}
                  className="btn-choose"
                  onClick={setValueCallData}
                >
                  確認
                </ButtonStyle>
                <ButtonStyle
                  disabled={loading}
                  className="btn-clear"
                  onClick={onClearDate}
                >
                  クリア
                </ButtonStyle>
              </div>
            )}
          </div>
          {loading && (
            <Spin
              style={{
                position: 'absolute',
                left: '50%',
                top: modePage === 'Co2' ? '40%' : '25%',
              }}
            />
          )}

          <Container
            style={{ opacity: loading ? 0.5 : 1 }}
            className="ichiranPage"
          >
            {modePage === 'Electric' && (
              <IchiranElectric
                modeTime={modeTime}
                dataMonth={ElectricMonthData}
                dataYear={ElectricYearData}
                dataNestChild={allNestChild}
                month={month}
                year={year}
              />
            )}
            {modePage === 'Co2' && (
              <IchiranCo2
                modeTime={modeTime}
                dataMonth={Co2MonthData}
                dataYear={Co2YearData}
                dataNestChild={allNestChild}
                month={month}
                year={year}
              />
            )}
            {modePage === 'Price' && (
              <IchiranPrice
                modeTime={modeTime}
                dataMonthElectric={ElectricMonthData}
                dataYearElectric={ElectricYearData}
                dataNestChild={allNestChild}
                month={month}
                year={year}
              />
            )}
            {modePage === 'Invoice' && (
              <IchiranInvoice data={convertedInvoiceData} />
            )}
          </Container>
        </WrapperBody>
      </ParentInfo.Provider>
    </>
  )
}
export default withErrorBoundary(ChiranPage, {
  FallbackComponent: ErrorComponent,
})

const Container = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
`
const ButtonStyle = styled(Button)`
  color: White;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  border-style: None;
  height: 32px;
  width: 100px;
  &.btn-choose {
    background: #bf901e;
    color: #fff;
    font-weight: 500;
  }
  &.btn-bold {
    font-weight: bold;
    letter-spacing: -1px;
  }
  &.btn-clear {
    background: rgb(199, 203, 211);
    color: #333;
    font-weight: 600;
  }
`

const DatePick = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-picker {
    background: var(--theme-color);
    input {
      color: white !important;
      ::placeholder {
        color: white;
      }
    }
  }
`
const DatePickerWrapper = styled(DatePicker)`
  flex: 1;
  .ant-picker-suffix {
    color: white;
  }
`

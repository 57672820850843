import React from 'react'

import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import { disabledDate } from '../../Utils/Helper/DisableDay'
import { DatePicker } from 'antd'
import styled from 'styled-components'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'

interface pageProps {
  modeTime: 'year' | 'month'
  month?: string | null
  year?: string | null
  onMonthChange?: (_: any, dateString: string | string[]) => void
  onYearChange?: (_: any, dateString: string | string[]) => void
  onClear?: any
}
const TimePicker: React.FC<pageProps> = ({
  modeTime,
  month,
  year,
  onMonthChange,
  onYearChange,
}) => {
  return (
    <>
      {modeTime === 'year' ? (
        <DatePickerWrapper
          value={year ? dayjs(year) : null}
          allowClear={false}
          locale={locale}
          onChange={onYearChange}
          picker="year"
          disabledDate={disabledDate}
        />
      ) : (
        <DatePickerWrapper
          value={month ? dayjs(month) : null}
          placeholder="クリック選択"
          allowClear={false}
          locale={locale}
          onChange={onMonthChange}
          picker="month"
          disabledDate={disabledDate}
        />
      )}
    </>
  )
}

export default withErrorBoundary(TimePicker, {
  FallbackComponent: ErrorComponent,
})

const DatePickerWrapper = styled(DatePicker)`
  flex: 1;
  background: var(--theme-color);
  input {
    color: white !important;
    font-size: 14px !important;
    @media (max-width: 1279px) {
      font-size: 12px !important;
    }
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
  }
  input::placeholder {
    color: #f5f5f5 !important;
  }
  .ant-picker-suffix {
    color: white;
  }
  &.ant-picker-disabled {
    background: #c7cbd3 !important;
  }
  .ant-picker-month-btn {
    margin-inline-start: 4px !important;
  }
`

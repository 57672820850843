import React, { useContext } from 'react'
import { ParentInfo } from '../../Context'
import { Container } from '../../Styled/Pages.style'
import IchiranElectricChart from './Chart'
import IchiranElectricTable from './ElectricTable'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface pageProps {
  modeTime: 'year' | 'month'
  dataNestChild: any
  dataMonth?: any
  dataYear?: any
  month?: string | null
  year?: string | null
}

const IchiranElectric: React.FC<pageProps> = ({
  modeTime,
  dataNestChild,
  dataMonth,
  dataYear,
  month,
  year,
}) => {
  const nameParent: string | undefined = useContext(ParentInfo)

  return (
    <>
      <Container>
        {modeTime === 'month' && (
          <>
            <div className="Power__Detail">
              <IchiranElectricTable
                data={dataMonth}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
              />
            </div>
            <div className="Power__Chart">
              <IchiranElectricChart
                data={dataMonth}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
                monthSelect={month ? `${month}の` : ''}
                firstName="使用電力量"
                lastName="を表示しています。"
                modeView={modeTime}
              />
            </div>
          </>
        )}
        {modeTime === 'year' && (
          <>
            <div className="Power__Detail">
              <IchiranElectricTable
                data={dataYear}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
              />
            </div>
            <div className="Power__Chart">
              <IchiranElectricChart
                data={dataYear}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
                yearSelect={year ? `${year}の` : ''}
                firstName="使用電力量"
                lastName="を表示しています。"
                modeView={modeTime}
              />
            </div>
          </>
        )}
      </Container>
    </>
  )
}

export default withErrorBoundary(IchiranElectric, {
  FallbackComponent: ErrorComponent,
})

import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons'
import { ConfigProvider, DatePicker, Table, notification } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ButtonDefault from '../../Components/UI/Button/ButtonDefault'
import Header from '../../Components/UI/Header/Header'
import { useFetchCompanies, useFetchNestCompanies } from '../../Api/Companies'
import { DownReport } from '../../Api/Electric'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import { customizeRenderEmpty } from '../../Utils/CustomHook/CustomNoData'

interface accountProps {
  id?: number | string
  name?: string
  address?: string
  about_us?: string
  key: number | string
}

function DownloadCSV() {
  const [startDay, setStartDate] = useState(dayjs().startOf('day'))
  const [endDate, setEndDate] = useState(dayjs().startOf('day'))
  const [selectedCompanyDownload, setSelectedCompanyDownload] = useState([])
  const [allAccount, setAllAccount] = useState<accountProps[] | []>([])

  const AccountTitle: string | number = JSON.parse(
    sessionStorage.getItem('AccountTitle') as string
  )
  const AccountID: string | number | any = sessionStorage.getItem('AccountID')

  const { data: parentCompanies, isLoading: isLoadingAccount } =
    useFetchCompanies(AccountID)

  const listParent = parentCompanies?.results?.map((item: any) => item.id)

  const { data: listChild, isLoading: loadingNestChild } =
    useFetchNestCompanies(listParent)

  let listChildList: any[] = []

  if (listChild) {
    for (let key in listChild) {
      if (listChild.hasOwnProperty(key)) {
        const childResults = listChild[key].results
        if (Array.isArray(childResults) && childResults.length > 0) {
          listChildList = listChildList.concat(childResults)
        }
      }
    }
  }

  useEffect(() => {
    if (listChildList) {
      let getAccount: accountProps[] = []

      for (const key in listChildList) {
        const accountData = listChildList[key]
        if (accountData) {
          const account: accountProps = {
            id: accountData.id,
            name: accountData.properties?.name,
            address:
              accountData.properties?.plantaddress ||
              accountData.properties?.customer_address ||
              accountData.properties?.plant_name,
            about_us: accountData.properties?.plantid || accountData.id,
            key: key,
          }
          getAccount.push(account)
        }
      }
      setAllAccount(getAccount)
    }
  }, [listChild, parentCompanies])

  const columns = [
    {
      key: 'about_us',
      title: '需要場所ID',
      dataIndex: 'about_us',
      width: '33%',
    },
    {
      key: 'address',
      title: '需要場所名',
      dataIndex: 'address',
      width: '66%',
    },
  ]

  const { mutate, isLoading: downloadBtnLoading } = useMutation(DownReport, {
    onSuccess: (data, variables, context) => {
      notification.success({
        message: 'ダウンロードに成功しました。',
        duration: 2,
        placement: 'topRight',
      })
    },
    onError: (error: any) => {
      console.log(error)
      notification.error({
        message: error,
        duration: 2,
        placement: 'topRight',
      })
    },
  })

  const onDownload = () => {
    if (selectedCompanyDownload.length === 0) {
      notification.error({
        message: '需要場所を選択してください。',
        duration: 2,
        placement: 'topRight',
      })
    } else {
      const from = dayjs(startDay).format('YYYYMMDD')
      const to = dayjs(endDate).format('YYYYMMDD')
      const item = selectedCompanyDownload
      mutate({
        variables: { from, to, item, AccountTitle },
      })
    }
  }

  const Navigate = useNavigate()
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedCompanyDownload(selectedRows)
    },
  }

  // Disable all dates after the selected end date
  const disabledStartDate = (current: any) => {
    return current && current > endDate.endOf('day')
  }

  // Disable all dates after today and before start day
  const disabledEndDate = (current: any) => {
    const today = dayjs().startOf('day')
    const startDayStart = startDay.startOf('day')
    return current && (current > today || current < startDayStart)
  }

  // Update the selected start date
  const onChangeStartDate = (value: any) => {
    setStartDate(value)
  }

  // Update the selected end date
  const onChangeEndDate = (value: any) => {
    setEndDate(value)
  }

  return (
    <WrapperBody>
      <Header />
      <DataBtn>
        <div onClick={() => Navigate(-1)}>
          <ButtonDefault
            className="Return-btn"
            title="戻る"
            iconbtn={faArrowLeft}
          />
        </div>
        <span>対象：30分電力量</span>
      </DataBtn>
      <DataBtn>
        <div></div>
        <span>
          30分毎の使用電力量（kWh）を出力したXLSX形式のファイルをダウンロードできます。
        </span>
      </DataBtn>
      <Container>
        <DatePick>
          <DatePickerWrapperCSV
            locale={locale}
            allowClear={false}
            onChange={onChangeStartDate}
            picker="date"
            defaultValue={dayjs().startOf('day')}
            defaultPickerValue={dayjs().startOf('day')}
            disabledDate={disabledStartDate}
          />
          <span
            style={{
              fontSize: '20px',
              padding: '0 10px 2px 10px',
              fontWeight: '600',
            }}
          >
            ~
          </span>
          <DatePickerWrapperCSV
            locale={locale}
            allowClear={false}
            onChange={onChangeEndDate}
            picker="date"
            defaultValue={dayjs().startOf('day')}
            defaultPickerValue={dayjs().startOf('day')}
            disabledDate={disabledEndDate}
          />
        </DatePick>
      </Container>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div style={{ width: '80vw' }}>
            <TableLocations
              loading={isLoadingAccount || loadingNestChild}
              bordered
              columns={columns}
              rowSelection={{
                ...rowSelection,
              }}
              dataSource={allAccount}
              pagination={false}
              scroll={{
                y: 400,
              }}
            />
            <ButtonDefault
              className="DownloadCSV"
              title="使用実績のダウンロード"
              iconbtn={faDownload}
              loading={downloadBtnLoading}
              handleClick={() => onDownload()}
            />
          </div>
        </div>
      </ConfigProvider>
    </WrapperBody>
  )
}

export default withErrorBoundary(DownloadCSV, {
  FallbackComponent: ErrorComponent,
})

const WrapperBody = styled.div`
  display: block;
  margin: auto;
  .Download--sub {
    text-align: center;
  }
  .DownloadCSV {
    width: max-content;
  }
`
const DataBtn = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  left: 0;
  div {
    width: 100px;
    margin-right: 20px;
  }
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  .Return {
    width: 100%;
    display: inline;
  }
`
const DatePick = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-picker {
    background: var(--theme-color);
    input {
      color: white !important;
    }
  }
`
const TableLocations = styled(Table)`
  margin: 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 10px;

  .ant-table-thead {
    height: 38px;
    font-size: 13px;
    padding: 0px;
    .ant-table-cell {
      font-weight: 600;
      padding: 0px;
      text-align: center;
      background: #c7cbd3;
    }
  }

  .ant-table-cell {
    padding: 8px !important;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  .ant-checkbox-inner {
    border: 1px solid #666;
  }
`
const DatePickerWrapperCSV = styled(DatePicker)`
  .ant-picker-suffix {
    color: white;
  }
`

import { Button, ConfigProvider, Modal, Table, notification } from 'antd'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { customizeRenderEmpty } from '../../Utils/CustomHook/CustomNoData'
import { Round, formatNumber } from '../../Utils/Helper/FormatNumber'
import { setStatusInvoice } from '../../Api/Invoice'
import { TypeAccount } from '../Context'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'

type DataType = {
  key: number
  date: string
  quantity: string | number
  cost: string
  address: string
  check: boolean
  id: string | number
}

interface InvoiceHistoryProps {
  dataInvoice?: any
}

const InvoiceHistory: React.FC<InvoiceHistoryProps> = ({ dataInvoice }) => {
  const { isAdmin } = useContext(TypeAccount)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [idInvoice, setIdInvoice] = useState<string | number>('')
  const [isPaid, setIsPaid] = useState<boolean>()

  const data: DataType[] = []

  dataInvoice?.length > 0 &&
    dataInvoice.forEach((invHis: any, index: number) => {
      //calculator day
      const numDay = dayjs(invHis.endDate).diff(invHis.startDate, 'day') + 1

      data.push({
        key: invHis.id,
        date: dayjs(invHis?.realeaseDate).format('YYYY/MM/DD') || '',
        quantity: `${formatNumber(Round(invHis.consumptionValue)) || 0} kWh`,
        cost: `${formatNumber(Round(invHis.grossPrice)) || 0} 円`,
        address: `${dayjs(invHis?.startDate).format('YYYY/MM/DD')} - ${dayjs(
          invHis?.endDate
        ).format('YYYY/MM/DD')} ${numDay}日間使用`,
        check: invHis.isPaid ? true : false,
        id: invHis.id,
      })
    })

  //set status invoice
  const queryClient = useQueryClient()
  const { mutate, isLoading: isPaidLoading } = useMutation(setStatusInvoice, {
    onSuccess: (data, variables, context) => {
      setIsModalOpen(false)
      queryClient.invalidateQueries({ queryKey: ['GetInvoiceHistory'] })
      queryClient.invalidateQueries({ queryKey: ['DataChartInvoice'] })

      isPaid
        ? notification.success({
            message: '支払記録への修正に成功しました。',
            duration: 1,
            placement: 'topRight',
          })
        : notification.success({
            message: '支払済みへの修正に成功しました。',
            duration: 1,
            placement: 'topRight',
          })
    },
    onError: (error: any) => {
      alert(error)
    },
  })

  const onOk = () => {
    mutate({
      variables: { idInvoice, isPaid },
    })
  }

  const columnsAdmin = [
    {
      key: 'date',
      title: '日付',
      dataIndex: 'date',
    },
    {
      key: 'address',
      title: '使用期間',
      dataIndex: 'address',
    },
    {
      key: 'quantity',
      title: '使用量',
      dataIndex: 'quantity',
    },
    {
      key: 'cost',
      title: '請求額',
      dataIndex: 'cost',
    },
    {
      key: 'check',
      title: '支払状況',
      dataIndex: 'check',
      render: (check: boolean, record: any) => {
        if (check === true) {
          return (
            <button
              style={{
                borderRadius: '4px',
                cursor: 'pointer',
                border: '1px solid #ccc',
              }}
              onClick={() => {
                setIsModalOpen(true)
                setIdInvoice(record.id)
                setIsPaid(check)
              }}
            >
              支払済み
            </button>
          )
        } else
          return (
            <button
              style={{
                color: 'white',
                backgroundColor: 'var(--theme-color)',
                fontFamily: 'inherit',
                fontWeight: 500,
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsModalOpen(true)
                setIdInvoice(record.id)
                setIsPaid(check)
              }}
            >
              支払記録
            </button>
          )
      },
    },
  ]

  const columns = [
    {
      key: 'date',
      title: '日付',
      dataIndex: 'date',
    },
    {
      key: 'address',
      title: '使用期間',
      dataIndex: 'address',
    },
    {
      key: 'quantity',
      title: '使用量',
      dataIndex: 'quantity',
    },
    {
      key: 'cost',
      title: '請求額',
      dataIndex: 'cost',
    },
  ]

  const btnRef = useRef<any>(null)

  // auto focus confirm btn

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    if (isModalOpen && !isPaidLoading) {
      timeoutId = setTimeout(() => {
        btnRef?.current?.focus()
      }, 100)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isModalOpen, isPaidLoading])

  return (
    <>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={!isAdmin ? columns : columnsAdmin}
          dataSource={data}
          pagination={{ pageSize: 3 }}
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
            borderRadius: '10px',
          }}
        />
      </ConfigProvider>
      <Modal
        open={isModalOpen}
        width={560}
        style={{ top: '20vh' }}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        footer={[
          <Button
            ref={btnRef}
            key="confirm"
            loading={isPaidLoading}
            type="primary"
            onClick={onOk}
          >
            はい
          </Button>,
          <Button
            key="cancel"
            type="default"
            onClick={() => {
              setIsModalOpen(false)
            }}
          >
            キャンセル
          </Button>,
        ]}
      >
        {isPaid ? (
          <p style={{ marginBottom: '24px' }}>
            この請求書は{dayjs().format('YYYY/MM/DD HH:mm:ss')}
            に支払記録への修正します。よろしいですか。
          </p>
        ) : (
          <p style={{ marginBottom: '24px' }}>
            この請求書は{dayjs().format('YYYY/MM/DD HH:mm:ss')}
            に支払済みへの修正します。よろしいですか。
          </p>
        )}
      </Modal>
    </>
  )
}

export default withErrorBoundary(InvoiceHistory, {
  FallbackComponent: ErrorComponent,
})

import { FileTextOutlined, RightOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import {
  BsFillArrowDownRightCircleFill,
  BsFillArrowUpRightCircleFill,
} from 'react-icons/bs'
import styled from 'styled-components'
import { useGetCurrentElectrics } from '../../Api/Invoice'
import { Round, formatNumber } from '../../Utils/Helper/FormatNumber'
import { TypeAccount } from '../Context'
import { WrapperBox } from '../Styled/Invoice.style'
import InvoiceHistory from './InvoiceHistory'
import InvoiceChart from './LineChart'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import { withErrorBoundary } from 'react-error-boundary'

const yesterday = () => {
  const date = new Date()

  return [
    `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日時点`,
    `${date.getFullYear()}年${date.getMonth() + 1}月分`,
  ]
}

interface Props {
  setIsSelect: any
  dataChart: any
  data: any

  dataCheckDate: any
  dataInvoiceHistory?: any

  companyId: string | number
  setCompanyId?: any
  lineChartLoading: boolean
}

const Invoice: React.FC<Props> = ({
  setIsSelect,
  dataChart,
  data,
  dataCheckDate,
  dataInvoiceHistory,
  companyId,
  lineChartLoading,
}) => {
  const { isAdmin } = useContext(TypeAccount)

  const dateStart = dataCheckDate.startDay
  const dateEnd = dataCheckDate.endDay

  const currentDay = dayjs().startOf('date')
  const numDayFormStartToCur = dayjs(currentDay).diff(dateStart, 'day') + 1

  //get data electrics current
  const { data: ElectricUsed, isLoading } = useGetCurrentElectrics(
    companyId,
    dateStart
  )

  const invoiceLastYear =
    data?.find((inv: any) => {
      return (
        Number(inv.checkMonth) === Number(dayjs(currentDay).format('MM')) &&
        Number(inv.checkYear) ===
          Number(dayjs(currentDay.subtract(1, 'year')).format('YYYY'))
      )
    }) || null

  const totalDayInvoice =
    dayjs(dateEnd).diff(dateStart, 'day') === 0
      ? 1
      : dayjs(dateEnd).diff(dateStart, 'day') + 1

  const forecastElectrics = parseFloat(
    (
      (Round(ElectricUsed) * Round(totalDayInvoice)) /
      Round(numDayFormStartToCur)
    ).toFixed(1)
  )

  const compareInvoice =
    Number(forecastElectrics) - (Number(invoiceLastYear?.consumptionValue) || 0)
  const comparePercent = compareInvoice
    ? (compareInvoice / (Number(invoiceLastYear?.consumptionValue) || 0)) * 100
    : 0

  return (
    <>
      <h3>電気料金</h3>
      <WrapperBox>
        <InvoiceChart
          isSuperAdmin={isAdmin}
          dataChart={dataChart}
          lineChartLoading={lineChartLoading}
        />
      </WrapperBox>
      <h3>今月の電気使用量予測</h3>
      <Spin spinning={isLoading}>
        <Table>
          <tbody>
            <tr>
              <td>
                （使用期間： {dayjs(dataCheckDate?.startDay).format('MM月DD日')}
                ～{dayjs(dataCheckDate?.endDay).format('MM月DD日')})
              </td>
              <td>{yesterday()[0]}</td>
            </tr>
            <tr>
              <td>
                <strong style={{ fontSize: '1.2rem' }}>
                  {formatNumber(Round(forecastElectrics || 0))}
                  {' kWh'}
                </strong>
              </td>
              <td>
                <span>これまでの使用量</span>
                <span> {numDayFormStartToCur || 0}日間</span>
                <span> {formatNumber(Round(ElectricUsed || 0))} kWh</span>
              </td>
            </tr>
            <tr>
              <td>
                前年同月差 {formatNumber(Round(Math.abs(compareInvoice || 0)))}{' '}
                kWh (
                {comparePercent === Infinity || comparePercent === -Infinity
                  ? 100
                  : comparePercent.toFixed(2)}{' '}
                %){' '}
                {comparePercent > 0 ? (
                  <BsFillArrowUpRightCircleFill
                    style={{ transform: 'translateY(2px)' }}
                    color="red"
                  />
                ) : (
                  <BsFillArrowDownRightCircleFill
                    style={{ transform: 'translateY(2px)' }}
                    color="blue"
                  />
                )}
              </td>
              <td>
                <span>前回検針日</span>
                <span>{dayjs(dataCheckDate?.startDay).format('MM月DD日')}</span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Spin>
      <h3>もっとくわしく料金を見る</h3>

      <ViewMoreForm>
        <div onClick={() => setIsSelect(false)}>
          <div>
            <FileTextOutlined /> <span>料金明細</span>
          </div>
          <RightOutlined />
        </div>
      </ViewMoreForm>
      <h3>電気の請求履歴</h3>
      <InvoiceHistory dataInvoice={dataInvoiceHistory} />
      <h3>注意事項</h3>
      <ul>
        <li>
          スマートメーターの通信状況や、送配電事業者からのデータ連携により、⼀部時間帯の電⼒使
          ⽤量が表⽰されない場合があります。
        </li>
        <li>
          電気料⾦の訂正等により、料⾦明細やグラフが正しく表⽰されない場合があります。
        </li>
        {/* <li>
          引越し、名義変更、支払者変更の際は、セキュリティ保護の観点から、以降の請求額や使用量が表示されなくなります。
          MODEL-Tマイページの「お
          引越しされた場合・お客さま番号の追加」からお客さま番号の再登録をお願いいたします。
        </li>
        <li>電気料金は複数まとめて請求されることがあります。</li>
        <li>
          スマートメーターの通信状況や、送配電事業者からのデータ連携
          により、一部時間帯の電力使用量が一時的に表示されない場合があります。
        </li>
        <li>
          ガス・電気セット割適用の場合、
          電気料金は、電気の計量後､一定期間を経た後に到来するガスの検針日に、
          ガス料金と合算して請求するため、ガス料金 の確定と同時に表示されます。
          詳細はこちら。
        </li>
        <li>
          電気料金の訂正等により、
          料金明細やグラフが正しく表示されない、または検針票と異なる場合があります。
          ただし、使用量･使用期間は以下の方法で 認できます。
        </li>
        <li>
          「請求｣ 「ガス」 「電気」
          ページの下部にある「使用量と請求額をダウンロード」の「CSVダウンロード」。
        </li>
        <li>
          使用期間 料金明細」ページに表示される日付は、原則として、
          ガス･電気合算のお客さまはガス検針日を、
          電気料金が単独で請求されているお客 . さまは電気計量を示しています。
        </li>
        <li>
          2023年2月検針分単価から、政府支援のガス▲30円/m3・低圧電気▲7円/kWhを適用しております。
          適用には条件があります。 詳細はこちらをご 確認ください。
        </li> */}
      </ul>
    </>
  )
}

export default withErrorBoundary(Invoice, {
  FallbackComponent: ErrorComponent,
})

const Table = styled.table`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

  td:nth-child(even) {
    display: flex;
    justify-content: space-between;
  }
  td {
    padding: 4px;
    text-align: center;
  }
`
const ViewMoreForm = styled.div`
  margin-bottom: 40px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  & > div {
    display: flex;
    /* flex: 1; */
    width: 50%;
    justify-content: space-between;
    text-align: center;
    padding: 12px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
  cursor: pointer;
  .download-invoice-btn {
    border: none;
    width: 100%;
    text-align: start;
    box-shadow: none;
  }
`

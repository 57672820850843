import { BulbOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Button, DatePicker, Divider, Spin, notification } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import axios from 'axios'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'
import { BsCaretUpFill } from 'react-icons/bs'
import styled from 'styled-components'
import { Round, formatNumber } from '../../Utils/Helper/FormatNumber'
import ButtonDefault from '../UI/Button/ButtonDefault'
import { WrapperBox } from '../Styled/Invoice.style'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
interface Props {
  setIsSelect: any
  companyId: string
  data: any
}

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

const DetailInvoice = ({ setIsSelect, companyId, data }: Props) => {
  //scroll to top

  const scrollToTop = () => {
    const element = document.getElementById('TopInvDetail')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
  useEffect(() => {
    scrollToTop()
  }, [])

  const lastInvoice = data && data[0]
  const [date, setDate] = useState(
    dayjs(lastInvoice?.endDay).format('YYYY-MM-DD')
  )

  const onDayChange = (_: any, dateString: any) => {
    setDate(dateString)
  }

  //get number of date

  const invoiceSelect =
    data?.length > 0 &&
    data?.find((dataMonth: any) => {
      return (
        Number(dataMonth?.checkMonth) === Number(dayjs(date).format('MM')) &&
        Number(dataMonth?.checkYear) === Number(dayjs(date).format('YYYY'))
      )
    })

  let priceBase =
    (invoiceSelect &&
      invoiceSelect?.consumptionValue * invoiceSelect?.unitPrice) ||
    0

  const releaseDate =
    invoiceSelect?.releaseDate &&
    dayjs(invoiceSelect?.releaseDate).format('YYYY[年]MM[月]DD[日]')

  const startDate =
    invoiceSelect?.startDay &&
    dayjs(invoiceSelect?.startDay).format('YYYY[年]MM[月]DD[日]')

  const endDate = dayjs(invoiceSelect?.endDay).format('YYYY[年]MM[月]DD[日]')

  const calculatorDay = dayjs(invoiceSelect?.endDay).diff(
    invoiceSelect?.startDay,
    'day'
  )

  const checkMonth = invoiceSelect?.checkMonth || dayjs(date).format('MM')
  const checkYear = invoiceSelect?.checkYear || dayjs(date).format('YYYY')

  const discount = Number(invoiceSelect?.discount) || 0
  const v2hTanka = Number(invoiceSelect?.v2hTanka) || 0
  const batteryFee = Number(invoiceSelect?.batteryFee) || 0
  const VAT = (priceBase + v2hTanka + batteryFee - discount) * 0.1
  const grossPrice = priceBase + v2hTanka + batteryFee - discount + VAT

  //invoice last month
  const invoiceLastMonth =
    data?.find((inv: any) => {
      return (
        Number(inv.checkMonth) === +checkMonth - 1 &&
        Number(inv.checkYear) === +checkYear
      )
    }) || null
  //invoice this month of last year
  const invoiceLastYear =
    data?.find((inv: any) => {
      return (
        Number(inv.checkMonth) === Number(checkMonth) &&
        Number(inv.checkYear) === +checkYear - 1
      )
    }) || null

  const calculatorDayLastMonth = invoiceLastMonth
    ? dayjs(invoiceLastMonth?.endDay).diff(invoiceLastMonth?.startDay, 'day') +
      1
    : 0

  const calculatorDayLastYear = invoiceLastYear
    ? dayjs(invoiceLastYear?.endDay).diff(invoiceLastYear?.startDay, 'day') + 1
    : 0

  //handle download invoice
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false)
  const onDownloadInvoice = async () => {
    const checkMonth = dayjs(date).format('MM')
    const checkYear = dayjs(date).format('YYYY')

    setDownloadLoading(true)
    try {
      const response = await axios.get(
        `${ENDPOINT}/Invoice/invoicepdf?plantId=${companyId}&year=${checkYear}&month=${checkMonth}`,
        { responseType: 'arraybuffer' }
      )
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      saveAs(blob, `${companyId}-${checkYear}-${checkMonth}.pdf`)
      window.open(url, '_blank')
      notification.success({
        message: 'ダウンロードに成功しました。',
        duration: 2,
        placement: 'topRight',
      })
    } catch (error) {
      console.log(error)
      notification.error({
        message: `${checkYear}/${checkMonth}の請求書が見つかりません。`,
        duration: 2,
        placement: 'topRight',
      })
    } finally {
      setDownloadLoading(false)
    }
  }

  return (
    <>
      <div id="TopInvDetail">
        <ButtonDefault
          className="Return-btn"
          title="戻る"
          iconbtn={faArrowLeft}
          handleClick={() => setIsSelect(true)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <WrapperDatePicker
          locale={locale}
          allowClear={false}
          onChange={onDayChange}
          picker="month"
          disabledDate={(current: any) => {
            const today = dayjs(lastInvoice?.endDay)
            return today < current
          }}
          value={dayjs(date)}
          // defaultPickerValue={dayjs().startOf('month')}
        />
        <Button
          className="download-invoice-btn"
          loading={downloadLoading}
          onClick={onDownloadInvoice}
          style={{ cursor: 'pointer' }}
        >
          <VerticalAlignBottomOutlined />

          <span>使用量と請求額をダウンロード</span>
        </Button>
      </div>
      <h3>料金明細</h3>
      <Spin spinning={false}>
        <WrapperBox>
          <div className="content">
            <div className="left">
              <p>請求額</p>
              <p
                style={{
                  fontSize: '1.5rem',
                  textAlign: 'center',
                }}
              >
                {formatNumber(Round(grossPrice) || 0)} 円
              </p>
            </div>
            <div className="right">
              <div className="row">
                <p>支払方法</p>
                <p>カード払い</p>
              </div>
              <Divider />
              <div className="row">
                <p>支払状税</p>
                <p>
                  {invoiceSelect?.isPaid === 'True'
                    ? 'お支払い済み'
                    : 'お支払い待ち'}
                </p>
              </div>
            </div>
          </div>
        </WrapperBox>
        <p>支払状況の反映には10日程度かかる場合があります。</p>
        <WrapperDetail>
          <div className="header">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BulbOutlined />
              <h3 style={{ marginLeft: '16px' }}>電気</h3>
            </div>
            <span>{releaseDate}</span>
          </div>
          <div className="content">
            <div className="row">
              <span>供給地点　特定番号</span>
              <span>{invoiceSelect?.plantNumber}</span>
            </div>
            <Divider style={{ margin: '16px 0px' }} />
            <div className="row">
              <span>契約種別</span>
              <span>{invoiceSelect?.planContent}</span>
            </div>
            <Divider style={{ margin: '16px 0px' }} />
            <div className="row">
              <span>使用期間</span>
              {startDate ? (
                startDate.slice(0, 4) === endDate.slice(0, 4) ? (
                  <span>
                    {startDate} ‐ {endDate?.slice(5)}（{calculatorDay + 1}日間）
                  </span>
                ) : (
                  <span>
                    <span>
                      {startDate} ‐ {endDate}（{calculatorDay + 1}日間）
                    </span>
                  </span>
                )
              ) : (
                <></>
              )}
            </div>
            <Divider style={{ margin: '16px 0px' }} />
            <div className="row">
              <div className="left">
                <div className="row">
                  <span>請求額</span>
                  <span
                    style={{
                      fontSize: '18px',
                      fontWeight: '500',
                    }}
                  >
                    {formatNumber(Round(+grossPrice) || 0)} 円
                  </span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
                <div className="row">
                  <span>電力量料金</span>
                  <span
                    style={{
                      fontSize: '18px',
                      fontWeight: '500',
                    }}
                  >
                    {formatNumber(Round(priceBase))} 円
                  </span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
                <div className="row">
                  <span>割引額</span>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <BsCaretUpFill />
                    {formatNumber(Round(+discount) || 0)} 円
                  </span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
                <div className="row">
                  <span>V2Hの料金</span>
                  <span>{formatNumber(Round(+v2hTanka) || 0)} 円</span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
                <div className="row">
                  <span>蓄電池</span>
                  <span>{formatNumber(Round(+batteryFee) || 0)} 円</span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
                <div className="row">
                  <span>消費税10%対象（税抜）</span>
                  <span>{formatNumber(Round(VAT))} 円</span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
                <div className="row">
                  <span>消費税8%対象（税抜）</span>
                  <span>{formatNumber(Round(0) || 0)} 円</span> {/* not use */}
                </div>
                <Divider style={{ margin: '16px 0px' }} />
              </div>
              <div className="right">
                <div className="row">
                  <span>使用量</span>
                  <span
                    style={{
                      fontSize: '18px',
                      fontWeight: '500',
                    }}
                  >
                    {formatNumber(
                      Round(Number(invoiceSelect?.consumptionValue)) || 0
                    )}
                    {' kWh'}
                  </span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
                <div className="row">
                  <span>前年同月</span>
                  <span>{calculatorDayLastYear} 日間</span>
                  <span>
                    {formatNumber(
                      Round(Number(invoiceLastYear?.consumptionValue)) || 0
                    )}
                    {' kWh'}
                  </span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
                <div className="row">
                  <span>前月</span>
                  <span>{calculatorDayLastMonth} 日間</span>
                  <span>
                    {formatNumber(
                      Round(Number(invoiceLastMonth?.consumptionValue)) || 0
                    )}
                    {' kWh'}
                  </span>
                </div>
                <Divider style={{ margin: '16px 0px' }} />
              </div>
            </div>
          </div>
        </WrapperDetail>
      </Spin>
      <h3>注意事項</h3>
      <ul>
        {/* <li>
          引越し、名義変更、支払者変更の際は、セキュリティ保護の観点から、以降の請求額や使用量が表示されなくなります。
          MODEL-Tマイページの「お
          引越しされた場合・お客さま番号の追加」からお客さま番号の再登録をお願いいたします。
        </li>
        <li>電気料金は複数まとめて請求されることがあります。</li>
        <li>
          スマートメーターの通信状況や、送配電事業者からのデータ連携
          により、一部時間帯の電力使用量が一時的に表示されない場合があります。
        </li>
        <li>
          ガス・電気セット割適用の場合、
          電気料金は、電気の計量後､一定期間を経た後に到来するガスの検針日に、
          ガス料金と合算して請求するため、ガス料金 の確定と同時に表示されます。
          詳細はこちら。
        </li>
        <li>
          電気料金の訂正等により、
          料金明細やグラフが正しく表示されない、または検針票と異なる場合があります。
          ただし、使用量･使用期間は以下の方法で 認できます。
        </li>
        <li>
          「請求｣ 「ガス」 「電気」
          ページの下部にある「使用量と請求額をダウンロード」の「CSVダウンロード」。
        </li>
        <li>
          使用期間 料金明細」ページに表示される日付は、原則として、
          ガス･電気合算のお客さまはガス検針日を、
          電気料金が単独で請求されているお客 . さまは電気計量を示しています。
        </li>
        <li>
          2023年2月検針分単価から、政府支援のガス▲30円/m3・低圧電気▲7円/KWhを適用しております。
          適用には条件があります。 詳細はこちらをご 確認ください。
        </li> */}
        <li>
          スマートメーターの通信状況や、送配電事業者からのデータ連携により、⼀部時間帯の電⼒使
          ⽤量が表⽰されない場合があります。
        </li>
        <li>
          電気料⾦の訂正等により、料⾦明細やグラフが正しく表⽰されない場合があります。
        </li>
      </ul>
    </>
  )
}

export default withErrorBoundary(DetailInvoice, {
  FallbackComponent: ErrorComponent,
})

const WrapperDatePicker = styled(DatePicker)`
  margin: 16px 0;
  width: 15vw;
`

const WrapperDetail = styled.div`
  margin-bottom: 16px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    width: 100%;
    border-radius: 10px;
    padding: 10px 40px;
    .row {
      display: flex;
      justify-content: space-between;
      .left {
        flex: 1;

        margin-right: 24px;
      }
      .right {
        flex: 1;

        margin-left: 24px;
        span {
          flex: 1;
          &:last-child {
            text-align: end;
          }
        }
      }
    }
  }
`

import { InboxOutlined } from '@ant-design/icons'
import { DatePicker, Form, Input, Upload, message, notification } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import ConfirmModal from './ConfirmModal'
import { addNewNotification } from '../../../Api/Notice'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

interface FormAlertProps {
  onClose: () => void
  onLoading: (value: boolean) => void
}

const FormNewAlert: React.FC<FormAlertProps> = ({ onClose, onLoading }) => {
  const [form] = Form.useForm()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [publicFrom, setPublicFrom] = useState<any>(dayjs().startOf('day'))
  const [publicTo, setPublicTo] = useState<any>(
    dayjs().add(1, 'week').startOf('day')
  )
  const [link, setLink] = useState(null)

  //focus input

  const queryClient = useQueryClient()
  const { mutate, isLoading: addNewLoading } = useMutation(addNewNotification, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['getAllNotification'] })
      onClose()
      notification.success({
        message: 'お知らせを登録しました。',
        duration: 1,
        placement: 'topRight',
      })
      form.resetFields()
      setShowConfirm(false)
    },
    onError: (error: any) => {
      console.log(error)
      notification.error({
        message: `${error.message}`,
        duration: 2,
        placement: 'topRight',
      })
      setShowConfirm(false)
    },
  })

  const onOKConfirm = () => {
    const title = form.getFieldValue('title')
    const htmlContents = form.getFieldValue('htmlContents')
    const From = dayjs(publicFrom).format('YYYYMMDD')
    const To = dayjs(publicTo).isValid()
      ? dayjs(publicTo).format('YYYYMMDD')
      : '99990101'
    mutate({
      variables: { title, htmlContents, From, To, link },
    })
  }

  const AddNewNotification = (values: any) => {
    setShowConfirm(true)
  }
  const onCancelConfirm = () => {
    setShowConfirm(false)
  }
  const handlePublicFromChange = (value: any) => {
    setPublicFrom(value)
  }

  // Disable all dates after the selected end date
  const disabledStartDate = (current: any) => {
    return current && current < publicFrom.startOf('day')
  }

  useEffect(() => {
    if (publicTo < publicFrom) {
      setPublicTo(publicFrom)
    }
  }, [publicFrom])

  //Control file up load
  const [fileList, setFileList] = useState<any>([])
  const allowedFileTypes = 'image/*,application/pdf'
  function getAction(file: any) {
    if (file.type === 'application/pdf') {
      return `${ENDPOINT}/pdf`
    }
    return `${ENDPOINT}/png`
  }
  const { Dragger } = Upload
  const uploadProps = {
    accept: allowedFileTypes,
    name: 'file',
    multiple: true,
    action: getAction,
    beforeUpload: (file: any) => {
      onLoading(true)
      return true
    },
    onChange(info: any) {
      const { status } = info.file
      setFileList(info.fileList)
      if (status === 'error') {
        message.error(`${info.file.name} ファイルアップロードに失敗しました。`)
      }

      if (['done', 'removed'].includes(status)) {
        const listLink = info.fileList.map(
          (item: any) => item.response?.split('\\_\\')[1]
        )
        setLink(listLink.toString())
        status === 'done' &&
          message.success(
            `${info.file.name} ファイルアップロードに成功しました。`
          )
        status === 'removed' &&
          message.success(`${info.file.name} ファイル削除に成功しました。.`)
      }

      info.fileList.every((file: any) =>
        ['done', 'error', 'removed'].includes(file.status)
      ) && onLoading(false)
    },
  }

  //auto focus
  const inputRef = useRef<any>()
  useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  return (
    <>
      <Form
        name="notification"
        form={form}
        labelAlign="left"
        layout="horizontal"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}
        autoComplete="off"
        onFinish={AddNewNotification}
      >
        <CustomFormItem
          label="タイトル"
          name="title"
          rules={[
            {
              required: true,
              message: 'タイトルを入力してください。',
            },
          ]}
        >
          <Input
            ref={inputRef}
            allowClear
            size={'large'}
            placeholder="タイトルを入力"
            showCount
            maxLength={100}
          />
        </CustomFormItem>

        <CustomFormItem
          className="public__from-to"
          label="掲載時間"
          // name="publicFrom"
        >
          <>
            <DatePicker
              allowClear={false}
              size={'large'}
              style={{ width: '100%' }}
              format={'YYYY/MM/DD'}
              defaultValue={dayjs().startOf('day')}
              locale={locale}
              onChange={(value) => handlePublicFromChange(value)}
            />
            <span style={{ fontSize: '28px', transform: 'translateY(-3px)' }}>
              ~
            </span>
            <DatePicker
              allowClear={true}
              size={'large'}
              style={{ width: '100%' }}
              format={'YYYY/MM/DD'}
              locale={locale}
              onChange={(value) => setPublicTo(value)}
              value={publicTo}
              disabledDate={disabledStartDate}
            />
          </>
        </CustomFormItem>

        <CustomFormItem
          rules={[
            {
              required: true,
              message: '本文を入力してください。',
            },
          ]}
          label="本文 "
          name="htmlContents"
          initialValue={''}
        >
          <CustomReactQuill
            theme="snow"
            onChange={(editor) => {
              if (editor === '<p><br></p>') {
                form.setFieldValue('htmlContents', '')
              }
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ['bold', 'italic', 'underline'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ color: [] }],
                ],
              },
            }}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'list',
              'indent',
              'link',
              'color',
              'image',
            ]}
          />
        </CustomFormItem>
        <CustomFormItem
          label={
            <>
              <p>ファイルを選択</p>
            </>
          }
          name="file"
        >
          <Dragger {...uploadProps} fileList={fileList}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">ファイル選択</p>
          </Dragger>
        </CustomFormItem>
      </Form>
      {showConfirm && (
        <ConfirmModal
          onOk={() => onOKConfirm()}
          onCancel={() => onCancelConfirm()}
          title="確認"
          message="お知らせを登録します。よろしいですか。"
          loading={addNewLoading}
        />
      )}
    </>
  )
}

export default withErrorBoundary(FormNewAlert, {
  FallbackComponent: ErrorComponent,
})

const CustomFormItem = styled(Form.Item)`
  &.not-require-item {
    label {
      position: relative;
      left: 11px;
    }
  }
  &.public__from-to {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .ant-upload .ant-upload-btn {
    padding: 4px;
  }
  .ant-upload-drag-icon {
    margin: 0px !important;
    & > .anticon {
      font-size: 36px !important;
    }
  }
  .ant-upload-text {
    font-size: 14px !important;
  }
  .ant-upload-list-item-action {
    opacity: 0.5 !important;
  }
`
const CustomReactQuill = styled(ReactQuill)`
  & > .ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    & > .ql-editor {
      height: 120px;
      overflow: auto;
      resize: vertical;
    }
  }
  & > .ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`

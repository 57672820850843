import React, { useContext } from 'react'
import { ParentInfo } from '../../Context'
import { Container } from '../../Styled/Pages.style'
import IchiranElectricTable from '../Electrics/ElectricTable'
import ChartPrice from './Chart'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import { withErrorBoundary } from 'react-error-boundary'

interface pageProps {
  modeTime: 'year' | 'month'
  dataNestChild: any
  dataMonthElectric?: any
  dataYearElectric?: any
  month?: string | null
  year?: string | null
}

const IchiranPrice: React.FC<pageProps> = ({
  modeTime,
  dataNestChild,
  dataMonthElectric,
  dataYearElectric,
  month,
  year,
}) => {
  const nameParent: string | undefined = useContext(ParentInfo)

  return (
    <>
      <Container>
        {modeTime === 'month' && (
          <>
            <div className="Power__Detail">
              <IchiranElectricTable
                data={dataMonthElectric}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
              />
            </div>
            <div className="Power__Chart">
              <ChartPrice
                data={dataMonthElectric}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
                firstName="モデル・ティでんき導入の効果"

                // lastName=""
              />
            </div>
          </>
        )}
        {modeTime === 'year' && (
          <>
            <div className="Power__Detail">
              <IchiranElectricTable
                data={dataYearElectric}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
              />
            </div>
            <div className="Power__Chart">
              <ChartPrice
                data={dataYearElectric}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
                firstName="モデル・ティでんき導入の効果"
                // lastName="を表示します。"
              />
            </div>
          </>
        )}
      </Container>
    </>
  )
}

export default withErrorBoundary(IchiranPrice, {
  FallbackComponent: ErrorComponent,
})

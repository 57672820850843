import { formatNumber } from '../Helper/FormatNumber'

interface YAxisProps {
  max: number
  min: number
  unit?: string
}

export const CustomYAxisPrice = ({ max, min }: YAxisProps) => {
  const y = max / 4

  return (
    <div className="y-axis-wrapper">
      <svg width="120" height="395" viewBox="0 1 120 400">
        <g className="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
          <line
            orientation="left"
            width="60"
            height="346"
            x="60"
            y="5"
            className="recharts-cartesian-axis-line"
            stroke="#666"
            fill="none"
            x1="120"
            y1="5"
            x2="120"
            y2="351"
          ></line>
          <g className="recharts-cartesian-axis-ticks">
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="346"
                x="60"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="351"
                x2="120"
                y2="351"
              ></line>
              <text
                orientation="left"
                width="60"
                height="346"
                x="112"
                y="351"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  0
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="346"
                x="60"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="264.5"
                x2="120"
                y2="264.5"
              ></line>
              <text
                orientation="left"
                width="60"
                height="346"
                x="112"
                y="264.5"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="346"
                x="60"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="178"
                x2="120"
                y2="178"
              ></line>
              <text
                orientation="left"
                width="60"
                height="346"
                x="112"
                y="178"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y * 2)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="346"
                x="60"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="91.5"
                x2="120"
                y2="91.5"
              ></line>
              <text
                orientation="left"
                width="60"
                height="346"
                x="112"
                y="91.5"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y * 3)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="346"
                x="60"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="5"
                x2="120"
                y2="5"
              ></line>
              <text
                orientation="left"
                width="60"
                height="346"
                x="112"
                y="9.5"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(max)}
                </tspan>
              </text>
            </g>
          </g>
          <text
            offset="-40"
            x="20"
            y="178"
            fill="#808080"
            transform="rotate(-90, 10, 178)"
            className="recharts-text recharts-label"
            textAnchor="start"
            style={{ textAnchor: 'middle' }}
          >
            <tspan x="20" dy="0.355em">
              千円
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  )
}

export const CustomYAxisCo2 = ({ max, min, unit }: YAxisProps) => {
  const y = max / 4

  return (
    <div className="y-axis-wrapper">
      <svg width="120" height="400" viewBox="0 -3 84 388">
        <g className="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
          <line
            orientation="left"
            width="60"
            height="319"
            x="40"
            y="5"
            className="recharts-cartesian-axis-line"
            stroke="#666"
            fill="none"
            x1="100"
            y1="5"
            x2="100"
            y2="324"
          ></line>
          <g className="recharts-cartesian-axis-ticks">
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="319"
                x="40"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="94"
                y1="324"
                x2="100"
                y2="324"
              ></line>
              <text
                orientation="left"
                width="60"
                height="319"
                x="92"
                y="324"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="92" dy="0.355em">
                  0
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="319"
                x="40"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="94"
                y1="244.25"
                x2="100"
                y2="244.25"
              ></line>
              <text
                orientation="left"
                width="60"
                height="319"
                x="92"
                y="244.25"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="92" dy="0.355em">
                  {formatNumber(y)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="319"
                x="40"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="94"
                y1="164.5"
                x2="100"
                y2="164.5"
              ></line>
              <text
                orientation="left"
                width="60"
                height="319"
                x="92"
                y="164.5"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="92" dy="0.355em">
                  {formatNumber(y * 2)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="319"
                x="40"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="94"
                y1="84.75"
                x2="100"
                y2="84.75"
              ></line>
              <text
                orientation="left"
                width="60"
                height="319"
                x="92"
                y="84.75"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="92" dy="0.355em">
                  {formatNumber(y * 3)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="319"
                x="40"
                y="5"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="94"
                y1="5"
                x2="100"
                y2="5"
              ></line>
              <text
                orientation="left"
                width="60"
                height="319"
                x="92"
                y="9.5"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="92" dy="0.355em">
                  {formatNumber(max)}
                </tspan>
              </text>
            </g>
          </g>
          <text
            offset="-20"
            x="20"
            y="164.5"
            fill="#808080"
            transform="rotate(-90, 10, 164.5)"
            className="recharts-text recharts-label"
            textAnchor="start"
            style={{ textAnchor: 'middle' }}
          >
            <tspan x="20" dy="0.355em">
              {unit}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  )
}

export const CustomYAxisElectricUp = ({ max, min, unit }: YAxisProps) => {
  const y = max / 4

  return (
    <div className="y-axis-wrapper">
      <svg width="120" height="235" viewBox="0 0 120 235">
        <g className="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
          <line
            orientation="left"
            width="60"
            height="179"
            x="60"
            y="55"
            className="recharts-cartesian-axis-line"
            stroke="#666"
            fill="none"
            x1="120"
            y1="55"
            x2="120"
            y2="234"
          ></line>
          <g className="recharts-cartesian-axis-ticks">
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <text
                orientation="left"
                width="60"
                height="180"
                x="112"
                y="228"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="110" dy="0.355em">
                  0
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="179"
                x="60"
                y="55"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="189.25"
                x2="120"
                y2="189.25"
              ></line>
              <text
                orientation="left"
                width="60"
                height="179"
                x="112"
                y="189.25"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="179"
                x="60"
                y="55"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="144.5"
                x2="120"
                y2="144.5"
              ></line>
              <text
                orientation="left"
                width="60"
                height="179"
                x="112"
                y="144.5"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y * 2)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="179"
                x="60"
                y="55"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="99.75"
                x2="120"
                y2="99.75"
              ></line>
              <text
                orientation="left"
                width="60"
                height="179"
                x="112"
                y="99.75"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y * 3)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="179"
                x="60"
                y="55"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="55"
                x2="120"
                y2="55"
              ></line>
              <text
                orientation="left"
                width="60"
                height="179"
                x="112"
                y="55"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(max)}
                </tspan>
              </text>
            </g>
          </g>
          <text
            offset="-40"
            x="20"
            y="144.5"
            fill="#808080"
            transform="rotate(-90, 20, 144.5)"
            className="recharts-text recharts-label"
            textAnchor="start"
            style={{ textAnchor: 'middle' }}
          >
            <tspan x="20" dy="0.355em">
              {unit}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  )
}

export const CustomYAxisElectricDown = ({ max, min, unit }: YAxisProps) => {
  const y = max / 4

  return (
    <div className="y-axis-wrapper">
      <svg width="120" height="200" viewBox="0 0 120 200">
        <g className="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
          <line
            orientation="left"
            width="60"
            height="180"
            x="60"
            y="0"
            className="recharts-cartesian-axis-line"
            stroke="#666"
            fill="none"
            x1="120"
            y1="0"
            x2="120"
            y2="180"
          ></line>
          <g className="recharts-cartesian-axis-ticks">
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="180"
                x="60"
                y="0"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="45"
                x2="120"
                y2="45"
              ></line>
              <text
                orientation="left"
                width="60"
                height="180"
                x="112"
                y="45"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="180"
                x="60"
                y="0"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="90"
                x2="120"
                y2="90"
              ></line>
              <text
                orientation="left"
                width="60"
                height="180"
                x="112"
                y="90"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y * 2)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="180"
                x="60"
                y="0"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="135"
                x2="120"
                y2="135"
              ></line>
              <text
                orientation="left"
                width="60"
                height="180"
                x="112"
                y="135"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(y * 3)}
                </tspan>
              </text>
            </g>
            <g className="recharts-layer recharts-cartesian-axis-tick">
              <line
                orientation="left"
                width="60"
                height="180"
                x="60"
                y="0"
                className="recharts-cartesian-axis-tick-line"
                stroke="#666"
                fill="none"
                x1="114"
                y1="180"
                x2="120"
                y2="180"
              ></line>
              <text
                orientation="left"
                width="60"
                height="180"
                x="112"
                y="180"
                stroke="none"
                fill="#666"
                className="recharts-text recharts-cartesian-axis-tick-value"
                textAnchor="end"
              >
                <tspan x="112" dy="0.355em">
                  {formatNumber(max)}
                </tspan>
              </text>
            </g>
          </g>
          <text
            offset="-40"
            x="20"
            y="90"
            fill="#808080"
            transform="rotate(-90, 20, 90)"
            className="recharts-text recharts-label"
            textAnchor="start"
            style={{ textAnchor: 'middle' }}
          >
            <tspan x="20" dy="0.355em">
              {unit}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  )
}

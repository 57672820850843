import styled from 'styled-components'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import { withErrorBoundary } from 'react-error-boundary'

const Co2Table = ({ tableData, selectedLabel }: any) => {
  const arr = tableData?.data
  const rows = arr?.length
  const cols = arr?.[0].split(',').length
  const averages = []

  for (let colIndex = 0; colIndex < cols; colIndex++) {
    let sum = 0
    let count = 0
    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
      const value = arr[rowIndex].split(',')[colIndex]
      const num = value !== 'null' ? Number(value) : 0
      sum += num
      count++
    }
    const average = count > 0 ? sum / count : 0
    averages.push(average)
  }

  const col = [
    '沖縄電力',
    '関西電力',
    '九州電力',
    '四国電力',
    '中国電力',
    '中部電力',
    '東京電力',
    '東北電力',
    '北海道電力',
    '北陸電力',
  ]

  return (
    <>
      <TableCo2MNGWrapper
        id={tableData.label}
        className={tableData.label === selectedLabel ? 'blue' : ''}
      >
        <thead>
          <tr>
            <th className="table-head" colSpan={13}>
              {tableData.label}
            </th>
          </tr>
        </thead>
        <tbody className="border">
          <tr>
            <td className="row-head"></td>
            <td className="row-head">1月</td>
            <td className="row-head">2月</td>
            <td className="row-head">3月</td>
            <td className="row-head">4月</td>
            <td className="row-head">5月</td>
            <td className="row-head">6月</td>
            <td className="row-head">7月</td>
            <td className="row-head">8月</td>
            <td className="row-head">9月</td>
            <td className="row-head">10月</td>
            <td className="row-head">11月</td>
            <td className="row-head">12月</td>
          </tr>
          {tableData?.data?.map((row: any, index: number) => (
            <tr key={index}>
              <td style={{ minWidth: '80px' }}>{col[index]}</td>
              {row.split(',').map((data: any, i: number) => (
                <td
                  style={{
                    textAlign: 'end',
                    background: data === 'null' ? 'yellow' : '',
                  }}
                  key={i}
                >
                  {data === 'null' ? '' : Number(data)?.toFixed(2)}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td>電力会社平均</td>
            {averages?.map((item: any, idx: number) => (
              <td key={idx} style={{ textAlign: 'end' }}>
                {item.toFixed(2) || ''}
              </td>
            ))}
          </tr>
        </tbody>
      </TableCo2MNGWrapper>
    </>
  )
}

export default withErrorBoundary(Co2Table, {
  FallbackComponent: ErrorComponent,
})

const TableCo2MNGWrapper = styled.table`
  border-collapse: collapse;
  margin: 20px 0px 20px 0px;
  td {
    border: 1px solid black;
    padding: 4px;
    width: 200px;
  }
  .table-head {
    font-size: 16px;
    font-weight: 500;
    height: 30px;
    text-align: left;
  }
  .row-head {
    text-align: center;
  }

  &.blue {
    .border {
      border: 1px solid #4096ff;
      box-shadow: 0px 0px 0.5px 2px #4096ff;
    }
  }
`

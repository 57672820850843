import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import styled from 'styled-components'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface ButtonDefaultProps {
  className?: string
  type?: 'default' | 'primary' | 'dashed' | 'link' | 'text'
  htmlType?: 'button' | 'submit' | 'reset'
  title: string
  loading?: boolean
  iconbtn?: any
  handleClick?: () => void
}
function ButtonDefault(props: ButtonDefaultProps) {
  return (
    <WrapperButton>
      <Button
        onClick={props.handleClick}
        className={props.className}
        type={props.type}
        htmlType={props.htmlType}
        loading={props.loading}
      >
        {props.iconbtn && (
          <FontAwesomeIcon icon={props.iconbtn} className="icon-btn" />
        )}
        {props.title}
      </Button>
    </WrapperButton>
  )
}

export default withErrorBoundary(ButtonDefault, {
  FallbackComponent: ErrorComponent,
})

const WrapperButton = styled.div`
  button {
    color: White;
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    border-style: None;
    height: 32px;
    width: 100px;

    &:hover {
      background-color: #1d2f51e6 !important;
    }
    &.submit {
      width: 165px;
      margin-top: 4px;
    }
    &.ForgotPass {
      width: unset;
    }
    &.tab-btn {
      // width: 100px;
      background-color: #c7cbd3;
    }
    &.pieChart {
      width: 120px;
    }
    .icon-btn {
      margin-right: 4px;
    }
    @media (max-width: 768px) {
      width: 80px;
      padding: 0 6px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 90px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      width: 100px;
    }

    @media (max-width: 520px) {
      width: 80px;
      padding: 0 6px;
      font-size: 12px;
    }
    &.inactive {
      background-color: #c7cbd3;
    }
  }
`

import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface DataType {
  data: any
  mode: string
  year?: string
  month?: string
}

const SurplusPowerTable: React.FC<DataType> = ({ data, mode, year, month }) => {
  const residualElectricResponses = data?.residualElectricResponses
  const splitIndex = Math.round(residualElectricResponses?.length / 2)
  const firstHalf: [{}] = residualElectricResponses?.slice(0, splitIndex)
  const secondHalf: [{}] = residualElectricResponses?.slice(splitIndex)

  secondHalf?.push({ time: '合計', value: data.totalValue })

  return (
    <TableWrapper>
      {mode === 'month' ? (
        <>
          <table
            style={{
              borderCollapse: 'collapse',
              height: '100px',
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: '1px solid black',
                    padding: '4px 8px',
                    minWidth: '100px',
                    height: 32,
                    textAlign: 'center',
                  }}
                >
                  {dayjs(month).format('YYYY年MM月')}
                </th>
                <th
                  style={{
                    border: '1px solid black',
                    padding: '4px 8px',
                    minWidth: '100px',
                    height: 32,
                    textAlign: 'center',
                  }}
                >
                  使用量（kWh）
                </th>
              </tr>
            </thead>
            <tbody>
              {firstHalf?.map(({ value, time }: any) => (
                <tr key={time}>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '4px 8px',
                      minWidth: '100px',
                      height: 32,
                      textAlign: 'center',
                    }}
                  >
                    {time}日
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '4px 8px',
                      minWidth: '100px',
                      height: 32,
                      textAlign: 'end',
                      background: value === 0 ? 'yellow' : '',
                    }}
                  >
                    {Number(value)?.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <table
            style={{
              borderCollapse: 'collapse',
              height: '100px',
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: '1px solid black',
                    padding: '4px 8px',
                    minWidth: '100px',
                    height: 32,
                    textAlign: 'center',
                  }}
                >
                  {dayjs(month).format('YYYY年MM月')}
                </th>
                <th
                  style={{
                    border: '1px solid black',
                    padding: '4px 8px',
                    minWidth: '100px',
                    height: 32,
                    textAlign: 'center',
                  }}
                >
                  使用量（kWh）
                </th>
              </tr>
            </thead>
            <tbody>
              {secondHalf?.map(({ value, time }: any) => (
                <tr key={time}>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '4px 8px',
                      minWidth: '100px',
                      height: 32,
                      textAlign: 'center',
                    }}
                  >
                    {time === '合計' ? '合計' : `${time}日`}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '4px 8px',
                      minWidth: '100px',
                      height: 32,
                      textAlign: 'end',
                      background: value === 0 ? 'yellow' : '',
                    }}
                  >
                    {Number(value)?.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <table
          style={{
            borderCollapse: 'collapse',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: '1px solid black',
                  padding: '4px 8px',
                  minWidth: '100px',
                  height: 32,
                  textAlign: 'center',
                }}
              >
                {dayjs(year).format('YYYY年')}
              </th>
              <th
                style={{
                  border: '1px solid black',
                  padding: '4px 8px',
                  minWidth: '100px',
                  height: 32,
                  textAlign: 'center',
                }}
              >
                使用量（kWh）
              </th>
            </tr>
          </thead>
          <tbody>
            {residualElectricResponses?.map(({ value, time }: any) => (
              <tr key={time}>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '4px 8px',
                    minWidth: '100px',
                    height: 32,
                    textAlign: 'center',
                  }}
                >
                  {time}月
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '4px 8px',
                    minWidth: '100px',
                    height: 32,
                    textAlign: 'end',
                    background: value === 0 ? 'yellow' : '',
                  }}
                >
                  {Number(value)?.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </TableWrapper>
  )
}

export default withErrorBoundary(SurplusPowerTable, {
  FallbackComponent: ErrorComponent,
})

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
  table {
  }

  th {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: var(--theme-color);
    color: #fff;
  }
`

import { saveAs } from 'file-saver'
import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

//get surplus
export const useFetchSurplus = (
  plantID: string | number | null,
  month: string | number | null
) => {
  const fetchData = async () => {
    const response = await api
      .get(
        `${ENDPOINT}/ResidualElectric/GetMonth?plantId=${plantID}&date=${month}`
      )
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['getSurplus', plantID, month],
    queryFn: () => fetchData(),
    staleTime: 10 * 1000,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!plantID && !!month,
  })
}

export const useFetchSurplusByYear = (
  plantID: string | number | null,
  year: string | number | null
) => {
  const fetchData = async () => {
    const response = await api
      .get(
        `${ENDPOINT}/ResidualElectric/GetYear?plantId=${plantID}&date=${year}`
      )
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['getSurplusByYear', plantID, year],
    queryFn: () => fetchData(),
    staleTime: 10 * 1000,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!plantID && !!year,
  })
}

//Down Surplus Power
export const DownloadTemplateSurplus = async (
  plantID: string,
  date: string
) => {
  await api({
    method: 'post',
    url: `${ENDPOINT}/ResidualElectric/TemplateDown?plantId=${plantID}&date=${date}`,
    responseType: 'arraybuffer',
  }).then((res) => {
    const contentDisposition = res.headers['content-disposition']
    const filenameMatch = contentDisposition.match(/filename="(.+)"/)
    let filename = '係数入力.csv' // Default filename
    if (filenameMatch && filenameMatch[1]) {
      filename = filenameMatch[1]
    }
    const blob = new Blob([res.data], {
      type: 'text/csv',
    })
    saveAs(blob, filename)
  })
}

import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Badge, Form, Input, Spin, notification } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  useFetchNotificationDetail,
  useFetchNotificationHomePage,
} from '../../Api/Notice'
import DetailNotification from '../Admin/AlertMng/DetailNotification'
import { TypeAccount } from '../Context'
import ButtonDefault from '../UI/Button/ButtonDefault'
import { SESSION_STORAGE_ITEM } from '../../constants/common'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

interface formProps {
  onClick: () => void
}

const LogIn: React.FC<formProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [openAlertDetail, setOpenAlertDetail] = useState(false)
  const [noticeShowDetailID, setNoticeShowDetailID] = useState('')

  const [form] = Form.useForm()
  const Navigate = useNavigate()
  const userInput = useRef<any>(null)
  const userInputPassword = useRef<any>(null)

  const { updateValue } = useContext(TypeAccount)
  //default focus user in put
  useEffect(() => {
    userInput.current.focus()
  }, [])

  //auto focus close btn
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 13) {
        setOpenAlertDetail(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  //handle login
  const onLogin = (values: any) => {
    const { username, password } = values
    setLoading(true)
    const dataAccount = () => {
      axios
        .post(`${ENDPOINT}/User/Login`, {
          userId: username,
          password,
        })
        .then((response) => {
          updateValue(response?.data?.properties?.companytype)
          sessionStorage.setItem(
            'AccountType',
            JSON.stringify(response?.data?.properties?.companytype)
          )
          sessionStorage.setItem(
            'AccountTitle',
            JSON.stringify(response?.data?.properties?.user_id)
          )
          sessionStorage.setItem('AccountID', response?.data?.id)
          sessionStorage.setItem(
            SESSION_STORAGE_ITEM.TOKEN,
            response?.data?.token
          )

          notification.success({
            message: 'ログインに成功しました。',
            duration: 2,
            placement: 'topRight',
          })
          Navigate('/electric')
        })
        .catch((error) => {
          // Reset form password value if an error occurs

          if (
            error?.response?.data === 'User not found' ||
            error?.response?.data === 'Wrong password'
          ) {
            setMessageError(
              '入力されたユーザー名またはパスワードが正しくありません。'
            )
          } else {
            notification.error({
              message: `Error。`,
              duration: 4,
              placement: 'topRight',
              description: `${error.message}`,
            })

            form.setFieldValue('password', '')
            userInputPassword?.current?.focus()
          }
        })
        .finally(() => {
          setLoading(false)
          form.setFieldValue('password', '')
        })
    }
    dataAccount()
  }

  //notification
  const { data: allNotification, isLoading: allNotificationLoading } =
    useFetchNotificationHomePage()

  const { data: notificationDetail, isLoading: notificationDetailLoading } =
    useFetchNotificationDetail(noticeShowDetailID)

  return (
    <FormLogin>
      <FormStyle
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={onLogin}
        autoComplete="off"
      >
        {messageError && (
          <MessageError>
            入力されたユーザー名またはパスワードが正しくありません。
          </MessageError>
        )}
        <Form.Item
          label="ユーザー名"
          name="username"
          rules={[
            {
              required: true,
              message: 'ユーザー名を入力してください。',
            },
          ]}
        >
          <Input
            ref={userInput}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="ユーザー名"
            onChange={() => setMessageError('')}
          />
        </Form.Item>

        <Form.Item
          label="パスワード"
          name="password"
          rules={[
            {
              required: true,
              message: 'パスワードを入力してください。',
            },
          ]}
        >
          <Input
            ref={userInputPassword}
            name="password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="パスワード"
            onChange={() => setMessageError('')}
          />
        </Form.Item>

        <Form.Item wrapperCol={{}}>
          <ButtonDefault
            className="submit"
            type="primary"
            htmlType="submit"
            title="ログイン"
            loading={loading}
          />
        </Form.Item>
        <p onClick={() => props.onClick()} className="Forgot-pass">
          <FontAwesomeIcon icon={faArrowRightLong} className="arrow-icon" />
          ユーザー名またはパスワードをお忘れの方はこちら
        </p>
      </FormStyle>

      {allNotificationLoading && <Spin />}
      {allNotification?.length > 0 && (
        <AlertWrapper>
          <h5 className="header-alert-modal">お知らせ</h5>
          <ScrollWrapper>
            {allNotification?.map((item: any) => {
              const checkNewNotification =
                dayjs().diff(dayjs(item.publishFrom), 'day') <= 1

              return (
                <AlertItem
                  key={item.id}
                  onClick={() => {
                    setNoticeShowDetailID(item.id)
                    setOpenAlertDetail(true)
                  }}
                  style={{ marginTop: '10px' }}
                  description={
                    <>
                      {checkNewNotification ? (
                        <Badge.Ribbon
                          text="New"
                          className="badge-ribbon"
                          style={{
                            top: -6,
                            right: -14,
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <span style={{ minWidth: '80px' }}>
                              {dayjs(item.publishFrom).format('YYYY-MM-DD')}
                            </span>
                            <span className="notice_title--limited">
                              {item.title}
                            </span>
                          </div>
                        </Badge.Ribbon>
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <span style={{ minWidth: '80px' }}>
                            {dayjs(item.publishFrom).format('YYYY-MM-DD')}
                          </span>
                          <span className="notice_title--limited">
                            {item.title}
                          </span>
                        </div>
                      )}
                      <p
                        className="notice_detail--limited"
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}
                      />
                    </>
                  }
                  type="info"
                />
              )
            })}
          </ScrollWrapper>
        </AlertWrapper>
      )}
      {openAlertDetail && (
        <DetailNotification
          data={notificationDetail}
          onClose={() => setOpenAlertDetail(false)}
          open={openAlertDetail}
          loading={notificationDetailLoading}
        />
      )}
    </FormLogin>
  )
}

export default LogIn

const FormLogin = styled.div`
  flex: 2;
  display: flex;
  background-color: #e9ebee;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  .Forgot-pass {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #4477da;
    margin-top: 6px;
    text-decoration: underline;
    font-weight: 900;
    .arrow-icon {
      margin-right: 4px;
    }
  }
  @media (max-width: 768px) {
    order: 2;
  }
`

const FormStyle = styled(Form)`
  justify-content: center;
  display: flex;
  margin: auto;
  padding-top: 40px;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
  @media (max-width: 768px) {
    min-width: unset;
  }
  .ant-input {
    height: 50px;
  }
  .ant-input-affix-wrapper {
    padding: 0px 8px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
  }
  .ant-row {
    display: block;
  }
  .ant-form-item {
    margin-bottom: 20px;
  }
  .ant-form-item-row {
    justify-content: flex-end;
  }
  .ant-form-item-required {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #45546f !important;
    height: unset !important;
    margin-left: 4px;
    &::after {
      content: '' !important;
    }
    &::before {
      content: '' !important;
    }
  }
  .ant-form-item-control-input {
    width: 344px;
  }
  .ant-form-item-explain-error {
    width: 300px;
    margin-top: 4px;
    margin-left: 8px;
  }
  .ant-form-item-control-input-content {
    text-align: center;
  }
  .anticon {
    font-size: 20px;
    margin-right: 4px;
  }
`
const MessageError = styled.p`
  position: absolute;
  top: 10px;
  color: red;
  font-size: 16px;
`

const AlertWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 40vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
  .header-alert-modal {
    z-index: 10;
    position: sticky;
    top: 0px;
    margin: 0px;
    padding: 4px 24px;
    width: 82%;
    background: #45546f;
    color: #f1f1f1;
    font-size: 16px;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`

const AlertItem = styled(Alert)`
  background-color: rgb(233, 235, 238);
  padding: 8px;
  border: none;
  border-top: 1px solid #fff;
  border-radius: 0px;
  /* width: 80%; */
  color: #45546f;
  cursor: pointer;
  :hover {
    background-color: rgb(206, 212, 219);
  }
  p {
    margin: 4px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 16px;
    -webkit-line-clamp: 1;
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .notice_title--limited {
    margin-left: 4px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    -webkit-line-clamp: 1;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .Alert-Type {
    padding: 2px 8px;
    margin-left: 10px;
    border-radius: 4px;
    color: #45546f;
    font-size: 16px;
    font-weight: 600;
  }
  .badge-ribbon {
    animation: colorChange 3s linear infinite;
    @keyframes colorChange {
      0% {
        background-color: var(--theme-color); /* Light Blue */
      }
      50% {
        background-color: #2a8eff; /* Light Blue */
      }
      100% {
        background-color: var(--theme-color); /* Light Blue */
      }
    }
  }
`
export const ScrollWrapper = styled.div`
  align-items: center;

  width: 80%;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
`

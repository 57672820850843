import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Checkbox, Spin, TreeSelect } from 'antd'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  useFetchCompanies,
  useFetchNestCompanies,
} from '../../../Api/Companies'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface GroupedValues {
  [parentId: string]: string[]
}

interface PageProps {
  onSelect: (values: GroupedValues) => void
  onSelect1?: any
  setNestChild: any
  valueCheck: any[]
  onChangeCheck: any
  modePage?: string
  loadingBtnDownload: boolean
  onDownload: () => void
}

const SelectCompanies: React.FC<PageProps> = ({
  onSelect,
  onSelect1,
  setNestChild,
  valueCheck,
  onChangeCheck,
  modePage,
  loadingBtnDownload,
  onDownload,
}) => {
  const AccountID: string | number | any = sessionStorage.getItem('AccountID')
  const {
    data: parentCompanies,
    isLoading: isLoadingAccount,
    isError,
  } = useFetchCompanies(AccountID)
  const listParent = parentCompanies?.results?.map(
    (item: any, idx: number) => item.id
  )

  const { data: listChild, isLoading: loadingNestChild } =
    useFetchNestCompanies(listParent)

  const convertData = (data: any) => {
    const convertedData = []
    for (const key in data) {
      const child = parentCompanies?.results.find(
        (item: any) => item.id === key
      )

      const node: any = {
        title: child?.properties?.name,
        value: key,
        key: key,
        children: [],
      }
      if (data[key].total) {
        data[key].results.forEach((result: any) => {
          const childNode = {
            title: result.properties.name,
            value: result.id,
            key: result.id,
          }
          node.children.push(childNode)
        })
      }
      convertedData.push(node)
    }
    return convertedData
  }

  const treeData: any = convertData(listChild)

  const allOptionKeys = treeData?.flatMap((node: any) => {
    return node?.children?.length > 0
      ? node.children.map((child: any) => child.value)
      : [node.value]
  })

  useEffect(() => {
    onChangeCheck(allOptionKeys)
  }, [listChild])

  const groupValues = (): GroupedValues => {
    const groupedValues: GroupedValues = {}

    treeData?.forEach((item: any) => {
      if (item.children) {
        const parentID = item.value
        const childrenIDs: string[] = []

        item.children.forEach((child: any) => {
          const childID = child.value
          if (valueCheck.includes(childID)) {
            childrenIDs.push(childID)
          }
        })

        if (childrenIDs.length > 0) {
          groupedValues[parentID] = childrenIDs
        }
      }
    })
    return groupedValues
  }

  useEffect(() => {
    if (!loadingNestChild) {
      setNestChild(treeData)
    }
  }, [loadingNestChild])

  useEffect(() => {
    const list = groupValues()

    onSelect(list)
    onSelect1(valueCheck)
  }, [valueCheck])

  return (
    <>
      {modePage === 'Invoice' && (
        <>
          <Button
            loading={loadingBtnDownload}
            disabled={loadingBtnDownload}
            onClick={() => onDownload()}
            type="text"
            style={{
              flex: 1,
              marginBottom: '10px',
              fontWeight: 600,
              background: '#1d2f51',
              color: '#fff',
            }}
            icon={<DownloadOutlined />}
          >
            ファイルダウンロード
          </Button>

          <div
            style={{
              width: '37.4%',
              display: 'flex',
              gap: '10px',
              marginBottom: '10px',
            }}
          >
            <Button
              type="text"
              style={{
                minWidth: '120px',
                cursor: 'default',
                fontWeight: 600,
                background: 'rgba(0, 0, 0, 0.06)',
              }}
            >
              ファイル種別
            </Button>
            <ButtonStyle>月額請求入金一覧</ButtonStyle>
          </div>
        </>
      )}

      <div
        style={{
          display: 'flex',
          gap: '10px',
          width: '37.4%',
          marginBottom: '10px',
        }}
      >
        <Button
          type="text"
          style={{
            minWidth: '120px',
            cursor: 'default',
            fontWeight: 600,
            background: 'rgba(0, 0, 0, 0.06)',
          }}
        >
          顧客
        </Button>

        <TreeWrapper>
          <TreeSelect
            maxTagCount="responsive"
            maxTagTextLength={3}
            disabled={loadingNestChild || isLoadingAccount}
            allowClear
            value={valueCheck}
            onChange={(value) => {
              onChangeCheck(value)
            }}
            treeCheckable={true}
            placeholder="顧客や拠点を選択"
            style={{ opacity: loadingNestChild || isLoadingAccount ? 0.5 : 1 }}
            treeData={[
              {
                title:
                  valueCheck.length > 0 ? (
                    <Checkbox
                      checked={true}
                      onChange={() => onChangeCheck([])}
                      style={{
                        color: '#286FBE',
                        cursor: 'pointer',
                        transform: 'translateX(-4px)',
                      }}
                    >
                      <strong style={{ marginLeft: '4px' }}>
                        全て選択解除
                      </strong>
                    </Checkbox>
                  ) : (
                    <Checkbox
                      checked={false}
                      onChange={() => onChangeCheck(allOptionKeys)}
                      style={{
                        color: '#286FBE',
                        cursor: 'pointer',
                        transform: 'translateX(-4px)',
                      }}
                    >
                      <strong style={{ marginLeft: '4px' }}>全て</strong>
                    </Checkbox>
                  ),
                value: 'xxx',
                disableCheckbox: true,
                disabled: true,
              },
              ...treeData,
            ]}
          />
          {(loadingNestChild || isLoadingAccount) && (
            <Spin
              size="small"
              style={{ position: 'absolute', left: '50%', top: '6px' }}
              indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
            />
          )}
        </TreeWrapper>
      </div>
    </>
  )
}

export default withErrorBoundary(SelectCompanies, {
  FallbackComponent: ErrorComponent,
})

const ButtonStyle = styled(Button)`
  flex: 1;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: #fff;
  border-style: None;
  height: 32px;
  cursor: default;

  &:hover {
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    color: #fff !important;
  }
`

const TreeWrapper = styled.div`
  flex: 1;
  position: relative;

  .ant-select {
    width: 100%;
    .ant-select-selector {
      background: var(--theme-color) !important;
      .ant-select-selection-item {
        background: #636363;

        color: #fff;
      }
    }
  }
  .ant-select-selection-placeholder {
    color: white;
  }
  .ant-select-suffix {
    color: #fff;
  }
  .ant-select-arrow-loading {
    color: #fff;
  }
`

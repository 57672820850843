import styled from 'styled-components'

export const ButtonRow = styled.div`
  flex-wrap: wrap;
  margin: 20px 0 40px;
  display: flex;
  gap: 40px;
  justify-content: center;
  @media (max-width: 978px) {
    gap: 20px;
  }
`

export const WrapperBody = styled.div`
  display: block;
  margin: auto;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  span {
    margin-top: 10px;
    max-width: 240px;
    font-size: 12px;
  }
`

export const Container = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
  display: flex;
  gap: 10px;

  &.electricPage {
    padding-bottom: 50px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 60px;
  }
  .Power__Detail {
    flex: 3;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 480px;
    gap: 10px;
  }
  .Power__Chart {
    position: relative;
    flex: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    height: 480px;
  }
`

import React, { useEffect, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'
import { CalculatorYAxis } from '../../../Utils/CustomChart/CalculatorYAxisChart'
import {
  CustomYAxisElectricDown,
  CustomYAxisElectricUp,
} from '../../../Utils/CustomChart/CustomYAxisChart'
import { RenderLegendIchiranChart } from '../../../Utils/CustomChart/RenderLegend'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import {
  RoundNumberAfterChangeUnit,
  formatNumber,
} from '../../../Utils/Helper/FormatNumber'
import { checkChangeUnitElectric } from '../../../Utils/Helper/HandleData'
import { handleDataElectric, maxBarChart } from '../HandleDataIchiran'
import { getLabelShow } from '../../../Utils/Helper/HandleLable'

interface chartProps {
  data?: any
  dataNestChild: any
  monthSelect?: string | null
  yearSelect?: string | null
  firstName?: string
  lastName?: string
  nameParent?: string | undefined
  modeView: string
}

const IchiranElectricChart: React.FC<chartProps> = React.memo(
  ({
    data,
    dataNestChild,
    monthSelect,
    yearSelect,
    firstName,
    lastName,
    nameParent,
    modeView,
  }) => {
    const [activeDataKeys, setActiveDataKeys] = useState([
      'generate',
      'consumption',
      'reverse',
      'compare',
      'nomalElectric',
    ])

    const handleClickLegend = (e: any) => {
      const { dataKey } = e
      const index = activeDataKeys.indexOf(dataKey)

      if (index === -1) {
        setActiveDataKeys([...activeDataKeys, dataKey])
      } else {
        setActiveDataKeys(activeDataKeys.filter((key) => key !== dataKey))
      }
    }

    const isDataKeyActive = (dataKey: any) =>
      activeDataKeys.indexOf(dataKey) !== -1

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    useEffect(() => {
      const handleResize = () => {
        setTimeout(() => setScreenWidth(window.innerWidth), 100)
      }
      window.addEventListener('resize', handleResize)

      // cleanup event
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    const calculateWidthChart = (screenWidth: number) => {
      if (screenWidth < 1025) {
        return screenWidth * 0.85
      } else {
        return screenWidth * 0.54
      }
    }
    const [widthChart, setWidthChart] = useState<number>(
      calculateWidthChart(screenWidth)
    )
    useEffect(() => {
      setWidthChart(calculateWidthChart(screenWidth))
    }, [screenWidth])

    //handle data
    let renderData: any = []
    renderData = handleDataElectric(data, dataNestChild, true, nameParent)

    const isChangeBar = renderData
      ?.map((item: any) => item.reverse)
      .some((val: any) => val < 0)

    const isChangeUnit = checkChangeUnitElectric(renderData, true)
    if (isChangeUnit) {
      renderData = renderData?.map((val: any, idx: number) => {
        return {
          ...val,
          generate: RoundNumberAfterChangeUnit(Number(val.generate) / 1000),
          reverse: RoundNumberAfterChangeUnit(Number(val.reverse) / 1000),
          consumption: RoundNumberAfterChangeUnit(
            Number(val.consumption) / 1000
          ),
          nomalElectric: RoundNumberAfterChangeUnit(
            Number(val.nomalElectric) / 1000
          ),
          compare: RoundNumberAfterChangeUnit(Number(val.compare) / 1000),
          consumption2: RoundNumberAfterChangeUnit(
            Number(val.consumption2) / 1000
          ),
          generate2: RoundNumberAfterChangeUnit(Number(val.generate2) / 1000),
          nomalElectric2: RoundNumberAfterChangeUnit(
            Number(val.nomalElectric2) / 1000
          ),
          reverse2: RoundNumberAfterChangeUnit(Number(val.reverse2) / 1000),
        }
      })
    }

    //is scroll chart
    const maxBar = maxBarChart()
    const isScrollChart = renderData?.length >= maxBar

    //set max Y
    const [max1, setMax1] = useState<number>(0)
    const [max2, setMax2] = useState<number>(0)
    const [maxY, setMaxY] = useState<number>(0)
    const [maxYCustom, setMaxYCustom] = useState<number>(0)

    useEffect(() => {
      max1 >= max2 ? setMaxY(max1) : setMaxY(max2)
    }, [max1, max2])

    const customTooltip = ({ active, payload, label }: any) => {
      if (active && payload) {
        const reversedPayload = [...payload].reverse()

        return (
          <div className="custom-tooltip">
            <p className="label">{label}</p>
            {reversedPayload.map((entry, index) => (
              <p key={`item-${index}`} style={{ color: entry.color }}>
                {`${entry.name}: ${formatNumber(entry.value)}`}
              </p>
            ))}
          </div>
        )
      }

      return null
    }

    return (
      <Container style={{ maxWidth: widthChart }}>
        <NameChart className="ichiranElectric">
          {yearSelect ? yearSelect : monthSelect}
          {firstName} {''}
          {isChangeUnit ? 'MWh' : 'kWh'} {''}
          {lastName}
        </NameChart>
        <div className="GraphWrapper">
          {isScrollChart && (
            <CustomYAxisElectricUp
              max={maxYCustom}
              min={0}
              unit={`${
                isChangeUnit ? '（買電）電力量（MWh）' : '（買電）電力量（kWh）'
              }`}
            />
          )}
          <ComposedChart
            width={isScrollChart ? renderData?.length * 120 : widthChart}
            data={renderData}
            height={235}
            barSize={8}
            margin={{ left: isScrollChart ? 5 : 60, bottom: 0, top: 5 }}
          >
            <XAxis
              dataKey={(value) => {
                if (value.startYear) {
                  return value.startYear
                } else return value.title
              }}
              tickSize={1}
              minTickGap={-40}
              tickLine={false}
              height={1}
              hide={false}
              tick={false}
            />
            <YAxis
              domain={([dataMin, dataMax]) => {
                setMax1(dataMax)
                const [min, max] = CalculatorYAxis(maxY)
                setMaxYCustom(max)
                return [min, max]
              }}
              label={{
                value: isChangeUnit
                  ? '（買電）電力量（MWh）'
                  : '（買電）電力量（kWh）',
                angle: -90,
                position: 'insideLeft',
                offset: -35,
                style: { textAnchor: 'middle' },
              }}
              hide={isScrollChart}
              // tick={<CustomYAxisTickUp />}
            />
            <CartesianGrid
              strokeDasharray="1 1"
              verticalCoordinatesGenerator={(props) => {
                const left: number = props?.offset?.left || 0
                const barSize: number = props?.xAxis?.bandSize
                const items = props?.xAxis?.domain

                let space = left
                let grid: any = []
                items?.forEach((element: any) => {
                  space = space + barSize
                  grid.push(space)
                })

                return grid
              }}
            />
            {/* <Tooltip
              formatter={(value, name, props) => {
                let data = formatNumber(+value)
                if (isChangeUnit) {
                  data = formatNumber(+value)
                }
                return [data, name]
              }}
            /> */}
            <Tooltip content={customTooltip} />

            <Legend
              width={widthChart - 20}
              wrapperStyle={{
                position: 'sticky',
                left: 0,
                transform: 'translateY(-230px)',
              }}
              verticalAlign="top"
              content={
                <RenderLegendIchiranChart
                  handleClickLegend={handleClickLegend}
                  isDataKeyActive={isDataKeyActive}
                  maxWidth={widthChart}
                />
              }
              height={50}
            />
            <Bar
              name="太陽光自家消費"
              dataKey="consumption"
              fill="#001d66"
              stackId="a"
              hide={!isDataKeyActive('consumption')}
            />
            <Bar
              name="買電（系統から）"
              dataKey="nomalElectric"
              fill="#FFC000"
              stackId="a"
              hide={isChangeBar || !isDataKeyActive('nomalElectric')}
            />
            <Bar
              name="余剰電力（系統へ）"
              dataKey="reverse"
              fill="#898989"
              stackId="a"
              hide={true}
            />
          </ComposedChart>
        </div>
        <div className="GraphWrapper">
          {isScrollChart && (
            <CustomYAxisElectricDown
              max={maxYCustom}
              min={0}
              unit={`${
                isChangeUnit ? '（余剰）電力量（MWh）' : '（余剰）電力量（kWh）'
              }`}
            />
          )}
          <ComposedChart
            width={
              renderData?.length >= maxBar
                ? renderData?.length * 120
                : widthChart
            }
            data={renderData}
            barSize={8}
            height={200}
            margin={{ left: isScrollChart ? 5 : 60, bottom: 5, top: 0 }}
          >
            <XAxis
              dataKey={(value) => {
                if (value.startYear) {
                  return value.startYear
                } else return value.title
              }}
              tickSize={4}
              minTickGap={-40}
              hide={false}
              orientation="bottom"
              tick={true}
              height={15}
              interval={0}
              tickFormatter={(value) =>
                value.length > 4 ? value.slice(0, 4) + '...' : value
              }
            ></XAxis>
            <YAxis
              domain={([dataMin, dataMax]) => {
                setMax2(dataMax)
                const [min, max] = CalculatorYAxis(maxY)
                return [min, max]
              }}
              label={{
                value: isChangeUnit
                  ? '（余剰）電力量（MWh）'
                  : '（余剰）電力量（kWh）',
                angle: -90,
                position: 'insideLeft',
                offset: -35,
                style: { textAnchor: 'middle' },
              }}
              reversed={true}
              hide={isScrollChart}
            />
            <CartesianGrid
              strokeDasharray="1 1"
              verticalCoordinatesGenerator={(props) => {
                const left: number = props?.offset?.left || 0
                const barSize: number = props?.xAxis?.bandSize
                const items = props?.xAxis?.domain

                let space = left
                let grid: any = []
                items?.forEach((element: any) => {
                  space = space + barSize
                  grid.push(space)
                })

                return grid
              }}
            />
            <Tooltip content={customTooltip} />
            <Bar
              name="余剰電力（系統へ）"
              dataKey="reverse"
              fill="#898989"
              stackId="a"
              hide={isChangeBar || !isDataKeyActive('reverse')}
            />
            <Bar
              name="買電（系統から）"
              dataKey="nomalElectric"
              fill="#FFC000"
              stackId="a"
              hide={!isChangeBar || !isDataKeyActive('nomalElectric')}
            />

            {/* {checkboxValue && (
              <Line
                name="比較"
                type="linear"
                data={dataDrawChart}
                dataKey={isChangeBar ? 'nomalElectric2' : 'reverse2'}
                stroke="#ab6fe2"
                hide={!isDataKeyActive('compare')}
                strokeWidth={2}
              />
            )} */}
            {/* {checkboxValue && (
              <Line
                name="比較"
                type="linear"
                dataKey={isChangeBar ? 'consumption2' : 'compare'}
                stroke="#ab6fe2"
                hide={!isDataKeyActive('compare')}
                strokeWidth={2}
              />
            )} */}
          </ComposedChart>
        </div>
      </Container>
    )
  }
)

export default withErrorBoundary(IchiranElectricChart, {
  FallbackComponent: ErrorComponent,
})

const Container = styled.div`
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;

  .GraphWrapper {
    max-height: 233px;
    display: flex;
    flex-direction: row;
    width: max-content;
  }

  .y-axis-wrapper {
    background: white;
    position: sticky;
    left: 0;
    z-index: 1003;
  }
  .recharts-wrapper {
    transform: translateX(-5px);
  }

  .recharts-legend-item-text {
    font-size: 12px !important;
  }
  .recharts-legend-wrapper {
    margin-left: -120px !important;
    padding-left: 120px !important;
  }

  .custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;

    .field {
      margin-top: 4px;
      display: flex;
    }
  }
`

export const NameChart = styled.p`
  color: #555;
  width: 100%;
  font-weight: bold;
  text-align: center;

  top: 4px;
  position: absolute;
  left: 0;
  padding-left: 60px;

  @media (max-width: 1024px) {
    top: 0px;
  }
`

import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

// get all notification for homepage
export const useFetchNotificationHomePage = () => {
  const fetchNotification = async () => {
    const response = await api
      .get(`${ENDPOINT}/Notification/AllForHomePage`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['getAllNotificationHomePage'],
    queryFn: () => fetchNotification(),
    staleTime: 10 * 1000,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}
// get all notification for admin
export const useFetchNotification = () => {
  const fetchNotification = async () => {
    const response = await api
      .get(`${ENDPOINT}/Notification/All`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['getAllNotification'],
    queryFn: () => fetchNotification(),
    staleTime: 10 * 1000,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

// get notification to show detail
export const useFetchNotificationDetail = (IDNotice: any) => {
  const fetchNotificationDetail = async () => {
    const response = await api
      .get(`${ENDPOINT}/Notification/Find?Id=${IDNotice}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['getNotificationDetail', IDNotice],
    queryFn: () => fetchNotificationDetail(),
    staleTime: 10 * 1000,
    enabled: !!IDNotice,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

// Delete notification
export const deleteNotification = async (IDNotice: string) => {
  await api.delete(`${ENDPOINT}/Notification/Delete`, { data: [IDNotice] })
}

export const addNewNotification = async ({ variables }: any) => {
  await api({
    method: 'post',
    url: `${ENDPOINT}/Notification`,
    data: {
      title: variables.title,
      content: variables.htmlContents,
      publishFrom: variables.From,
      publishTo: variables.To,
      link: variables.link,
    },
  })
}

// Edit notification
export const editNotification = async ({ variables }: any) => {
  await api({
    method: 'put',
    url: `${ENDPOINT}/Notification`,
    data: {
      id: variables.ID,
      title: variables.title,
      content: variables.description,
      publishFrom: variables.From,
      publishTo: variables.To,
      link: variables.link,
    },
  })
}

export const CustomYAxisTickDown = ({ x, y, payload, itemType }: any) => {
  return (
    <>
      {/* <line x1={65} y1={0} x2={60} y2={0} stroke='#666' strokeWidth={3}/> */}
      <text
        x={87}
        y={0}
        orientation={'left'}
        width={60}
        height={180}
        textAnchor="end"
        fill="rgba(102,102,102,0.4)"
      >
        <tspan x={87} dy={'0.355em'}>
          0
        </tspan>
      </text>
      <text
        x={87}
        y={y}
        orientation={'left'}
        width={60}
        height={180}
        textAnchor="end"
        fill="#666"
      >
        <tspan x={87} dy={'0.355em'}>
          {payload.value}
        </tspan>
      </text>
    </>
  )
}

export const CustomYAxisTickUp = ({ x, y, payload, itemType }: any) => {
  return (
    <>
      <line
        x1={95}
        y1={235}
        x2={89}
        y2={235}
        stroke="rgba(102,102,102,0.4)"
        strokeWidth={3}
      />
      <text
        x={87}
        y={235}
        orientation={'left'}
        width={60}
        height={180}
        textAnchor="end"
        fill="rgba(102,102,102,0.4)"
      >
        <tspan x={87} dy={'0.355em'}>
          0
        </tspan>
      </text>
      <text
        x={87}
        y={y}
        orientation={'left'}
        width={60}
        height={180}
        textAnchor="end"
        fill="#666"
      >
        <tspan x={87} dy={'0.355em'}>
          {payload.value}
        </tspan>
      </text>
    </>
  )
}

import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

//call Electrics data select by day
export const useFetchDayCo2 = (
  IDSelect: number | string,
  date: number | string | null,
  enable: boolean
) => {
  const fetchData = async () => {
    const response = await api
      .get(`${ENDPOINT}/Co2/Day?plantId=${IDSelect}&date=${date}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['useFetchDayCo2', IDSelect, date],
    queryFn: () => fetchData(),
    staleTime: 10 * 1000,
    enabled:
      !!IDSelect &&
      date !== null &&
      IDSelect !== '選択' &&
      IDSelect !== '"' &&
      !!enable,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//call Electrics data select by month
export const useFetchMonthCo2 = (
  IDSelect: number | string,
  month: number | string | null,
  enable: boolean
) => {
  const fetchData = async () => {
    const response = await api
      .get(`${ENDPOINT}/Co2/Month?plantId=${IDSelect}&date=${month}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['useFetchMonthCo2', IDSelect, month],
    queryFn: () => fetchData(),
    staleTime: 10 * 1000,
    enabled:
      !!IDSelect &&
      month !== null &&
      IDSelect !== '選択' &&
      IDSelect !== '"' &&
      !!enable,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//call Electrics data select by year
export const useFetchYearCo2 = (
  IDSelect: number | string,
  year: number | string | null,
  enable: boolean
) => {
  const fetchData = async () => {
    const response = await api
      .get(`${ENDPOINT}/Co2/Year?plantId=${IDSelect}&year=${year}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['useFetchYearCo2', IDSelect, year],
    queryFn: () => fetchData(),
    staleTime: 10 * 1000,
    enabled: !!(
      IDSelect !== '選択' &&
      IDSelect !== '"' &&
      IDSelect &&
      year &&
      enable
    ),
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//call Electrics data select by yearly
export const useFetchYearlyCo2 = (
  IDSelect: number | string,
  enable: boolean
) => {
  const fetchData = async () => {
    const response = await api
      .get(`${ENDPOINT}/Co2/Yearly?plantId=${IDSelect}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['useFetchYearlyCo2', IDSelect],
    queryFn: () => fetchData(),
    staleTime: 10 * 1000,
    enabled: !!IDSelect && IDSelect !== '選択' && IDSelect !== '"' && !!enable,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

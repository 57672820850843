import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

//add new purchase
export const addNewPurchase = async ({ variables }: any) => {
  await api({
    method: 'post',
    url: `${ENDPOINT}/PurchasePower/Insert?plantId=${variables.plantID}`,
    data: [
      {
        monthlyPeak: variables.field1,
        contractPower: variables.field2,
        time: variables.month,
      },
    ],
  })
}
//Edit purchase
export const editPurchase = async ({ variables }: any) => {
  await api({
    method: 'put',
    url: `${ENDPOINT}/PurchasePower?plantId=${variables.plantID}`,
    data: [
      {
        monthlyPeak: variables.field1,
        contractPower: variables.field2,
        time: variables.month,
      },
    ],
  })
}
//get purchase
export const useFetchPurchase = (
  plantID: string | number | null,
  year: string | number | null
) => {
  const fetchPurchase = async () => {
    const response = await api
      .get(`${ENDPOINT}/PurchasePower/Get?plantId=${plantID}&date=${year}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['getPurchase', plantID, year],
    queryFn: () => fetchPurchase(),
    staleTime: 10 * 1000,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!plantID && !!year,
  })
}

//delete Purchase
export const deletePurchase = async ({ variables }: any) => {
  await api.delete(
    `${ENDPOINT}/PurchasePower?plantId=${variables.childSelected}&date=${variables.dateSelect}`
  )
}

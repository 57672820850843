import { Button, ConfigProvider, Select, Spin } from 'antd'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  useFetchChildrenCompanies,
  useFetchCompanies,
} from '../../../Api/Companies'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import { customizeRenderEmpty } from '../../../Utils/CustomHook/CustomNoData'
import { TypeAccount } from '../../Context'
import ButtonDefault from '../Button/ButtonDefault'

interface AccountMngProps {
  option?: any
  onFetching: any
  page?: string
  hideOption2?: boolean
  hideFakeBtn?: boolean
}

const AccountMng = React.memo(
  ({ option, onFetching, page, hideOption2, hideFakeBtn }: AccountMngProps) => {
    const AccountID: string | number | any = sessionStorage.getItem('AccountID')
    const sessionFirst = JSON.parse(
      sessionStorage.getItem('firstSelected') as string
    )
    const sessionSecond = JSON.parse(
      sessionStorage.getItem('secondSelected') as string
    )
    const [firstOption, setFirstOption] = useState<any>(
      sessionStorage.getItem('firstSelected') ? sessionFirst?.[0] : '選択'
    )

    const { isAdmin } = useContext(TypeAccount)

    const {
      data: parentCompanies,
      isLoading: isLoadingAccount,
      isFetching: isParentFetching,
    } = useFetchCompanies(AccountID)

    const isDisableOption = useMemo(() => {
      return firstOption === '選択' || firstOption?.[0] === '選択'
    }, [firstOption])

    const {
      data: childCompanies,
      isLoading: childCompaniesLoading,
      isFetching: isChildFetching,
    } = useFetchChildrenCompanies(sessionFirst?.[1])

    const onFirstOptionChange = (value: any) => {
      setFirstOption(JSON.parse(value)?.[0])
      sessionStorage.setItem('firstSelected', value)
      sessionStorage.setItem('secondSelected', JSON.stringify(['選択', '選択']))
      if (!isAdmin) {
        option(value[1])
      }
    }

    const onSecondOptionChange = (value: any) => {
      const formatValue = JSON.parse(value)
      sessionStorage.setItem('secondSelected', value)
      option(formatValue[1])
    }

    useEffect(() => {
      if (!isParentFetching && parentCompanies?.results?.length === 1) {
        setFirstOption(parentCompanies.results?.[0]?.properties?.name)
        sessionStorage.setItem(
          'firstSelected',
          JSON.stringify([
            parentCompanies?.results?.[0]?.properties?.name,
            parentCompanies?.results?.[0]?.id,
          ])
        )
      }
    }, [parentCompanies, isParentFetching])

    useEffect(() => {
      if (!isChildFetching) {
        if (childCompanies?.length > 1) {
          option(sessionSecond?.[1] || '選択')
        } else if (childCompanies?.length === 1) {
          sessionStorage.setItem(
            'secondSelected',
            JSON.stringify([childCompanies?.[0]?.name, childCompanies?.[0]?.id])
          )
          option(childCompanies[0]?.id)
          // } else if (childCompanies?.length === 0) {
          //   option(sessionFirst?.[1])
        }
      }
    }, [childCompanies, isChildFetching])

    useEffect(() => {
      onFetching(childCompaniesLoading)
    }, [childCompaniesLoading])

    return (
      <div style={{ display: 'flex' }}>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <OptionsAccount>
            <OptionWrapper>
              <Button
                className="optionTile"
                type="text"
                style={{
                  cursor: 'default',
                  fontWeight: 600,
                  background: 'rgba(0, 0, 0, 0.06)',
                }}
              >
                {/* {isAdmin ? '顧客' : '拠点'}{' '} */}
                顧客
              </Button>
              <Select
                loading={isLoadingAccount}
                onClear={() => {
                  sessionStorage.setItem(
                    'firstSelected',
                    JSON.stringify(['選択', '選択'])
                  )
                  sessionStorage.setItem(
                    'secondSelected',
                    JSON.stringify(['選択', '選択'])
                  )
                  setFirstOption('選択')
                  option('')
                }}
                allowClear
                value={firstOption}
                onSelect={onFirstOptionChange}
                showSearch
                // filterOption={(input, option) => {
                //   return (
                //     option?.props?.children
                //       .toLowerCase()
                //       .indexOf(input.toLowerCase()) >= 0
                //   )
                // }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    {isLoadingAccount && (
                      <Spin
                        style={{
                          position: 'absolute',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          top: '50%',
                        }}
                      />
                    )}
                  </div>
                )}
              >
                {parentCompanies?.results?.map((item: any) => (
                  <Select.Option
                    key={item.id}
                    value={JSON.stringify([item?.properties?.name, item?.id])}
                    disabled={item.id === sessionFirst?.[1]}
                    className="set-background-disable-value"
                  >
                    {item?.properties?.name}
                  </Select.Option>
                ))}
              </Select>
            </OptionWrapper>

            {/* {isAdmin && !hideOption2 && ( */}
            {!hideOption2 && (
              <OptionWrapper>
                <Button
                  className="optionTile"
                  type="text"
                  style={{
                    cursor: 'default',
                    fontWeight: 600,
                    background: 'rgba(0, 0, 0, 0.06)',
                  }}
                >
                  拠点
                </Button>

                <Select
                  loading={isChildFetching}
                  disabled={isDisableOption}
                  onSelect={onSecondOptionChange}
                  value={
                    sessionStorage.getItem('secondSelected')
                      ? sessionSecond?.[0]
                      : '選択'
                  }
                  placeholder="選択"
                  showSearch
                  // filterOption={(input, option) =>
                  //   option?.props?.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  dropdownRender={(menu) => (
                    <div style={{ position: 'relative' }}>
                      {menu}
                      {isChildFetching && (
                        <Spin
                          style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            top: '50%',
                          }}
                        />
                      )}
                    </div>
                  )}
                >
                  {childCompanies &&
                    childCompanies.map((item: any) => (
                      <Select.Option
                        key={item.id}
                        value={JSON.stringify([item?.name, item?.id])}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </OptionWrapper>
            )}
          </OptionsAccount>
        </ConfigProvider>
        {page === 'Electric' ? (
          <DataBtn>
            <Link to={`/electric/download`}>
              <div style={{ width: '100px' }}>
                <ButtonDefault title="データ出力" />
              </div>
            </Link>
          </DataBtn>
        ) : hideFakeBtn ? (
          ''
        ) : (
          <DataBtn></DataBtn>
        )}
      </div>
    )
  }
)

export default withErrorBoundary(AccountMng, {
  FallbackComponent: ErrorComponent,
})

const OptionsAccount = styled.div`
  display: flex;
  flex: 3;
  gap: 10px;
  flex-direction: column;
  min-width: 300px;
`
const DataBtn = styled.div`
  flex: 5;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  @media (max-width: 1024px) {
    flex: 1;
  }
`
const OptionWrapper = styled.div`
  /* max-width: 70%; */
  display: flex;
  align-items: center;
  gap: 10px;
  .ant-select {
    flex: 1;
    .ant-select-selector {
      background: var(--theme-color);
      border-color: var(--theme-color);
      color: #fff;
      border-style: None;
      font-size: 14px;
      @media (max-width: 1080px) {
        font-size: 12px;
      }
    }
    .ant-select-suffix {
      color: #fff;
    }
    .ant-select-arrow-loading {
      color: #fff;
    }
  }
  .optionTile {
    min-width: 80px !important;
  }
`

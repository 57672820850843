import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ForgotPassword from '../../Components/LogIn/ForgotPassword'
import LogIn from '../../Components/LogIn/LogIn'

const imageLogo = require('../../Publics/Images/Model-T_logo2.jpg')

function Login(onLogin: any) {
  const Navigate = useNavigate()
  const [isLoginPage, setIsLoginPage] = useState(
    sessionStorage.getItem('isLoginPage') === 'false' ? false : true
  )
  useEffect(() => {
    sessionStorage.setItem('isLoginPage', isLoginPage.toString())
  }, [isLoginPage])

  const loggedIn = sessionStorage.getItem('AccountType')
  useEffect(() => {
    if (loggedIn) {
      Navigate('electric')
    }
  }, [])

  return (
    <Wrapper>
      <WrapperContent>
        {isLoginPage ? (
          <LogIn onClick={() => setIsLoginPage(false)} />
        ) : (
          <ForgotPassword onClick={() => setIsLoginPage(true)} />
        )}
        <Logo>
          <img src={imageLogo} alt="Logo" />
        </Logo>
      </WrapperContent>
    </Wrapper>
  )
}

export default Login

const Wrapper = styled.div`
  display: block;
  width: 100%;

  padding: 40px;
  @media (max-width: 500px) {
    padding: 20px;
  }
`
const WrapperContent = styled.div`
  display: flex;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  height: calc(100vh - 80px);

  @media (max-width: 768px) {
    display: flex;
    height: unset;
    flex-direction: column;
  }
`

const Logo = styled.div`
  flex: 1;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfeff;
  img {
    float: left;
    width: auto;
    height: 120px;
    background: #555;
    margin-bottom: 70px;
    @media (max-width: 1024px) {
      height: 90px;
    }
    @media (max-width: 768px) {
      height: 100px;
      margin-bottom: 0px;
    }
    // @media (max-width: 624px) {
    //   height: 60px;
    // }
  }
  @media (max-width: 768px) {
    order: 1;
  }
`

import { CloudDownloadOutlined } from '@ant-design/icons'
import styled from 'styled-components'

//customizeRenderEmpty
export const customizeRenderEmpty = () => (
  <NoDataWrapper>
    <CloudDownloadOutlined style={{ fontSize: 20 }} />
    <p>データがありません</p>
  </NoDataWrapper>
)

const NoDataWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`

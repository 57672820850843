import React, { createContext } from 'react'
type MyContextType = {
  isAdmin: boolean | undefined
  updateValue: any
}

export const ParentInfo = createContext<string | undefined>(undefined)

export const TypeAccount = createContext<MyContextType>({
  isAdmin: undefined,
  updateValue: () => {},
})

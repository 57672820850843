import {
  CloudDownloadOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  Button,
  DatePicker,
  Modal,
  Select,
  Spin,
  Upload,
  UploadProps,
  message,
  notification,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { useQueryClient } from 'react-query'
import readXlsxFile from 'read-excel-file'
import styled from 'styled-components'
import { DownloadTemplate, useGetDataCo2MNG } from '../../../Api/Co2Mng'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import Co2Table from './TableData'
import { api } from '../../../Api/Config/AxiosConfig'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL
const EXCELKEY =
  'b6e8c272174302d1a357fc1cc8dd34bfc6e0748333df6e4b8a0109cd76001dce'

const Co2Management: React.FC = () => {
  const [loadingUpData, setLoadingUpData] = useState<boolean>(false)
  const [dataShow, setDataShow] = useState<any[]>([])
  const [yearSelect, setYearSelect] = useState<string>(
    dayjs().startOf('year').format('YYYY')
  )
  const [fileData, setFileData] = useState<any>([])
  const [isHideFileUpload, setIsHideFileUpload] = useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const btnRef = useRef<any>(null)

  const generateArray = (start: number, end: number, typePowerId: number) => {
    return fileData
      .slice(start, end)
      .slice(3, -1)
      .map((data: any) => {
        const x = data.slice(2).map((y: any) => {
          if (typeof y === 'number') {
            return Number(y.toFixed(2))
          } else {
            return y
          }
        })
        return x
      })
  }

  const dataSend = Array.from({ length: 10 }, (_, index) => ({
    value: generateArray(index * 14, (index + 1) * 14, index + 1),
    typePowerId: index + 1,
  }))

  const queryClient = useQueryClient()
  const SendData = async () => {
    setLoadingUpData(true)
    try {
      await api({
        url: `${ENDPOINT}/Co2/CO2Data?year=${yearSelect}`,
        method: 'post',
        data: dataSend,
      })
      notification.success({
        message: 'アップロードに成功しました。',
        duration: 1,
        placement: 'topRight',
      })
      handleCloseModal()
      setLoadingUpData(false)
      queryClient.invalidateQueries({ queryKey: ['DataCo2Management'] })
    } catch (err) {
      alert(err)
      setLoadingUpData(false)
    }
  }

  //handle select year
  const onYearChange = (date: any, dateString: string | string[]) => {
    setYearSelect(dateString.toString())
  }

  //handle File Upload
  const handleFileUpload = (event: any) => {
    const file = event.file.originFileObj

    // Read the Excel file using readXlsxFile
    readXlsxFile(file).then((rows: any) => {
      const array = rows.map((array: any) => array.slice(0, 14))
      setFileData(array)
    })
  }

  const { data, isLoading } = useGetDataCo2MNG(yearSelect)

  useEffect(() => {
    const isFileDataValid = fileData.length > 0
    const excelKey = fileData[149]?.[1]

    if (isFileDataValid && excelKey !== EXCELKEY) {
      message.error(
        <>
          <span>アップロードしたファイルは正しくありません。</span>
          <p>
            「ダウンロード」ボタンを押して、正確なテンプレートをダウンロードしてください。
          </p>
        </>
      )
    }
    if (isFileDataValid && excelKey === EXCELKEY) {
      const dataItem = fileData?.slice(0, 140).map((item: any) => item.slice(1))

      showModal()
      setDataShow(dataItem)
    }
  }, [fileData])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const props: UploadProps = {
    name: 'file',
    action: `${ENDPOINT}/Co2/CO2TemplateUp?year=${yearSelect}`,
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 1,
    showUploadList: isHideFileUpload,
    onChange(info) {
      setIsHideFileUpload(false)
      if (info.file.status === 'done') {
        handleFileUpload(info)
      } else if (info.file.status === 'error') {
        if (info.file.response.includes('is not correct')) {
          notification.error({
            message:
              'アップロードした ファイルは正しくありません。ダウンロードボタンを押して、正しいテンプレートをダウンロードし てください。',
            duration: 2,
            placement: 'topRight',
          })
        } else {
          message.error(
            `${info.file.name} ファイルアップロードに失敗しました。`
          )
        }
      }
    },
  }

  const tableData = [
    { label: '系統電力単価（従量電灯）（¥/kWh)', data: data?.[0]?.slice(',') },
    { label: '系統電力単価（低圧電力）（¥/kWh)', data: data?.[1]?.slice(',') },
    { label: '系統電力単価(業務用電力) （¥/kWh)', data: data?.[2]?.slice(',') },
    {
      label: '系統電力単価（特別高圧電力）（¥/kWh)',
      data: data?.[3]?.slice(','),
    },
    { label: '燃料調整費（従量電灯）（¥/kWh)', data: data?.[4]?.slice(',') },
    { label: '燃料調整費（低圧電力）（¥/kWh）', data: data?.[5]?.slice(',') },
    { label: '燃料調整費（業務用電力）（¥/kWh）', data: data?.[6]?.slice(',') },
    {
      label: '燃料調整費（特別高圧電力）（¥/kWh）',
      data: data?.[7]?.slice(','),
    },
    { label: '再エネ賦課金（¥/kWh）', data: data?.[8]?.slice(',') },
    { label: '調整後排出係数（kg-CO2/kWh）', data: data?.[9]?.slice(',') },
  ]

  const [selectedLabel, setSelectedLabel] = useState('全て')

  const handleLabelChange = (value: any) => {
    setSelectedLabel(value)
    if (value === '全て') {
      scrollToLabel('系統電力単価（従量電灯）（¥/kWh)')
    } else {
      scrollToLabel(value)
    }
  }

  const scrollToLabel = (label: any) => {
    const element = document.getElementById(label)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (isModalOpen && !loadingUpData) {
      timeoutId = setTimeout(() => {
        btnRef?.current?.focus()
      }, 100)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isModalOpen, loadingUpData])

  //for highlight null value
  const noneHighlightRows = [
    0, 13, 14, 27, 28, 41, 42, 55, 56, 69, 70, 83, 84, 97, 98, 111, 112, 125,
    126, 139, 140,
  ]
  return (
    <div>
      <UploadWrapper>
        <Button
          className="DownLoadBtn"
          onClick={() => DownloadTemplate(yearSelect)}
          icon={<DownloadOutlined />}
        >
          ダウンロード
        </Button>
        <Upload {...props} accept=".xlsx">
          <Button className="DownLoadBtn" icon={<UploadOutlined />}>
            アップロード
          </Button>
        </Upload>
        <Select
          style={{ width: 300 }}
          placeholder="Select a label"
          onChange={handleLabelChange}
          value={selectedLabel}
          listHeight={600}
        >
          {tableData.map((item, index) => (
            <Select.Option key={index} value={item.label}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
        <DatePicker
          style={{ height: '32px' }}
          allowClear={false}
          locale={locale}
          onChange={onYearChange}
          picker="year"
          defaultValue={dayjs().startOf('year')}
        />
      </UploadWrapper>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
        {data?.length > 0 ? (
          tableData?.map((data, index) => (
            <Co2Table
              key={index}
              tableData={data}
              selectedLabel={selectedLabel}
            />
          ))
        ) : isLoading ? (
          <Spin style={{ position: 'absolute', left: '50%', top: '100px' }} />
        ) : (
          <NoDataWrapper>
            <CloudDownloadOutlined style={{ fontSize: 20 }} />
            <p>データがありません</p>
          </NoDataWrapper>
        )}
      </div>

      <Modal
        width={1000}
        open={isModalOpen}
        onCancel={handleCloseModal}
        maskClosable={!loadingUpData}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          top: '6vh',
        }}
        bodyStyle={{ maxHeight: '80vh', overflow: 'auto', marginTop: '20px' }}
        footer={
          <>
            <Button
              loading={loadingUpData}
              ref={btnRef}
              onClick={() => SendData()}
              type="primary"
            >
              保存する
            </Button>
            <Button onClick={handleCloseModal}>戻る</Button>
          </>
        }
      >
        <table
          style={{
            borderCollapse: 'collapse',
          }}
        >
          <tbody>
            {dataShow.length > 0 &&
              dataShow?.slice(1).map((row, rowIndex) => (
                <tr style={{ height: '26px' }} key={rowIndex}>
                  {row?.map((cell: any, cellIndex: any) => {
                    let x
                    if (typeof cell === 'number') {
                      x = (+cell).toFixed(2)
                    } else x = cell

                    return (
                      <td
                        style={{
                          border: '1px solid black',
                          padding: '4px',
                          minWidth: '60px',
                          textAlign:
                            typeof cell === 'number' ? 'end' : 'center',
                          background:
                            cell === null &&
                            cellIndex !== 0 &&
                            rowIndex !== 0 &&
                            !noneHighlightRows.includes(rowIndex)
                              ? 'yellow'
                              : '',
                        }}
                        key={cellIndex}
                      >
                        {x}
                      </td>
                    )
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </Modal>
    </div>
  )
}

export default withErrorBoundary(Co2Management, {
  FallbackComponent: ErrorComponent,
})

const UploadWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0px;
  padding: 20px 0px;
  background: #fff;
  gap: 20px;
  width: calc(100% - 190px);
  @media (max-width: 1440px) {
    width: calc(100% - 110px);
  }

  .DownLoadBtn {
    background: var(--theme-color) !important;
    color: #fff;
  }
`

const NoDataWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input, notification } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ButtonDefault from '../UI/Button/ButtonDefault'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL
interface formProps {
  onClick: () => void
}
const ForgotPassword: React.FC<formProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [messageError, setMessageError] = useState(false)

  const [form] = Form.useForm()
  const userInput = useRef<any>(null)

  //default focus user in put
  useEffect(() => {
    userInput.current.focus()
  }, [])

  const onForgotPass = (values: any) => {
    const { email } = values
    setIsLoading(true)
    const dataAccount = () => {
      axios
        .post(`${ENDPOINT}/User/RequestChangePassword?email=${email}`)
        .then((response) => {
          notification.success({
            message: (
              <>
                <p>メール送信に成功しました。</p>
                <p>メールを確認してください。</p>
              </>
            ),
            duration: 3,
            placement: 'topRight',
          })
          props.onClick()
        })
        .catch((error) => {
          console.log(error)
          form.setFieldValue('email', '')
          userInput.current.focus()
          if (error.response.data === '電子メールが存在しません') {
            setMessageError(true)
          }
          notification.error({
            message: `${error.response.data}`,
            duration: 4,
            placement: 'topRight',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    dataAccount()
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <FormLogin>
      <FormStyle
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onForgotPass}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <h4 className="Form__Heading">パスワードをお忘れの方</h4>
        <strong style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faEnvelope} className="Mail-icon" />
          ご登録メールアドレスを入力してください
        </strong>
        <Form.Item
          style={{ margin: '10px 0px' }}
          name="email"
          rules={[
            {
              required: true,
              message: '※メールアドレスが入力されていません。',
            },
            {
              type: 'email',
              message: '※メールアドレスが正しくありません。',
            },
          ]}
        >
          <Input ref={userInput} onChange={() => setMessageError(false)} />
        </Form.Item>
        {messageError && (
          <MessageError>
            ※入力されたメールアドレスは登録されていません。
          </MessageError>
        )}
        <Form.Item>
          <p className="FormItem--P">
            ※メールアドレス指定受信、ドメイン指定受信の設定をしている場合は、
          </p>
          <p className="FormItem--P">
            「info@model-t.co.jp」のアドレスからのメールが受信できるように設定してください。
          </p>
        </Form.Item>
        <Form.Item>
          <div className="btn-container">
            <Button
              style={{ marginRight: '4px' }}
              onClick={() => props.onClick()}
              className="Return-btn"
            >
              ログイン画面に戻る
            </Button>
            <ButtonDefault
              loading={isLoading}
              className="ForgotPass"
              type="primary"
              htmlType="submit"
              title="メールを送信する"
            />
          </div>
        </Form.Item>
      </FormStyle>
    </FormLogin>
  )
}

export default ForgotPassword

const FormLogin = styled.div`
  flex: 2;
  display: flex;

  background-color: #e9ebee;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .Return-btn {
    color: White;
    background-color: #666;
    border-color: #666;
    border-style: None;
    height: 30px;

    &:hover {
      background-color: #666666e6;
      color: White;
    }
  }

  .Form__Heading {
    border-bottom: 1px #ccc dotted;
    font-weight: 600;
    font-size: 18px;
    color: var(--text-color);
  }
  .FormItem--P {
    font-size: 12px;
  }
  .btn-container {
    display: flex;
  }
  @media (max-width: 768px) {
    order: 2;
  }
`

const FormStyle = styled(Form)`
  display: flex;
  flex-direction: column;

  min-width: 400px;
  @media (max-width: 1280px) {
    padding: 24px;
    min-width: unset;
  }
  @media (max-width: 768px) {
    min-width: unset;
  }
  .ant-input {
    height: 30px;
  }
  .ant-input-affix-wrapper {
  }
  .ant-row {
  }
  .ant-form-item {
    margin-bottom: 20px;
  }
  .ant-form-item-row {
  }
  .ant-form-item-required {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #45546f !important;
    height: unset !important;
    margin-left: 4px;
    &::after {
      content: '' !important;
    }
    &::before {
      content: '' !important;
    }
  }
  .ant-form-item-control-input {
  }
  .ant-form-item-explain-error {
  }
  .ant-form-item-control-input-content {
  }
  .anticon {
  }
  .Mail-icon {
    margin-right: 4px;

    font-size: 16px;
  }
`
const MessageError = styled.p`
  color: red;
  font-size: 12px;
  margin: 0px;
`

import { RouteObject } from 'react-router-dom'
import { PATH } from '../constants/common'
import NotFound from '../Pages/404page'
import Login from '../Pages/LoginPages/Login'
import HomePage from '../Pages/MainPages/HomePage'
import ResetPassword from '../Pages/LoginPages/ResetPassword'

export const routes_layout: RouteObject[] = [
  {
    path: PATH.NOT_FOUND,
    element: <NotFound />,
  },
  {
    path: PATH.HOME,
    element: <Login />,
  },
  {
    path: PATH.ELECTRIC,
    element: <HomePage page="electric" />,
  },
  {
    path: PATH.CO2,
    element: <HomePage page="co2" />,
  },
  {
    path: PATH.DOWN_ELECTRIC,
    element: <HomePage page="download" />,
  },
  {
    path: PATH.RESET_PASS,
    element: <ResetPassword />,
  },
  {
    path: PATH.INVOICE,
    element: <HomePage page="invoice" />,
  },
  {
    path: PATH.ICHIRAN,
    element: <HomePage page="ichiran" />,
  },
]

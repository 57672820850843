import { InboxOutlined } from '@ant-design/icons'
import {
  DatePicker,
  Form,
  Input,
  Upload,
  UploadProps,
  message,
  notification,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import ReactQuill from 'react-quill'
import styled from 'styled-components'
import ConfirmModal from './ConfirmModal'
import { editNotification } from '../../../Api/Notice'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL
interface dataForEdit {
  data: any
  onClose: () => void
  onLoading: (value: boolean) => void
}
const FormEditAlert: React.FC<dataForEdit> = ({ data, onClose, onLoading }) => {
  const [form] = Form.useForm()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [publicFrom, setPublicFrom] = useState<any>(dayjs(data.from))
  const [publicTo, setPublicTo] = useState<any>(
    dayjs(data.to).format('YYYYMMDD') === '99990101' ? '' : dayjs(data.to)
  )
  const [link, setLink] = useState(data?.link)
  const [listLink, setListLink] = useState([])
  const [fileList, setFileList] = useState<any>([])

  useEffect(() => {
    setPublicFrom(dayjs(data.from))
    setPublicTo(
      dayjs(data.to).format('YYYYMMDD') === '99990101' ? '' : dayjs(data.to)
    )
  }, [data])

  useEffect(() => {
    if (dayjs(publicFrom).diff(publicTo || '99990101', 'day') > 0) {
      setPublicTo(publicFrom)
    }
  }, [publicFrom])

  //call api
  const queryClient = useQueryClient()
  const { mutate, isLoading: editLoading } = useMutation(editNotification, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['getNotificationDetail'] })
      queryClient.invalidateQueries({ queryKey: ['getAllNotification'] })
      onClose()
      notification.success({
        message: 'お知らせを修正しました。',
        duration: 1,
        placement: 'topRight',
      })
      setShowConfirm(false)
    },

    onError: (error: any) => {
      console.log(error)
      notification.error({
        message: `${error.message}`,
        duration: 2,
        placement: 'topRight',
      })
      setShowConfirm(false)
    },
  })

  const onOKConfirm = () => {
    const title = form.getFieldValue('titleEdit')
    const description = form.getFieldValue('description')
    const From = dayjs(publicFrom).format('YYYYMMDD')
    const To = dayjs(publicTo).isValid()
      ? dayjs(publicTo).format('YYYYMMDD')
      : '99990101'
    const ID = data.id
    mutate({
      variables: { ID, title, description, From, To, link },
    })
  }

  const EditNotification = () => {
    setShowConfirm(true)
  }
  const onCancelConfirm = () => {
    setShowConfirm(false)
  }
  // Disable all dates after the selected end date
  const disabledStartDate = (current: any) => {
    return current && current < publicFrom.startOf('day')
  }
  useEffect(() => {
    form.setFieldsValue(data)
  }, [data])

  //define old data for form edit
  const allowedFileTypes = 'image/*,application/pdf'

  useEffect(() => {
    if (data.link) {
      setListLink(data.link?.split(','))
    } else {
      setListLink([])
    }
  }, [data])

  useEffect(() => {
    if (listLink.length > 0) {
      const newFileList = listLink.map((link, index) => ({
        uid: `${index + 1}`,
        name: link,
        status: 'done',
        url: `${ENDPOINT}/${link}`,
        response: `\\_\\${link}`,
      }))
      setFileList(newFileList)
    } else {
      setFileList([])
    }
  }, [listLink])

  function getAction(file: any) {
    if (file.type === 'application/pdf') {
      return `${ENDPOINT}/pdf`
    }
    return `${ENDPOINT}/png`
  }

  const { Dragger } = Upload

  const handleChange: UploadProps['onChange'] = (info: any) => {
    const { status } = info.file
    setFileList(info.fileList)
    if (['done', 'removed'].includes(status)) {
      const listLinkSendApi = info.fileList.map(
        (item: any) => item.response?.split('\\_\\')[1]
      )
      setLink(listLinkSendApi.toString())
    } else if (status === 'error') {
      message.error(`${info.file.name} ファイルアップロードに失敗しました。.`)
    }
    if (
      info.fileList.every((file: any) =>
        ['done', 'error', 'removed'].includes(file.status)
      )
    ) {
      onLoading(false)
    }
  }

  const uploadProps = {
    accept: allowedFileTypes,
    name: 'file',
    multiple: true,
    action: getAction,
    beforeUpload: (file: any) => {
      onLoading(true)
      return true
    },
  }

  return (
    <>
      <Form
        name="notificationEdit"
        form={form}
        labelAlign="left"
        layout="horizontal"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}
        autoComplete="off"
        onFinish={EditNotification}
      >
        <CustomFormItem
          label="タイトル"
          name="titleEdit"
          rules={[
            {
              required: true,
              message: 'タイトルを入力してください。',
            },
          ]}
        >
          <Input
            value={data.titleEdit}
            size={'large'}
            placeholder="タイトルを入力"
            showCount
            maxLength={100}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          />
        </CustomFormItem>

        <CustomFormItem
          className="public__from-to"
          label="掲載時間"
          // name="publicFrom"
        >
          <>
            <DatePicker
              allowClear={false}
              size={'large'}
              style={{ width: '100%' }}
              format={'YYYY/MM/DD'}
              locale={locale}
              onChange={(value) => setPublicFrom(value)}
              value={publicFrom}
            />
            <span style={{ fontSize: '28px', transform: 'translateY(-3px)' }}>
              ~
            </span>
            <DatePicker
              allowClear={true}
              size={'large'}
              style={{ width: '100%' }}
              format={'YYYY/MM/DD'}
              locale={locale}
              onChange={(value) => setPublicTo(value)}
              value={publicTo}
              disabledDate={disabledStartDate}
            />
          </>
        </CustomFormItem>

        <CustomFormItem
          rules={[
            {
              required: true,
              message: '本文を入力してください。',
            },
          ]}
          label="本文 "
          name="description"
        >
          <CustomReactQuill
            value={data.description}
            theme="snow"
            onChange={(editor) => {
              if (editor === '<p><br></p>') {
                form.setFieldValue('description', '')
              }
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ['bold', 'italic', 'underline'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ color: [] }],
                ],
              },
            }}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'list',
              'indent',
              'link',
              'color',
              'image',
            ]}
          />
        </CustomFormItem>
        <CustomFormItem
          label={
            <>
              <p>添付ファイル</p>
            </>
          }
          name="file"
        >
          <Dragger
            {...uploadProps}
            fileList={fileList}
            onChange={handleChange}
            showUploadList={fileList.length > 0}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">ファイル選択</p>
          </Dragger>
        </CustomFormItem>
      </Form>
      {showConfirm && (
        <ConfirmModal
          onOk={() => onOKConfirm()}
          onCancel={() => onCancelConfirm()}
          title="確認"
          message="お知らせを修正します。よろしいですか。"
          loading={editLoading}
        />
      )}
    </>
  )
}

export default withErrorBoundary(FormEditAlert, {
  FallbackComponent: ErrorComponent,
})

const CustomFormItem = styled(Form.Item)`
  &.not-require-item {
    label {
      position: relative;
      left: 11px;
    }
  }
  &.public__from-to {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .ant-upload .ant-upload-btn {
    padding: 4px;
  }
  .ant-upload-drag-icon {
    margin: 0px !important;
    & > .anticon {
      font-size: 36px !important;
    }
  }
  .ant-upload-text {
    font-size: 14px !important;
  }
  .ant-upload-list-item-action {
    opacity: 0.7 !important;
  }
`
const CustomReactQuill = styled(ReactQuill)`
  & > .ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    & > .ql-editor {
      height: 120px;
      overflow: auto;
      resize: vertical;
    }
  }
  & > .ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`

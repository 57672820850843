import { Layout, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import AlertMng from '../../Components/Admin/AlertMng'
import Co2Management from '../../Components/Admin/Co2Management'
import Sidebar from '../../Components/UI/SideBar'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import DownloadCSV from '../Tabs/DownloadCSV'
import PurchasePowerPage from '../Tabs/PurchasePowerPage'
import SurplusPower from '../Tabs/SurplusPower'
import Co2 from './Co2Page'
import Electric from './Electric'
import ChiranPage from './Ichiranpage'
import InvoicePage from './InvoicePage'

interface HomePageProps {
  page?: string
}
const HomePage: React.FC<HomePageProps> = (props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigate = useNavigate()
  const getJSONType: any = sessionStorage.getItem('AccountType') || null
  const accountType = JSON.parse(getJSONType)
  const isSuperAdmin = accountType === '本店'
  const isHoldingCompany = accountType === '支店'
  const [selectedSidebar, setSelectedSidebar] = useState(
    sessionStorage.getItem('MTselectedSidebar') || 'sub1'
  )

  //denied connect when no account
  useEffect(() => {
    if (getJSONType == null) {
      navigate('/')
    }
  }, [getJSONType])

  const { Content, Sider } = Layout
  const handleSelect = (selectedKeys: string) => {
    setSelectedSidebar(selectedKeys)
  }

  //responsive collapse sidebar
  const [collapsed, setCollapsed] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => setWidth(window.innerWidth), 100)
    }
    window.addEventListener('resize', handleResize)

    // cleanup event
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (width < 1440) {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }
  }, [width])

  // when wrong account
  if (!isSuperAdmin && !isHoldingCompany) {
    setTimeout(() => sessionStorage.clear(), 2000)
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '10vh' }}
      >
        This Account is not supported of type, please contact with admin for
        detail.
      </div>
    )
  }

  return (
    <LayoutWrapper>
      <Layout>
        <Layout>
          <Sider
            collapsible
            collapsed={collapsed}
            width={160}
            onCollapse={(value) => setCollapsed(value)}
          >
            <Sidebar onSelect={handleSelect} />
          </Sider>

          <Layout style={{ padding: '0px' }}>
            <Content
              style={{
                padding: '20px',
                margin: 0,
                minHeight: '80vh',
                background: colorBgContainer,
              }}
            >
              {(selectedSidebar === 'sub1' || selectedSidebar === '') && (
                <>
                  {props.page === 'electric' && <Electric />}
                  {props.page === 'co2' && <Co2 />}
                  {props.page === 'invoice' && <InvoicePage />}
                  {props.page === 'download' && <DownloadCSV />}
                  {props.page === 'ichiran' && <ChiranPage />}
                </>
              )}
              {selectedSidebar === 'sub2' && <AlertMng />}
              {selectedSidebar === 'sub3' && <Co2Management />}
              {selectedSidebar === 'sub4' && <SurplusPower />}
              {selectedSidebar === 'sub5' && <PurchasePowerPage />}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </LayoutWrapper>
  )
}

export default withErrorBoundary(HomePage, {
  FallbackComponent: ErrorComponent,
})

const LayoutWrapper = styled.div`
  .ant-menu {
    padding-top: 4vh;
  }
  .ant-layout {
    height: 100vh;
    overflow-y: auto;
    background: #fff;
  }

  #components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }

  .ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  .SearchWrapper {
    display: flex;
    justify-content: space-between;
  }
  aside {
    @media (max-width: 768px) {
      flex: unset !important;
      min-width: unset !important;
      width: 40px !important;
    }
  }
  .ant-layout-content {
    border-radius: 8px;
  }
`

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ButtonDefault from '../Button/ButtonDefault'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

const imageLogo = require('../../../Publics/Images/Model-T_logo2.jpg')

interface headerProps {
  defaultActiveBtn?: number
}

const Header: React.FC<headerProps> = ({ defaultActiveBtn }) => {
  const [activeBtn, setActiveBtn] = useState(defaultActiveBtn)

  //check type account
  const getJSONType: any = sessionStorage.getItem('AccountType') || null
  const accountType = JSON.parse(getJSONType)
  const isSuperAdmin = accountType === '本店'

  const handleBtnClick = (index: number) => {
    setActiveBtn(index)
  }

  return (
    <WrapperHeader>
      <HeaderLogo>
        <Link to="/electric">
          <img src={imageLogo} alt="logo" />
        </Link>
      </HeaderLogo>
      <Tab>
        <Link to={`/electric`} onClick={() => handleBtnClick(0)}>
          <ButtonDefault
            className={`tab-btn ${activeBtn === 0 ? 'active-tab-btn' : ''}`}
            title="電力"
          />
        </Link>
        <Link to={`/co2`} onClick={() => handleBtnClick(1)}>
          <ButtonDefault
            className={`tab-btn ${activeBtn === 1 ? 'active-tab-btn' : ''}`}
            title="CO2"
          />
        </Link>
        {isSuperAdmin && (
          <>
            <Link to="/invoice" onClick={() => handleBtnClick(2)}>
              <ButtonDefault
                className={`tab-btn ${activeBtn === 2 ? 'active-tab-btn' : ''}`}
                title="請求"
              />
            </Link>
            <Link to="/ichiran" onClick={() => handleBtnClick(3)}>
              <ButtonDefault
                className={`tab-btn ${activeBtn === 3 ? 'active-tab-btn' : ''}`}
                title="一覧"
              />
            </Link>
          </>
        )}
      </Tab>
    </WrapperHeader>
  )
}

export default withErrorBoundary(Header, {
  FallbackComponent: ErrorComponent,
})

const WrapperHeader = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`
const HeaderLogo = styled.div`
  flex: 2;
  min-width: 225px;
  @media (max-width: 1024px) {
    width: 70vw;
    margin-bottom: 10px;
  }

  img {
    width: auto;
    height: 70px;
    background: #555;
  }
`

const Tab = styled.div`
  flex: 5;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 20px;
  .active-tab-btn {
    background-color: var(--theme-color) !important;
  }
`

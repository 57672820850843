import { saveAs } from 'file-saver'
import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

//call Electrics data select by year
export const useFetchChildElectricYear = (
  IDSelect: number | string,
  year: number | string | null,
  enable: boolean
) => {
  const fetchElectricYear = async () => {
    const response = await api
      .get(`${ENDPOINT}/Demand/Year?companyId=${IDSelect}&year=${year}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['ChildElectricYear', IDSelect, year],
    queryFn: () => fetchElectricYear(),
    staleTime: 10 * 1000,
    enabled: !!(
      IDSelect !== '選択' &&
      IDSelect !== '"' &&
      IDSelect &&
      year &&
      enable
    ),
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//call Electrics data select by month
export const useFetchChildElectricMonth = (
  IDSelect: number | string,
  month: string | null,
  enable: boolean
) => {
  const fetchElectricMonth = async () => {
    const response = await api
      .get(`${ENDPOINT}/Demand/Month?companyId=${IDSelect}&date=${month}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['ChildElectricMonth', IDSelect, month],
    queryFn: () => fetchElectricMonth(),
    staleTime: 10 * 1000,
    enabled:
      !!IDSelect &&
      month !== null &&
      IDSelect !== '選択' &&
      IDSelect !== '"' &&
      !!enable,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//call Electrics data select by yearly
export const useFetchChildElectricYearly = (
  IDSelect: number | string,

  enable: boolean
  // LastDay: number | string | null
) => {
  const fetchElectric = async () => {
    const response = await api
      .get(`${ENDPOINT}/Demand/Yearly?companyId=${IDSelect}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['ChildElectricYearly', IDSelect],
    queryFn: () => fetchElectric(),
    staleTime: 10 * 1000,
    enabled: !!IDSelect && IDSelect !== '選択' && IDSelect !== '"' && !!enable,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//call Electrics data select by day
export const useFetchChildElectricDay = (
  IDSelect: number | string,
  day: number | string | null,
  enable: boolean
) => {
  const fetchElectricDay = async () => {
    const response = await api
      .get(`${ENDPOINT}/Demand/Day?companyId=${IDSelect}&date=${day}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['ChildElectricDay', IDSelect, day],
    queryFn: () => fetchElectricDay(),
    staleTime: 10 * 1000,
    enabled:
      !!IDSelect &&
      day !== null &&
      IDSelect !== '選択' &&
      IDSelect !== '"' &&
      !!enable,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//Down Report Power
export const DownReport = async ({ variables }: any) => {
  await api({
    method: 'post',
    url: `${ENDPOINT}/Csv/GenV2`,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      dateFrom: variables.from,
      dateTo: variables.to,
      demandType: 'day',
      memberId: variables.AccountTitle,
      locations: variables.item.map((item: any) => ({
        id: item.about_us,
        plantId: item.id,
        locationName: item.name,
      })),
    },
  }).then((res) => {
    const blob = new Blob([res.data], { type: 'application/zip' })
    saveAs(blob, `${variables.from} - ${variables.to}.zip`)
  })
}

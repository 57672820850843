import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import UpdatePassword from '../../Components/LogIn/UpdatePassword'

const imageLogo = require('../../Publics/Images/Model-T_logo2.jpg')

function ResetPassword() {
  const Navigate = useNavigate()
  const loggedIn = sessionStorage.getItem('AccountType')
  useEffect(() => {
    if (loggedIn) {
      Navigate('electric')
    }
  }, [])

  return (
    <Wrapper>
      <WrapperContent>
        <UpdatePassword />
        <Logo>
          <img src={imageLogo} alt="Logo" />
        </Logo>
      </WrapperContent>
    </Wrapper>
  )
}

export default ResetPassword

const Wrapper = styled.div`
  display: block;
  padding: 100px;
  @media (max-width: 1024px) {
    padding: 80px;
  }
  @media (max-width: 978px) {
    padding: 60px;
  }
  @media (max-width: 768px) {
    padding: 40px;
  }
  @media (max-width: 500px) {
    padding: 20px;
  }
`
const WrapperContent = styled.div`
  display: flex;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  height: 70vh;
`

const Logo = styled.div`
  flex: 1;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfeff;
  img {
    float: left;
    width: auto;
    height: 120px;
    background: #555;
    margin-bottom: 70px;
    @media (max-width: 1024px) {
      height: 100px;
    }
    @media (max-width: 768px) {
      height: 80px;
    }
    @media (max-width: 624px) {
      height: 60px;
    }
  }
`

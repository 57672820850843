import { useEffect, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'
import { CalculatorYAxis } from '../../../Utils/CustomChart/CalculatorYAxisChart'
import { CustomYAxisCo2 } from '../../../Utils/CustomChart/CustomYAxisChart'
import { RenderLegendCo2Chart } from '../../../Utils/CustomChart/RenderLegend'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import {
  Round,
  RoundNumberAfterChangeUnit,
  formatNumber,
} from '../../../Utils/Helper/FormatNumber'
import { checkChangeUnitCo2 } from '../../../Utils/Helper/HandleData'
import { handleDataCo2, maxBarChart } from '../HandleDataIchiran'
import { getLabelShow } from '../../../Utils/Helper/HandleLable'
interface chartProps {
  data?: any
  dataNestChild: any
  monthSelect?: string | undefined | null
  yearSelect?: string | undefined | null
  firstName?: string
  lastName?: string
  nameParent?: string | undefined
  modeView: string
}
const ChartCo2 = ({
  data,
  dataNestChild,
  monthSelect,
  yearSelect,
  firstName,
  lastName,
  nameParent,
  modeView,
}: chartProps) => {
  const [maxY, setMaxY] = useState<number>(0)
  //calculateWidthChart
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => setScreenWidth(window.innerWidth), 100)
    }
    window.addEventListener('resize', handleResize)

    // cleanup event
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const calculateWidthChart = (screenWidth: number) => {
    if (screenWidth < 1025) {
      return screenWidth * 0.85
    } else {
      return screenWidth * 0.54
    }
  }
  const [widthChart, setWidthChart] = useState<number>(
    calculateWidthChart(screenWidth)
  )
  useEffect(() => {
    setWidthChart(calculateWidthChart(screenWidth))
  }, [screenWidth])

  let renderData: any = []
  renderData = handleDataCo2(data, dataNestChild, true, nameParent)

  const isChangeUnit = checkChangeUnitCo2(renderData, true)
  if (isChangeUnit) {
    renderData = renderData?.map((val: any, idx: number) => {
      return {
        ...val,
        gridPowerCo2Emissions: RoundNumberAfterChangeUnit(
          Number(val.gridPowerCo2Emissions) / 1000
        ),
        modelTCo2Emissions: RoundNumberAfterChangeUnit(
          Number(val.modelTCo2Emissions) / 1000
        ),
      }
    })
  }

  const maxBar = maxBarChart()

  const [activeDataKeys, setActiveDataKeys] = useState([
    'gridPowerCo2Emissions',
    'modelTCo2Emissions',
    'photovoltaicRenewableEnergyRatio',
  ])

  const handleClickLegend = (e: any) => {
    const { dataKey } = e
    const index = activeDataKeys.indexOf(dataKey)

    if (index === -1) {
      setActiveDataKeys([...activeDataKeys, dataKey])
    } else {
      setActiveDataKeys(activeDataKeys.filter((key) => key !== dataKey))
    }
  }

  const isDataKeyActive = (dataKey: any) =>
    activeDataKeys.indexOf(dataKey) !== -1

  const CandyBar = (props: any) => {
    const { x: oX, y: oY, width: oWidth, height: oHeight, value, fill } = props

    let x = oX
    let y = oHeight < 0 ? oY + oHeight : oY
    let width = oWidth
    let height = Math.abs(oHeight)

    return (
      <rect
        fill={fill}
        mask="url(#mask-stripe)"
        x={x}
        y={y}
        width={width}
        height={height}
      />
    )
  }

  const customTooltip = ({ active, payload, label }: any) => {
    if (active && payload) {
      const reversedPayload = [...payload]

      // const labelShow = getLabelShow(label, modeView)

      return (
        <div className="custom-tooltip">
          <p className="label">{label}</p>
          {reversedPayload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${formatNumber(entry.value)}`}
            </p>
          ))}
        </div>
      )
    }

    return null
  }

  const isScrollChart = renderData?.length >= maxBar
  return (
    <GraphWrapper style={{ maxWidth: widthChart }}>
      <NameChart className="NameChartPrice">
        {yearSelect ? yearSelect : monthSelect}
        {firstName} {''}
        {isChangeUnit ? ' [t-CO2] ' : ' [kg-CO2] '} {''}
        {lastName}
      </NameChart>
      {isScrollChart && (
        <CustomYAxisCo2
          max={maxY}
          min={0}
          unit={`CO2排出量 ${isChangeUnit ? ' [t-CO2] ' : ' [kg-CO2] '} `}
        />
      )}
      <ComposedChart
        width={isScrollChart ? renderData?.length * 120 : widthChart}
        height={400}
        data={renderData}
        barSize={16}
        barGap={8}
        margin={{ left: isScrollChart ? 5 : 60, bottom: 5, top: 5 }}
      >
        <CartesianGrid
          strokeDasharray="1 1"
          verticalCoordinatesGenerator={(props) => {
            const left: number = props?.offset?.left || 0
            const barSize: number = props?.xAxis?.bandSize
            const items = props?.xAxis?.domain

            let space = left
            let grid: any = []
            items?.forEach((element: any) => {
              space = space + barSize
              grid.push(space)
            })

            return grid
          }}
        />
        <pattern
          id="pattern-stripe"
          width="4"
          height="2"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect
            width="2"
            height="4"
            transform="translate(0,0)"
            fill="white"
          ></rect>
        </pattern>
        <mask id="mask-stripe">
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#pattern-stripe)"
          />
        </mask>
        <XAxis
          dataKey="title"
          minTickGap={-40}
          interval={0}
          tickFormatter={(value) =>
            value.length > 4 ? value.slice(0, 4) + '...' : value
          }
        />
        <YAxis
          label={{
            value: `CO2排出量 ${isChangeUnit ? ' [t-CO2] ' : ' [kg-CO2] '} `,
            angle: -90,
            position: 'insideLeft',
            offset: widthChart > 704 ? -36 : -20,
            style: { textAnchor: 'middle' },
          }}
          domain={([dataMin, dataMax]) => {
            const [min, max] = CalculatorYAxis(dataMax)
            setMaxY(max)
            return [min, max]
          }}
          hide={isScrollChart}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{
            value: '[%] 再エネ比率 ',
            angle: 90,
            position: 'insideRight',
            offset: 10,
            style: { textAnchor: 'middle' },
          }}
          domain={([_, dataMax]) => {
            const max = dataMax > 100 ? dataMax : 100
            return [0, max]
          }}
        />

        <Tooltip content={customTooltip} />
        {/* <Tooltip
          formatter={(value, name, props) => {
            let data
            if (props.dataKey === 'photovoltaicRenewableEnergyRatio') {
              data = formatNumber(+(+value).toFixed(1))
            } else {
              data = formatNumber(Round(+value))
            }
            return [data, name]
          }}
        /> */}
        <Legend
          verticalAlign="bottom"
          width={widthChart - 20}
          wrapperStyle={{
            position: 'sticky',
            left: 0,
            bottom: 14,
          }}
          content={
            <RenderLegendCo2Chart
              handleClickLegend={handleClickLegend}
              isDataKeyActive={isDataKeyActive}
              maxWidth={widthChart}
            />
          }
        />
        <Bar
          name="電気使用量(その他)-CO2排出量"
          dataKey="gridPowerCo2Emissions"
          fill="#FA9633"
          stackId="a"
          hide={!isDataKeyActive('gridPowerCo2Emissions')}
        />
        <Bar
          name="電気使用量(太陽光 MODEL-T)-CO2削減量"
          dataKey="modelTCo2Emissions"
          shape={<CandyBar />}
          fill="#001d66"
          stackId="a"
          hide={!isDataKeyActive('modelTCo2Emissions')}
        />
        {renderData?.length > 0 && (
          <Line
            name="再エネ比率"
            strokeWidth={2}
            type="monotone"
            dataKey="photovoltaicRenewableEnergyRatio"
            stroke="#00AAAD"
            yAxisId="right"
            hide={!isDataKeyActive('photovoltaicRenewableEnergyRatio')}
          />
        )}
      </ComposedChart>
    </GraphWrapper>
  )
}

export default withErrorBoundary(ChartCo2, {
  FallbackComponent: ErrorComponent,
})

const GraphWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 1em;

  overflow-x: auto;
  overflow-y: hidden;
  .y-axis-wrapper {
    background: white;
    position: sticky;
    left: 0;
    z-index: 1003;
  }
  .recharts-wrapper {
    transform: translateX(-5px);
  }

  .recharts-legend-item-text {
    font-size: 12px !important;
  }
  .recharts-legend-wrapper {
    margin-left: -120px !important;
    padding-left: 120px !important;
  }

  .custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;

    .field {
      margin-top: 4px;
      display: flex;
    }
  }
`

export const NameChart = styled.p`
  color: #555;
  width: 100%;

  font-weight: bold;
  text-align: center;

  top: 4px;
  position: absolute;
  left: 0;

  @media (max-width: 1024px) {
    top: 0px;
  }
`

import { Button } from 'antd'
import styled from 'styled-components'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface ButtonTotalCo2 {
  className?: string
  type?: 'default' | 'primary' | 'dashed' | 'link' | 'text'
  htmlType?: 'button' | 'submit' | 'reset'
  title: string
  value?: number | string
  percent?: number | string
  unit: string
  strong?: string
  sup?: string
}

function ButtonTotalCo2(props: ButtonTotalCo2) {
  return (
    <WrapperButton
      className={props.className}
      type={props.type}
      htmlType={props.htmlType}
    >
      <p>
        {props.title} <strong>{props.strong}</strong>
        <sup>{props.sup}</sup>
      </p>
      <span className="valueBtn">
        {props.value ? props.value : props.percent}
      </span>
      <span>{props.unit}</span>
    </WrapperButton>
  )
}

export default withErrorBoundary(ButtonTotalCo2, {
  FallbackComponent: ErrorComponent,
})

const WrapperButton = styled(Button)`
  color: White;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  border-style: None;
  height: 90px;
  width: 240px !important;
  font-size: 16px;
  border-radius: 20px;
  strong {
    font-size: 20px;
  }
  sup {
    font-weight: 500;
  }
  &:hover {
    background-color: #1d2f51e6 !important;
  }
  .valueBtn {
    font-size: 28px;
    margin-right: 4px;
  }
  p {
    margin: 0px;
  }
`

import { Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface ConfirmProps {
  openConfirm: boolean
  onOk: () => void
  onCancel: () => void
  title: string
  message: string
  loading?: boolean
}
const ConfirmModal: React.FC<ConfirmProps> = ({
  openConfirm,
  onOk,
  onCancel,
  title,
  message,
  loading,
}) => {
  const [confirming, setConfirming] = useState(false)

  const handleOk = () => {
    if (!confirming) {
      setConfirming(true)
      onOk()
    }
  }

  const handleCancel = () => {
    if (!confirming) {
      onCancel()
    }
  }
  // auto focus confirm btn

  const btnRef = useRef<any>(null)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (openConfirm && !loading) {
      timeoutId = setTimeout(() => {
        btnRef?.current?.focus()
      }, 100)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [openConfirm, loading])

  useEffect(() => {
    if (!openConfirm) {
      // Reset confirming state when the modal is closed
      setConfirming(false)
    }
  }, [openConfirm])

  return (
    <ModalConfirm
      confirmLoading={loading}
      open={openConfirm}
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="はい"
      cancelText="キャンセル"
      centered
    >
      <p>{message}</p>
      <button
        ref={btnRef}
        onClick={handleOk}
        style={{ position: 'absolute', opacity: '0' }}
      />
    </ModalConfirm>
  )
}

export default ConfirmModal

const ModalConfirm = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
  }
`

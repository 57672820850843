import React from 'react'

import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import { disabledDate } from '../../Utils/Helper/DisableDay'
import { DatePicker } from 'antd'
import styled from 'styled-components'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'

interface pageProps {
  modeTime: 'year' | 'month' | 'day' | any
  onDateChange: any
  onMonthChange: any
  onYearChange: any
}
const TimePickerCo2: React.FC<pageProps> = ({
  modeTime,
  onDateChange,
  onMonthChange,
  onYearChange,
}) => {
  return (
    <>
      {modeTime === 'day' && (
        <DatePickerWrapper
          allowClear={false}
          locale={locale}
          onChange={onDateChange}
          picker="date"
          defaultValue={
            sessionStorage.getItem('Co2DaySelected')
              ? dayjs(sessionStorage.getItem('Co2DaySelected')).startOf('day')
              : dayjs().startOf('day')
          }
          defaultPickerValue={dayjs().startOf('day')}
          disabledDate={disabledDate}
        />
      )}
      {modeTime === 'month' && (
        <DatePickerWrapper
          allowClear={false}
          locale={locale}
          onChange={onMonthChange}
          picker="month"
          defaultValue={
            sessionStorage.getItem('Co2MonthSelected')
              ? dayjs(sessionStorage.getItem('Co2MonthSelected')).startOf(
                  'month'
                )
              : dayjs().startOf('month')
          }
          defaultPickerValue={dayjs().startOf('month')}
          disabledDate={disabledDate}
        />
      )}
      {modeTime === 'year' && (
        <DatePickerWrapper
          allowClear={false}
          locale={locale}
          onChange={onYearChange}
          picker="year"
          defaultValue={
            sessionStorage.getItem('Co2YearSelected')
              ? dayjs(sessionStorage.getItem('Co2YearSelected')).startOf('year')
              : dayjs().startOf('year')
          }
          defaultPickerValue={dayjs().startOf('year')}
          disabledDate={disabledDate}
        />
      )}
      {modeTime === 'yearly' && ''}
    </>
  )
}

export default withErrorBoundary(TimePickerCo2, {
  FallbackComponent: ErrorComponent,
})

const DatePickerWrapper = styled(DatePicker)`
  margin-top: 20px;
  background: var(--theme-color);
  input {
    color: white !important;
    font-size: 14px !important;
    @media (max-width: 1279px) {
      font-size: 12px !important;
    }
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
  }
  input::placeholder {
    color: #f5f5f5 !important;
  }
  .ant-picker-suffix {
    color: white;
  }
  &.ant-picker-disabled {
    background: #c7cbd3 !important;
  }
  .ant-picker-month-btn {
    margin-inline-start: 4px !important;
  }
`

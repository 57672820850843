import { Button, Form, Input, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ButtonDefault from '../UI/Button/ButtonDefault'
import { LockOutlined } from '@ant-design/icons'
import axios from 'axios'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL
const UpdatePassword: React.FC = (props) => {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const Navigate = useNavigate()
  const userInput = useRef<any>()

  //focus input
  useEffect(() => {
    userInput.current.focus()
  }, [])

  //convert hex to string
  const { hex } = useParams<{ hex: string }>()
  const hexToString = (hex: any): string => {
    let str = ''
    for (let i = 0; i < hex.length; i += 2) {
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
    }
    return str
  }
  const email = hexToString(hex)

  //call API
  const onUpdatePassword = (values: any) => {
    const { password } = values
    setLoading(true)
    if (values.password !== values.confirm) {
      form.setFields([
        {
          name: 'confirm',
          errors: [
            '入力された新パスワードと新パスワード(確認)が一致しません。',
          ],
        },
      ])
      form.setFieldValue('confirm', '')
      setLoading(false)
    } else {
      const dataAccount = () => {
        axios
          .post(
            `${ENDPOINT}/User/ChangePassword?email=${email}&newPassword=${password}`
          )
          .then((response) => {
            notification.success({
              message: `新しいパスワードが正常に変更されました。`,
              duration: 2,
              placement: 'topRight',
            })
            setTimeout(() => {
              Navigate('/')
            }, 2000)
          })
          .catch((error) => {
            console.log('UpdatePassWordError', error)
            notification.error({
              message: `${error?.response?.data}`,
              duration: 2,
              placement: 'topRight',
            })
          })
          .finally(() => {
            setLoading(false)
          })
      }
      dataAccount()
    }
  }
  const onFinishFailed = (errorInfo: object) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <FormLogin>
      <FormStyle
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={onUpdatePassword}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="password"
          label="新しいパスワード"
          rules={[
            {
              required: true,
              message: 'パスワードを入力してください。',
            },
            { min: 6, message: 'パスワードは6文字以上で入力してください。' },
          ]}
          hasFeedback
        >
          <Input.Password
            ref={userInput}
            placeholder="新しいパスワードを入力"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="新しいパスワード（確認用）"
          rules={[
            {
              required: true,
              message: 'パスワードを入力してください。',
            },
          ]}
        >
          <Input.Password
            placeholder="新しいパスワードを確認"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item>
          <div className="btn-container">
            <Button
              style={{ marginRight: '4px' }}
              onClick={() => Navigate('/')}
              className="Return-btn"
            >
              ログイン画面に戻る
            </Button>
            <ButtonDefault
              className="submit"
              type="primary"
              htmlType="submit"
              title="変更する"
              loading={loading}
            />
          </div>
        </Form.Item>
      </FormStyle>
    </FormLogin>
  )
}

export default UpdatePassword

const FormLogin = styled.div`
  flex: 2;
  display: flex;
  padding-top: 12px;
  background-color: #e9ebee;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .Forgot-pass {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #4477da;
    margin-top: 6px;
    text-decoration: underline;
    font-weight: 900;
    .arrow-icon {
      margin-right: 4px;
    }
  }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    button {
      margin-top: 0px;
      height: 30px;
    }
    .Return-btn {
      color: White;
      background-color: #666;
      border-color: #666;
      border-style: None;

      &:hover {
        background-color: #666666e6;
        color: White;
      }
    }
  }
  .ant-col {
    overflow: unset;
  }
`

const FormStyle = styled(Form)`
  justify-content: center;
  display: flex;
  margin-left: 4px;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
  @media (max-width: 1024px) {
    min-width: unset;
  }
  .ant-input {
    height: 50px;
  }
  .ant-input-affix-wrapper {
    padding: 0px 8px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
  }
  .ant-row {
    display: block;
  }
  .ant-form-item {
    margin-bottom: 20px;
  }
  .ant-form-item-row {
    justify-content: flex-end;
  }
  .ant-form-item-required {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #45546f !important;
    height: unset !important;
    margin-left: 4px;
    &::after {
      content: '' !important;
    }
    &::before {
      content: '' !important;
    }
  }
  .ant-form-item-control-input {
    width: 344px;
  }
  .ant-form-item-explain-error {
    width: 300px;
    margin-top: 4px;
    margin-left: 8px;
  }
  .ant-form-item-control-input-content {
    text-align: center;
  }
  .anticon {
    font-size: 20px;
    margin-right: 4px;
  }
`
const MessageError = styled.p`
  position: absolute;
  top: 6vh;
  color: red;
  font-size: 16px;
`

import { saveAs } from 'file-saver'
import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

interface ListIdSelectProps {
  [parentId: string]: string[]
}
export const useFetchIchiranElectricByYear = (
  year: string | null,
  listId: ListIdSelectProps,
  isPage: boolean,
  isMode: boolean
) => {
  const fetchData = async () => {
    const response = await api
      .post(`${ENDPOINT}/Ichiran/Electric/Year?date=${year}`, listId)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['IchiranElectricByYear', year, listId],
    queryFn: () => fetchData(),
    staleTime: 30 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled:
      !!listId &&
      Object.keys(listId).length > 0 &&
      !!year &&
      !!isPage &&
      !!isMode,
  })
}

export const useFetchIchiranElectricByMonth = (
  month: string | null,
  listId: ListIdSelectProps,
  isPage: boolean,
  isMode: boolean
) => {
  const fetchData = async () => {
    const response = await api
      .post(`${ENDPOINT}/Ichiran/Electric/Month?date=${month}`, listId)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['IchiranElectricByMonth', month, listId],
    queryFn: () => fetchData(),
    staleTime: 30 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled:
      !!listId &&
      Object.keys(listId).length > 0 &&
      !!month &&
      !!isPage &&
      !!isMode,
  })
}

export const useFetchIchiranCo2ByYear = (
  year: string | null,
  listId: ListIdSelectProps,
  isPage: boolean,
  isMode: boolean
) => {
  const fetchData = async () => {
    const response = await api
      .post(`${ENDPOINT}/Ichiran/Co2/Year?date=${year}`, listId)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['IchiranCo2ByYear', year, listId],
    queryFn: () => fetchData(),
    staleTime: 30 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled:
      !!listId &&
      Object.keys(listId).length > 0 &&
      !!year &&
      !!isPage &&
      !!isMode,
  })
}

export const useFetchIchiranCo2ByMonth = (
  month: string | null,
  listId: ListIdSelectProps,
  isPage: boolean,
  isMode: boolean
) => {
  const fetchData = async () => {
    const response = await api
      .post(`${ENDPOINT}/Ichiran/Co2/Month?date=${month}`, listId)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['IchiranCo2ByMonth', month, listId],
    queryFn: () => fetchData(),
    staleTime: 30 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled:
      !!listId &&
      Object.keys(listId).length > 0 &&
      !!month &&
      !!isPage &&
      !!isMode,
  })
}

//
export const useInfoParent = (
  IDChild: string,
  isParent: boolean | undefined
) => {
  const fetchData = async () => {
    const response = await api
      .post(`${ENDPOINT}/Company/GetParentCompany?listId=${IDChild}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['InfoParent', IDChild],
    queryFn: () => fetchData(),
    staleTime: 30 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!IDChild && !!isParent,
  })
}

export const useFetchIchiranInvoice = (
  dateFrom: string | null,
  dateTo: string | null,
  listIdChild: any[],
  isCall: boolean | undefined
) => {
  const fetchData = async () => {
    const response = await api
      .post(`${ENDPOINT}/InvoiceLog?from=${dateFrom}&to=${dateTo}`, listIdChild)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['IchiranInvoice', dateFrom, dateTo, listIdChild],
    queryFn: () => fetchData(),
    staleTime: 30 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled:
      !!listIdChild &&
      listIdChild?.length > 0 &&
      !!dateFrom &&
      !!dateTo &&
      !!isCall,
  })
}

//Down Report ichiran Inv

const DownloadIchiranInv = async (data: []) => {
  await api({
    method: 'post',
    url: `${ENDPOINT}/InvoiceLog/TemplateDown`,
    data: data,
    responseType: 'arraybuffer',
  })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, `モデルT＿マイページ用.xlsx`)
    })
    .catch((err) => {
      alert(err)
      console.log(err)
    })
}

import { ConfigProvider, Select, Spin } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  useFetchChildrenCompanies,
  useFetchCompanies,
} from '../../../Api/Companies'
import { customizeRenderEmpty } from '../../../Utils/CustomHook/CustomNoData'
import { TypeAccount } from '../../Context'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import { withErrorBoundary } from 'react-error-boundary'

interface Props {
  setId?: any
}

const SelectCompaniesInv: React.FC<Props> = ({ setId }) => {
  const AccountID: string | number | any = sessionStorage.getItem('AccountID')
  const {
    data: parentCompanies,
    isLoading: isLoadingParent,
    isFetching: isParentFetching,
  } = useFetchCompanies(AccountID)

  const sessionFirst = JSON.parse(
    sessionStorage.getItem('firstSelected') as string
  )

  const [firstOption, setFirstOption] = useState<any>(
    sessionStorage.getItem('firstSelected') ? sessionFirst[0] : '選択'
  )

  const sessionSecond = JSON.parse(
    sessionStorage.getItem('secondSelected') as string
  )

  const [valueSelect, setValueSelect] = useState(
    sessionStorage.getItem('secondSelected') ? sessionSecond?.[0] : '選択'
  )

  const { isAdmin } = useContext(TypeAccount)

  //disable second option
  const isDisableOption = useMemo(() => {
    return firstOption === '選択' || firstOption?.[0] === '選択'
  }, [firstOption])

  //call list child
  const {
    data: childCompanies,
    isLoading: childCompaniesLoading,
    isFetching: isChildFetching,
  } = useFetchChildrenCompanies(sessionFirst?.[1])

  //handle first choose
  const onFirstOptionChange = (value: any) => {
    setFirstOption(value)
    const setSession = parentCompanies?.results?.find(
      (item: any) => item.properties.name === value
    )
    sessionStorage.setItem(
      'firstSelected',
      JSON.stringify([setSession?.properties?.name, setSession?.id])
    )
    sessionStorage.setItem('secondSelected', JSON.stringify(['選択', '選択']))
    if (!isAdmin) {
      setId(setSession?.id)
    }
  }

  //handle first choose
  const onSecondOptionChange = (value: any) => {
    const setSession = childCompanies?.find((item: any) => item.name === value)

    sessionStorage.setItem(
      'secondSelected',
      JSON.stringify([setSession?.name, setSession?.id])
    )

    setId(setSession?.id)
    setValueSelect(setSession?.name)
  }

  // set default select if only 1 option
  useEffect(() => {
    if (!isParentFetching && parentCompanies?.results?.length === 1) {
      setFirstOption(parentCompanies?.results?.[0]?.properties?.name)
      sessionStorage.setItem(
        'firstSelected',
        JSON.stringify([
          parentCompanies?.results?.[0]?.properties?.name,
          parentCompanies?.results?.[0]?.id,
        ])
      )
    }
  }, [parentCompanies, isParentFetching])

  useEffect(() => {
    if (!isChildFetching) {
      if (childCompanies?.length > 1) {
        setId(sessionSecond?.[1] || '')
      } else if (childCompanies?.length === 1) {
        sessionStorage.setItem(
          'secondSelected',
          JSON.stringify([childCompanies?.[0]?.name, childCompanies?.[0]?.id])
        )
        setId(childCompanies?.[0]?.id)
      } else if (childCompanies?.length === 0) {
        setId(sessionFirst?.[1])
      }
    }
  }, [childCompanies, isChildFetching])

  return (
    <>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <OptionsAccount>
          <Select
            style={{ flex: 1 }}
            size="middle"
            loading={isLoadingParent}
            onClear={() => {
              sessionStorage.setItem(
                'firstSelected',
                JSON.stringify(['選択', '選択'])
              )
              sessionStorage.setItem(
                'secondSelected',
                JSON.stringify(['選択', '選択'])
              )
              setFirstOption('選択')
            }}
            allowClear
            value={firstOption}
            onSelect={onFirstOptionChange}
            showSearch
            // filterOption={(input, option) => {
            //   return (
            //     option?.props?.children
            //       ?.toLowerCase()
            //       ?.indexOf(input?.toLowerCase()) >= 0
            //   )
            // }}
            dropdownRender={(menu) => {
              return (
                <div>
                  {menu}
                  {isLoadingParent && (
                    <Spin
                      style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        top: '50%',
                      }}
                    />
                  )}
                </div>
              )
            }}
          >
            {parentCompanies?.results?.map((items: any) => {
              return (
                <Select.Option
                  key={items.id}
                  value={items.properties.name}
                  disabled={items.id === sessionFirst?.[1]}
                  className="set-background-disable-value"
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>{items.properties.name}</span>
                    <span>{items.properties.plant_number}</span>
                  </div>
                </Select.Option>
              )
            })}
          </Select>
          {isAdmin && (
            <Select
              style={{ flex: 1 }}
              size="middle"
              loading={childCompaniesLoading}
              disabled={isDisableOption}
              onSelect={onSecondOptionChange}
              value={
                sessionStorage.getItem('secondSelected')
                  ? sessionSecond?.[0]
                  : '選択'
              }
              placeholder="選択"
              showSearch
              // filterOption={(input, option) =>
              //   option?.props?.children
              //     ?.toLowerCase()
              //     ?.indexOf(input?.toLowerCase()) >= 0
              // }
              dropdownRender={(menu) => (
                <div style={{ position: 'relative' }}>
                  {menu}
                  {childCompaniesLoading && (
                    <Spin
                      style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        top: '50%',
                      }}
                    />
                  )}
                </div>
              )}
            >
              {childCompanies &&
                childCompanies.map((item: any) => (
                  <Select.Option key={item.id} value={item?.name}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{item.name}</span>
                      <span>{item.plant_number}</span>
                    </div>
                  </Select.Option>
                ))}
            </Select>
          )}
        </OptionsAccount>
      </ConfigProvider>
    </>
  )
}

export default withErrorBoundary(SelectCompaniesInv, {
  FallbackComponent: ErrorComponent,
})

const OptionsAccount = styled.div`
  margin: 10px 10px 10px 0px;
  display: flex;
  justify-items: center;
  gap: 20px;
  width: 100%;
`

import React from 'react'
import styled from 'styled-components'
import ButtonDefault from '../Button/ButtonDefault'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface pageType {
  onChange?: any
  page: string

  modeTime?: string
}
const TimeLine: React.FC<pageType> = React.memo(
  ({ page, onChange, modeTime }) => {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        {page === 'Surplus' ? (
          ''
        ) : (
          <HeaderLogo style={{ flex: 2 }}></HeaderLogo>
        )}
        <>
          {(page === 'electric' || page === 'co2') && (
            <Tab>
              <ButtonDefault
                handleClick={() => onChange('day')}
                className={`tab-btn ${
                  modeTime === 'day' ? 'active-tab-btn' : ''
                }`}
                title="日間"
              />
              <ButtonDefault
                handleClick={() => onChange('month')}
                className={`tab-btn ${
                  modeTime === 'month' ? 'active-tab-btn' : ''
                }`}
                title="月間"
              />
              <ButtonDefault
                handleClick={() => onChange('year')}
                className={`tab-btn ${
                  modeTime === 'year' ? 'active-tab-btn' : ''
                }`}
                title="年間"
              />
              <ButtonDefault
                handleClick={() => onChange('yearly')}
                className={`tab-btn ${
                  modeTime === 'yearly' ? 'active-tab-btn' : ''
                }`}
                title="年次"
              />
            </Tab>
          )}

          {page === 'Ichiran' && (
            <Tab>
              <ButtonDefault
                handleClick={() => onChange('month')}
                className={`tab-btn ${
                  modeTime === 'month' ? 'active-tab-btn' : ''
                }`}
                title="月間"
              />

              <ButtonDefault
                handleClick={() => onChange('year')}
                className={`tab-btn ${
                  modeTime === 'year' ? 'active-tab-btn' : ''
                }`}
                title="年間"
              />
            </Tab>
          )}
          {page === 'Surplus' && (
            <Tab className="SurplusTime">
              <ButtonDefault
                handleClick={() => onChange('month')}
                className={`tab-btn ${
                  modeTime === 'month' ? 'active-tab-btn' : ''
                }`}
                title="月間"
              />

              <ButtonDefault
                handleClick={() => onChange('year')}
                className={`tab-btn ${
                  modeTime === 'year' ? 'active-tab-btn' : ''
                }`}
                title="年間"
              />
            </Tab>
          )}
        </>
      </div>
    )
  }
)

export default withErrorBoundary(TimeLine, {
  FallbackComponent: ErrorComponent,
})

const Tab = styled.div`
  margin-bottom: 20px;
  flex: 5;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .active-tab-btn {
    background-color: var(--theme-color) !important;
  }
  &.SurplusTime {
    margin-bottom: 0px;
  }
`
const HeaderLogo = styled.div`
  flex: 2;
  min-width: 225px;
  @media (max-width: 1024px) {
    display: none;
  }
`

import { Divider, Spin } from 'antd'
import dayjs from 'dayjs'
import { FunctionComponent, useEffect, useState } from 'react'
import { MdRectangle } from 'react-icons/md'
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'
import { Round, formatNumber } from '../../Utils/Helper/FormatNumber'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'

type DataItem = {
  month: string
  line1: number
  line2: number
  date1: number
  date2: number
  icon: any
  energy: number
}

interface Props {
  isSuperAdmin: boolean | undefined
  dataChart: any
  lineChartLoading: boolean
}

const InvoiceChart = ({ isSuperAdmin, dataChart, lineChartLoading }: Props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [monthSelect, setMonthSelect] = useState(11)
  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => setScreenWidth(window.innerWidth), 100)
    }
    window.addEventListener('resize', handleResize)

    // cleanup event
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const calculateWidthChart = (screenWidth: number) => {
    return screenWidth * 0.6
  }

  const calculateXAxis = (screenWidth: number) => {
    return screenWidth * 0.6 * 0.04
  }

  const [widthChart, setWidthChart] = useState<number>(
    calculateWidthChart(screenWidth)
  )

  const [widthPadding, setWidthPadding] = useState<number>(
    calculateXAxis(screenWidth)
  )

  useEffect(() => {
    setWidthChart(calculateWidthChart(screenWidth))
    setWidthPadding(calculateXAxis(screenWidth))
  }, [screenWidth])

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload, index } = props

    if (index == monthSelect) {
      return (
        <g transform={`translate(${x},${y})`} fill="blue" cursor={'pointer'}>
          <rect
            x={-20}
            y={0}
            width={40}
            height={30}
            fill="rgb(191, 144, 30)"
            rx={8}
            ry={8}
          />
          <text
            x={0}
            y={0}
            dy={20}
            textAnchor="middle"
            fontWeight="bold"
            fill="white"
          >
            {payload.value}
          </text>
        </g>
      )
    }
    return (
      <g
        transform={`translate(${x},${y})`}
        onClick={() => setMonthSelect(index)}
        cursor={'pointer'}
      >
        <text x={0} y={0} dy={20} textAnchor="middle" fill="black">
          {payload.value}
        </text>
      </g>
    )
  }

  const data =
    dataChart &&
    dataChart.map((inv: any) => {
      return {
        ...inv,
        line1: inv.line1 || 0,
        line2: inv.line2 || 0,
      }
    })

  return (
    <>
      <div className="content">
        <div className="left">
          <p style={{ fontSize: '1rem', margin: '0' }}>
            {(dataChart[monthSelect]?.endDay &&
              dayjs(dataChart[monthSelect].endDay).format(
                `YYYY[年]${dataChart[monthSelect].month}[分]`
              )) ||
              ''}
          </p>
          <p>
            {(dataChart[monthSelect]?.endDay &&
              `（使用期間：${dayjs(dataChart[monthSelect]?.startDay).format(
                'MM[月]DD[日]'
              )}～
            ${dayjs(dataChart[monthSelect]?.endDay).format(
              'MM[月]DD[日]'
            )}）`) ||
              ''}
          </p>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              margin: '20px 0',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              <MdRectangle fill="#1d2f51" />
              当年
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              <MdRectangle fill="gray" />
              前年
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <p></p>
        <p style={{ fontSize: '1.5rem', textAlign: 'center', margin: '0' }}>
          {dataChart[monthSelect].line1
            ? `${formatNumber(Round(dataChart[monthSelect].line1))} 円`
            : ''}
        </p>
      </div>
      <Divider />
      <Spin spinning={lineChartLoading}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <LineChart
            width={widthChart}
            height={400}
            data={data}
            margin={{ right: 40 }}
          >
            <XAxis
              dataKey="month"
              padding={{ left: widthPadding, right: widthPadding }}
              tickSize={0}
              minTickGap={-10}
              tick={<CustomizedAxisTick />}
            />
            <YAxis
              orientation="right"
              width={40}
              domain={([dataMin, dataMax]) => {
                if (dataMin === 0 && dataMax < 10) {
                  return [0, 10]
                }
                if (dataMax == 0 || dataMax == -Infinity) {
                  return [dataMin, dataMax]
                }
                if (dataMax <= -1000000) {
                  const quotient = Math.ceil(dataMax / 1000000) - 1
                  return [dataMin, quotient * 1000000]
                }
                if (dataMax <= -100000) {
                  const quotient = Math.ceil(dataMax / 100000) - 1
                  return [dataMin, quotient * 100000]
                }
                if (dataMax <= -10000) {
                  const quotient = Math.ceil(dataMax / 10000) - 1
                  return [dataMin, quotient * 10000]
                }

                if (dataMax <= -1000) {
                  const quotient = Math.ceil(dataMax / 1000) - 1
                  return [dataMin, quotient * 1000]
                }
                if (dataMax <= -100) {
                  const quotient = Math.ceil(dataMax / 100) - 1
                  return [dataMin, quotient * 100]
                }
                if (dataMax < dataMin) {
                  const quotient = Math.ceil(dataMax / 10) - 1
                  return [dataMin, quotient * 10]
                }
                if (dataMax <= 100) {
                  const quotient = Math.floor(dataMax / 10) + 1
                  return [dataMin, quotient * 10]
                }
                if (dataMax <= 1000) {
                  const quotient = Math.floor(dataMax / 100) + 1
                  return [dataMin, quotient * 100]
                }
                if (dataMax <= 10000) {
                  const quotient = Math.floor(dataMax / 1000) + 1
                  return [dataMin, quotient * 1000]
                }
                if (dataMax <= 100000) {
                  const quotient = Math.floor(dataMax / 10000) + 1
                  return [dataMin, quotient * 10000]
                } else {
                  const quotient = Math.floor(dataMax / 100000) + 1
                  return [dataMin, quotient * 100000]
                }
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              formatter={(value, name, props) => {
                const data = formatNumber(Round(+value))
                return [data, name]
              }}
            />
            <ReferenceLine
              x={`${dataChart[monthSelect].month}`}
              stroke="rgb(191, 144, 30)"
            />
            <Line
              type="monotone"
              dataKey="line1"
              stroke="#1d2f51"
              name="当年"
              strokeWidth={3}
              activeDot={{ r: 6 }}
            />
            <Line
              type="monotoneX"
              dataKey="line2"
              stroke="gray"
              name="前年"
              strokeWidth={2}
              activeDot={{ r: 6 }}
            />
          </LineChart>
          <div
            style={{
              display: 'flex',
              width: '60vw',
            }}
          >
            <CustomTable>
              <tbody>
                <tr>
                  {dataChart.map((item: DataItem, index: number) => {
                    return (
                      <th
                        style={{ position: 'relative', maxWidth: '40px' }}
                        key={index}
                      >
                        <span>{formatNumber(Round(item.line1))}</span>
                        <CustomSpan
                          style={{
                            background: item?.icon?.props?.color
                              ? '#fff'
                              : 'none',
                          }}
                        >
                          {item.icon}
                        </CustomSpan>
                      </th>
                    )
                  })}
                </tr>

                <tr>
                  {dataChart.map((item: DataItem, index: number) => (
                    <td key={index + 50}>{formatNumber(Round(item.line2))}</td>
                  ))}
                </tr>
              </tbody>

              <tbody>
                {isSuperAdmin && (
                  <>
                    <CustomTrCurrent>
                      {dataChart.map((item: DataItem, index: number) => (
                        <th key={index + 100}>{item.date1}</th>
                      ))}
                    </CustomTrCurrent>
                    <CustomTrLast>
                      {dataChart.map((item: DataItem, index: number) => (
                        <td key={index + 150}>{item.date2}</td>
                      ))}
                    </CustomTrLast>
                  </>
                )}
              </tbody>
            </CustomTable>
            <WrapperAside>
              {isSuperAdmin ? (
                <>
                  <span style={{ height: '36px' }}>当年</span>
                  <span style={{ height: '36px' }}>前年</span>
                  <span style={{ height: '36px' }}>当年</span>
                  <span style={{ height: '36px' }}>前年</span>
                </>
              ) : (
                <>
                  <span style={{ height: '72px' }}>当年</span>
                  <span style={{ height: '72px' }}>前年</span>
                </>
              )}
            </WrapperAside>
            <WrapperAside>
              <span style={{ height: '72px' }}>請求額</span>
              <span style={{ height: '72px' }}>使用日数</span>
            </WrapperAside>
          </div>
        </div>
      </Spin>
    </>
  )
}

export default withErrorBoundary(InvoiceChart, {
  FallbackComponent: ErrorComponent,
})

const CustomTable = styled.table`
  width: calc(100% - 80px);

  font-size: 12px;
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  td {
    padding: 0 2px;
    text-align: center;
    width: 5%;
    height: 36px;
  }
  th {
    padding: 0 2px;
    text-align: center;
    width: 5%;
    height: 36px;
    background-color: var(--theme-color);
    color: #fff;
  }
  @media (max-width: 768px) {
    font-size: 8px;
  }
  @media (max-width: 1024px) {
    font-size: 10px;
  }
  @media (max-width: 1280px) {
    font-size: 10px;
  }
`
const CustomTrCurrent = styled.tr``
const CustomTrLast = styled.tr``
const WrapperAside = styled.div`
  min-width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    width: 12px;
    font-size: 10px !important;
    text-align: center;
    display: flex;
    align-items: center;
  }
`
const CustomSpan = styled.div`
  border-radius: 50%;

  width: 14px;
  height: 14px;
  font-size: 16px;
  position: absolute;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%);
  svg {
    position: absolute;
    transform: translateX(-50%);
    top: -1px;
  }
`

import { Modal } from 'antd'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface ConfirmProps {
  onOk: () => void
  onCancel: () => void
  title: string
  message: string
  loading?: boolean
}
const ConfirmModal: React.FC<ConfirmProps> = ({
  onOk,
  onCancel,
  title,
  message,
  loading,
}) => {
  const handleOk = () => {
    onOk()
  }
  const handleCancel = () => {
    onCancel()
  }

  //auto focus
  const okButtonRef = useRef<any>(null)
  useEffect(() => {
    okButtonRef?.current?.focus()
  }, [])

  return (
    <ModalConfirm
      confirmLoading={loading}
      open
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="はい"
      cancelText="キャンセル"
      centered
    >
      <p>{message}</p>
      <button
        onClick={handleOk}
        ref={okButtonRef}
        style={{ position: 'absolute', opacity: '0' }}
      />
    </ModalConfirm>
  )
}
export default withErrorBoundary(ConfirmModal, {
  FallbackComponent: ErrorComponent,
})

const ModalConfirm = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
  }
`

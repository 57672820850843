import { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { TypeAccount } from './Components/Context'
import NotFound from './Pages/404page'
import Login from './Pages/LoginPages/Login'
import ResetPassword from './Pages/LoginPages/ResetPassword'
import HomePage from './Pages/MainPages/HomePage'
import { GlobalStyle } from './Components/Styled/global.style'
import RouterConfig from './routes'

const queryClient = new QueryClient()
function App() {
  //checkType account
  const [value, setValue] = useState(
    JSON.parse(sessionStorage.getItem('AccountType') as string)
  )
  const isAdmin = value ? value === '本店' : undefined
  const updateValue = (newValue: any) => {
    setValue(newValue)
  }

  return (
    <TypeAccount.Provider value={{ isAdmin, updateValue }}>
      <QueryClientProvider client={queryClient}>
        <RouterConfig />
        <GlobalStyle />
      </QueryClientProvider>
    </TypeAccount.Provider>
  )
}

export default App

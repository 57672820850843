import { ConfigProvider } from 'antd'
import 'dayjs/locale/ja'
import React from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import { customizeRenderEmpty } from '../../Utils/CustomHook/CustomNoData'
import { useElementWidth } from '../../Utils/CustomHook/useGetScreenWidth'
import {
  checkChangeUnitElectric,
  formatColumTableElectric,
} from '../../Utils/Helper/HandleData'
import { TableData } from '../Styled/MainPage.style'

interface DayDetail {
  data: any
  loading: boolean
  fetchingOption: boolean
  modeTime: '時刻' | '日' | '月' | '年'
}

const TableDetail: React.FC<DayDetail> = React.memo(
  ({ data, loading, fetchingOption, modeTime }) => {
    let renderData: any[] = data?.electricResponses
      ? data.electricResponses.map((item: any, index: number) => {
          return { ...item, key: index }
        })
      : []

    const existingData = renderData.find((item) => item.title === '合計')
    if (!existingData && data) {
      renderData.unshift({
        key: '合計',
        title: '合計',
        generate: data?.totalGenerate || 0,
        reverse: data?.totalReverse || 0,
        consumption: data?.totalConsumption || 0,
        consumptionFee: data?.totalConsumptionFee || 0,
        consumptionWithDiscount: data?.totalConsumptionWithDiscount || 0,
        nomalElectric: data?.totalNomalElectric || 0,
      })
    }

    //check length value for set fontsize
    const obj = renderData[0]
    let isValueLengthGreaterThan8 = false
    for (let key in obj) {
      const value = obj[key]
      const valueLength = String(value).length
      if (valueLength >= 7) {
        isValueLengthGreaterThan8 = true
        break
      }
    }

    const isChangeUnit = checkChangeUnitElectric(renderData, false)
    const loadingTable = loading || fetchingOption
    const columns = formatColumTableElectric(isChangeUnit, modeTime)
    const screenWidth = useElementWidth('electricPage')

    return (
      <>
        <div
          style={{
            maxWidth: screenWidth ? screenWidth * 0.37 : 400,
            height: '100%',
            fontSize: '13px',
            opacity: loadingTable ? '0.5' : '1',
          }}
        >
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <TableData
              setFontSize={isValueLengthGreaterThan8}
              bordered
              columns={columns}
              dataSource={renderData}
              pagination={false}
              scroll={{ x: true, y: 400 }}
            />
          </ConfigProvider>
        </div>
      </>
    )
  }
)

export default withErrorBoundary(TableDetail, {
  FallbackComponent: ErrorComponent,
})

import { createGlobalStyle } from 'styled-components'

const px2vw = (size: number, width = 1440) => `${(size / width) * 100}vw`
export const GlobalStyle = createGlobalStyle`
:root {
  font-size: ${px2vw(24)};

  @media (max-width: 768px) {
    font-size: ${px2vw(16)};
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: ${px2vw(18)};
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    font-size: ${px2vw(18)};
  }

}

`

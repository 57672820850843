import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { withErrorBoundary } from 'react-error-boundary'
import { CalculatorYAxis } from '../../Utils/CustomChart/CalculatorYAxisChart'
import { RenderLegend } from '../../Utils/CustomChart/RenderLegend'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import {
  RoundNumberAfterChangeUnit,
  formatNumber,
} from '../../Utils/Helper/FormatNumber'
import {
  checkChangeUnitCo2,
  handleDataForElectricChart,
} from '../../Utils/Helper/HandleData'
import { ChartContainer, NameChart } from '../Styled/MainPage.style'
import { getLabelShow } from '../../Utils/Helper/HandleLable'

interface Prop {
  data: any
  loadingData: boolean
  fetchingOption: boolean
  firstNameChart: string
  lastNameChart: string
  modeView: string
}

function Co2Chart({
  data,
  loadingData,
  fetchingOption,
  firstNameChart,
  lastNameChart,
  modeView,
}: Prop) {
  const [activeDataKeys, setActiveDataKeys] = useState([
    'gridPowerCo2Emissions',
    'modelTCo2Emissions',
    'photovoltaicRenewableEnergyRatio',
  ])

  const handleClickLegend = (e: any) => {
    const { dataKey } = e
    const index = activeDataKeys.indexOf(dataKey)

    if (index === -1) {
      setActiveDataKeys([...activeDataKeys, dataKey])
    } else {
      setActiveDataKeys(activeDataKeys.filter((key) => key !== dataKey))
    }
  }

  const isDataKeyActive = (dataKey: any) =>
    activeDataKeys.indexOf(dataKey) !== -1

  //calculateWidthChart

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => setScreenWidth(window.innerWidth), 100)
    }
    window.addEventListener('resize', handleResize)

    // cleanup event
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const calculateWidthChart = (screenWidth: number) => {
    if (screenWidth < 1025) {
      return screenWidth * 0.85
    } else {
      return screenWidth * 0.52
    }
  }
  const [widthChart, setWidthChart] = useState<number>(
    calculateWidthChart(screenWidth)
  )
  useEffect(() => {
    setWidthChart(calculateWidthChart(screenWidth))
  }, [screenWidth])

  const CandyBar = (props: any) => {
    const { x: oX, y: oY, width: oWidth, height: oHeight, value, fill } = props

    let x = oX
    let y = oHeight < 0 ? oY + oHeight : oY
    let width = oWidth
    let height = Math.abs(oHeight)

    return (
      <rect
        fill={fill}
        mask="url(#mask-stripe)"
        x={x}
        y={y}
        width={width}
        height={height}
      />
    )
  }

  //handle data
  let dataDrawChart = data && handleDataForElectricChart(data.data)
  const isChangeUnit = checkChangeUnitCo2(dataDrawChart, true)
  if (isChangeUnit) {
    dataDrawChart = dataDrawChart?.map((val: any, idx: number) => {
      return {
        ...val,
        gridPowerCo2Emissions: RoundNumberAfterChangeUnit(
          Number(val.gridPowerCo2Emissions) / 1000
        ),
        modelTCo2Emissions: RoundNumberAfterChangeUnit(
          Number(val.modelTCo2Emissions) / 1000
        ),
      }
    })
  }

  const customTooltip = ({ active, payload, label }: any) => {
    if (active && payload) {
      const reversedPayload = [...payload]

      const labelShow = getLabelShow(label, modeView)

      return (
        <div className="custom-tooltip">
          <p className="label">{labelShow}</p>
          {reversedPayload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${formatNumber(entry.value)}`}
            </p>
          ))}
        </div>
      )
    }

    return null
  }

  const disableChart = fetchingOption || loadingData
  return (
    <ChartContainer
      style={{ position: 'relative', opacity: disableChart ? '0.5' : '1' }}
    >
      <NameChart className="NameChartCo2">
        {`${firstNameChart} ${
          isChangeUnit ? ' [t-CO2] ' : ' [kg-CO2] '
        } ${lastNameChart}`}
      </NameChart>
      {disableChart && (
        <Spin style={{ position: 'absolute', left: '50%', top: '6%' }} />
      )}
      <ComposedChart
        data={dataDrawChart}
        barSize={8}
        width={widthChart}
        height={400}
        margin={{
          top: 5,
          right: 10,
          bottom: 0,
          left: widthChart > 704 ? 60 : 40,
        }}
      >
        <pattern
          id="pattern-stripe"
          width="4"
          height="2"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect
            width="2"
            height="4"
            transform="translate(0,0)"
            fill="white"
          ></rect>
        </pattern>
        <mask id="mask-stripe">
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#pattern-stripe)"
          />
        </mask>
        <XAxis dataKey="title" minTickGap={-40} />
        <YAxis
          label={{
            value: `CO2排出量 ${isChangeUnit ? ' [t-CO2] ' : ' [kg-CO2] '} `,
            angle: -90,
            position: 'insideLeft',
            offset: widthChart > 704 ? -40 : -20,
            style: { textAnchor: 'middle' },
          }}
          domain={([dataMin, dataMax]) => {
            const [min, max] = CalculatorYAxis(dataMax)
            return [min, max]
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{
            value: '[%] 再エネ比率 ',
            angle: 90,
            position: 'insideRight',
            offset: 10,
            style: { textAnchor: 'middle' },
          }}
          domain={([_, dataMax]) => {
            const max = dataMax > 100 ? dataMax : 100
            return [0, max]
          }}
        />
        <CartesianGrid strokeDasharray="1 1" />
        <Tooltip content={customTooltip} />
        {/* <Tooltip
          formatter={(value, name, props) => {
            let data
            if (props.dataKey === 'photovoltaicRenewableEnergyRatio') {
              data = formatNumber((+value).toFixed(1))
            } else {
              data = formatNumber(+value)
            }
            return [data, name]
          }}
        /> */}
        <Legend
          verticalAlign="bottom"
          content={
            <RenderLegend
              handleClickLegend={handleClickLegend}
              isDataKeyActive={isDataKeyActive}
            />
          }
        />
        <Bar
          name="系統電力-CO2排出量"
          dataKey="gridPowerCo2Emissions"
          fill="#FA9633"
          stackId="a"
          hide={!isDataKeyActive('gridPowerCo2Emissions')}
        />
        <Bar
          name="MODEL-T-CO2削減量"
          dataKey="modelTCo2Emissions"
          shape={<CandyBar />}
          fill="#001d66"
          stackId="a"
          hide={!isDataKeyActive('modelTCo2Emissions')}
        />
        {dataDrawChart?.length > 0 && (
          <Line
            name="再エネ比率"
            strokeWidth={2}
            type="monotone"
            dataKey="photovoltaicRenewableEnergyRatio"
            stroke="#00AAAD"
            yAxisId="right"
            hide={!isDataKeyActive('photovoltaicRenewableEnergyRatio')}
          />
        )}
      </ComposedChart>
    </ChartContainer>
  )
}

export default withErrorBoundary(Co2Chart, {
  FallbackComponent: ErrorComponent,
})

import React, { useState, useEffect } from 'react'

export const useElementWidth = (className: string): number | null => {
  const [elementWidth, setElementWidth] = useState<number | null>(null)

  useEffect(() => {
    const handleResize = () => {
      const electricPageElement = document.getElementsByClassName(className)[0]
      if (electricPageElement instanceof HTMLElement) {
        setElementWidth(electricPageElement.offsetWidth)
      }
    }

    // Initial measurement
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [className])

  return elementWidth
}

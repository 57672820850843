import dayjs from 'dayjs'
import { useState } from 'react'
import styled from 'styled-components'
import ButtonTotal from '../../Components/Co2/ButtonTotal'
import Co2Chart from '../../Components/Co2/Co2Chart'
import Co2Detail from '../../Components/Co2/Co2Detail'
import TimePickerCo2 from '../../Components/Co2/TimePicker'
import Header from '../../Components/UI/Header/Header'
import TimeLine from '../../Components/UI/Header/TimeLine'
import AccountMng from '../../Components/UI/SelectCompanies'
import {
  useFetchDayCo2,
  useFetchMonthCo2,
  useFetchYearCo2,
  useFetchYearlyCo2,
} from '../../Api/Co2'
import { Container } from '../../Components/Styled/Pages.style'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'

function Co2() {
  const [childSelected, setChildSelected] = useState<string>('')
  const [fetchOption, SetFetchOption] = useState<boolean>(false)
  const [modeTime, setModeTime] = useState(
    sessionStorage.getItem('co2Mode') || 'day'
  )

  //set default year/month/week/day
  const [yearData, setYearData] = useState(
    sessionStorage.getItem('Co2YearSelected') || new Date().getFullYear()
  )

  const [monthSelected, setMonthSelected] = useState(
    sessionStorage.getItem('Co2MonthSelected') ||
      dayjs().startOf('month').format('YYYY-MM')
  )

  const [daySelected, setDaySelected] = useState(
    sessionStorage.getItem('Co2DaySelected') ||
      dayjs().startOf('day').format('YYYY-MM-DD')
  )

  const handleChangeMode = (mode: string) => {
    setModeTime(mode)
    sessionStorage.setItem('co2Mode', mode)
  }
  const onFetchOption = (value: boolean) => {
    SetFetchOption(value)
  }

  const setSelectForCallData = (value: any) => {
    setChildSelected(value)
  }

  //callback function to get time value

  const handleChangeSelectYear = (_: any, dateString: string) => {
    setYearData(dateString)
    sessionStorage.setItem('Co2YearSelected', dateString)
  }
  const handleChangeSelectMonth = (_: any, dateString: string) => {
    setMonthSelected(dateString)
    sessionStorage.setItem('Co2MonthSelected', dateString)
  }

  const handleChangeSelectDay = (_: any, dateString: string) => {
    setDaySelected(dateString)
    sessionStorage.setItem('Co2DaySelected', dateString)
  }

  //get data by day yearly
  const { data: ChildYearlyData, isLoading: isChildYearlyDataLoading } =
    useFetchYearlyCo2(childSelected, modeTime === 'yearly')

  //get data by year
  const {
    data: ChildYearData,
    isLoading: isChildYearDataLoading,
    isError: YearError,
    error: ChildErrorMessage,
  } = useFetchYearCo2(childSelected, yearData, modeTime === 'year')

  //get data by month
  const {
    data: ChildMonthData,
    isLoading: isChildMonthDataLoading,
    isError: MonthError,
    error,
  } = useFetchMonthCo2(childSelected, monthSelected, modeTime === 'month')

  //get data by day
  const {
    data: ChildDayData,
    isLoading: isChildDayDataLoading,
    isError: dayErr,
    error: dayMessErr,
  } = useFetchDayCo2(childSelected, daySelected, modeTime === 'day')
  return (
    <WrapperBody>
      <Header defaultActiveBtn={1} />
      <TimeLine
        page="co2"
        modeTime={modeTime}
        onChange={(mode: string) => handleChangeMode(mode)}
      />

      {modeTime === 'day' && (
        <ButtonTotal
          value1={ChildDayData?.annualCO2Emissions1}
          value2={ChildDayData?.annualCO2Emissions2}
          value3={ChildDayData?.renewableEnergyRatio}
          loadingData={isChildDayDataLoading}
          fetchingOption={fetchOption}
          mode="day"
        />
      )}
      {modeTime === 'month' && (
        <ButtonTotal
          value1={ChildMonthData?.annualCO2Emissions1}
          value2={ChildMonthData?.annualCO2Emissions2}
          value3={ChildMonthData?.renewableEnergyRatio}
          loadingData={isChildMonthDataLoading}
          fetchingOption={fetchOption}
          mode="month"
        />
      )}
      {modeTime === 'year' && (
        <ButtonTotal
          value1={ChildYearData?.annualCO2Emissions1}
          value2={ChildYearData?.annualCO2Emissions2}
          value3={ChildYearData?.renewableEnergyRatio}
          loadingData={isChildYearDataLoading}
          fetchingOption={fetchOption}
          mode="year"
        />
      )}
      {modeTime === 'yearly' && (
        <ButtonTotal
          value1={ChildYearlyData?.annualCO2Emissions1}
          value2={ChildYearlyData?.annualCO2Emissions2}
          value3={ChildYearlyData?.renewableEnergyRatio}
          loadingData={isChildYearlyDataLoading}
          fetchingOption={fetchOption}
          mode="yearly"
        />
      )}
      <AccountMng
        option={(value: any) => setSelectForCallData(value)}
        onFetching={onFetchOption}
      />
      <TimePickerCo2
        modeTime={modeTime}
        onDateChange={handleChangeSelectDay}
        onMonthChange={handleChangeSelectMonth}
        onYearChange={handleChangeSelectYear}
      />
      <Container className="co2Page">
        {modeTime === 'day' && (
          <>
            <div className="Power__Detail">
              <Co2Detail
                data={ChildDayData}
                loading={isChildDayDataLoading}
                fetchingOption={fetchOption}
                modeTime="時刻"
              />
            </div>
            <div className="Power__Chart">
              <Co2Chart
                firstNameChart="一時単位のCO2排出量"
                lastNameChart="を表示します。"
                data={ChildDayData}
                loadingData={isChildDayDataLoading}
                fetchingOption={fetchOption}
                modeView={modeTime}
              />
            </div>
          </>
        )}
        {modeTime === 'month' && (
          <>
            <div className="Power__Detail">
              <Co2Detail
                data={ChildMonthData}
                loading={isChildMonthDataLoading}
                fetchingOption={fetchOption}
                modeTime="日"
              />
            </div>
            <div className="Power__Chart">
              <Co2Chart
                firstNameChart="日毎のCO2排出量"
                lastNameChart="を表示します。"
                data={ChildMonthData}
                loadingData={isChildMonthDataLoading}
                fetchingOption={fetchOption}
                modeView={modeTime}
              />
            </div>
          </>
        )}
        {modeTime === 'year' && (
          <>
            <div className="Power__Detail">
              <Co2Detail
                data={ChildYearData}
                loading={isChildYearDataLoading}
                fetchingOption={fetchOption}
                modeTime="月"
              />
            </div>
            <div className="Power__Chart">
              <Co2Chart
                firstNameChart="月毎のCO2排出量"
                lastNameChart="を表示します。"
                data={ChildYearData}
                loadingData={isChildYearDataLoading}
                fetchingOption={fetchOption}
                modeView={modeTime}
              />
            </div>
          </>
        )}
        {modeTime === 'yearly' && (
          <>
            <div className="Power__Detail">
              <Co2Detail
                data={ChildYearlyData ? ChildYearlyData : null}
                loading={isChildYearlyDataLoading}
                fetchingOption={fetchOption}
                modeTime="年"
              />
            </div>
            <div className="Power__Chart">
              <Co2Chart
                firstNameChart="年毎のCO2排出量"
                lastNameChart="を表示します。"
                data={ChildYearlyData ? ChildYearlyData : null}
                loadingData={isChildYearlyDataLoading}
                fetchingOption={fetchOption}
                modeView={modeTime}
              />
            </div>
          </>
        )}
      </Container>
    </WrapperBody>
  )
}

export default withErrorBoundary(Co2, {
  FallbackComponent: ErrorComponent,
})

const WrapperBody = styled.div`
  display: block;
  margin: auto;
`

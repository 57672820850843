import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

export const useFetchDataChart = (
  plantID: string | number,
  currentDay: string | number
) => {
  const fetchDataChart = async () => {
    const response = await api
      .get(`${ENDPOINT}/Invoice/Log?plantId=${plantID}&date=${currentDay}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['DataChartInvoice', plantID, currentDay],
    queryFn: () => fetchDataChart(),
    staleTime: 10 * 1000,
    enabled: !!plantID && !!currentDay,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

export const useGetCheckDate = (about_us: string, date: string) => {
  const fetchDataChart = async () => {
    const response = await api
      .get(`${ENDPOINT}/Invoice/Date?locationId=${about_us}&date=${date}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['CheckMonth', about_us, date],
    queryFn: () => fetchDataChart(),
    staleTime: 10 * 1000,
    // enabled: !!IDSelect && day !== null && IDSelect !== '選択' && !!enable,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

export const useGetCurrentElectrics = (
  locationID: string | number,
  startDay: string
  // currentDay: number | string
) => {
  const fetchDataChart = async () => {
    const response = await api
      .get(
        `${ENDPOINT}/Demand/UsageByDate?companyId=${locationID}&startDay=${startDay}`
      )
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['CurrentElectricUsed', locationID, startDay],
    queryFn: () => fetchDataChart(),
    staleTime: 10 * 1000,
    enabled: !!locationID && !!startDay && locationID !== '選択',
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//get invoice history

export const useGetInvoiceHistory = (locationID: string | number) => {
  const fetchDataChart = async () => {
    const response = await api
      .get(`${ENDPOINT}/InvoiceLog/All?PlantId=${locationID}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['GetInvoiceHistory', locationID],
    queryFn: () => fetchDataChart(),
    staleTime: 10 * 1000,
    enabled: !!locationID && locationID !== '選択',
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

//set status invoice

export const setStatusInvoice = async ({ variables }: any) => {
  await api({
    method: 'put',
    url: `${ENDPOINT}/InvoiceLog`,
    data: {
      id: variables.idInvoice,
      isPaid: !variables.isPaid,
    },
  })
}

export const useGetCheckDateByCompanyId = (companyId: string, date: string) => {
  const fetchDataChart = async () => {
    const response = await api
      .get(`${ENDPOINT}/Invoice/CheckDate?companyId=${companyId}&date=${date}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['CheckDate', companyId, date],
    queryFn: () => fetchDataChart(),
    staleTime: 10 * 1000,
    enabled: !!companyId && companyId !== '選択' && !!date,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

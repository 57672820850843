export const handleDataCo2 = (
  data: any,
  dataNestChild: any,
  isChart: boolean,
  nameParent?: string
) => {
  const renderData: any = []
  data &&
    Object.keys(data).forEach((key) => {
      // Access each data object using the key
      const val = data?.[key]
      const title = dataNestChild?.find((item: any) => item.value === key)
      // Create a new object to hold the converted data
      const convertedItem = {
        key: title?.title,
        title: nameParent ? nameParent : title?.title,
        gridPower: val.totalGridPower,
        gridElectricityConsumption: val.totalGridElectricityConsumption,
        modelTElectricityUsage: val.totalModelTElectricityUsage,
        gridPowerCo2Emissions: val.totalGridPowerCo2Emissions,
        modelTCo2Emissions: val.totalModelTCo2Emissions,

        photovoltaicRenewableEnergyRatio: isChart
          ? val.totalPhotovoltaicRenewableEnergyRatio || 0
          : val.totalPhotovoltaicRenewableEnergyRatio,
      }

      // Push the converted item to the array
      renderData.push(convertedItem)
    })

  // Calculate the sum of each field
  if (!isChart) {
    const sum = renderData?.reduce((acc: any, obj: any) => {
      for (const key in obj) {
        if (key !== 'title') {
          acc[key] = (acc[key] || 0) + (obj[key] || 0)
        }
      }
      return acc
    }, {})

    // Prepend the sum object to the array
    renderData?.length > 0 &&
      renderData.unshift({
        title: '合計',
        ...sum,
        photovoltaicRenewableEnergyRatio:
          (sum?.modelTElectricityUsage /
            (sum?.modelTElectricityUsage + sum?.gridElectricityConsumption)) *
          100,
      })
  }
  return renderData
}

export const handleDataElectric = (
  data: any,
  dataNestChild: any,
  isChart: boolean,
  nameParent?: string | undefined
) => {
  const renderData: any = []

  data &&
    Object.keys(data).forEach((key) => {
      // Access each data object using the key
      const val = data?.[key]
      const title = dataNestChild?.find((item: any) => item.value === key)
      // Create a new object to hold the converted data
      const convertedItem = {
        key: title?.title,
        title: nameParent ? nameParent : title?.title,
        generate: val.totalGenerate,
        reverse: val.totalReverse,
        consumption: val.totalConsumption,
        consumptionFee: val.totalConsumptionFee,
        consumptionWithDiscount: val.totalConsumptionWithDiscount,
        nomalElectric: val.totalNomalElectric,
        powerCompanyFee: val.totalPowerCompanyFee,
      }

      // Push the converted item to the array
      renderData.push(convertedItem)
    })

  if (!isChart) {
    // Calculate the sum of each field
    const sum = renderData.reduce((acc: any, obj: any) => {
      for (const key in obj) {
        if (key !== 'title') {
          acc[key] = (acc[key] || 0) + (obj[key] || 0)
        }
      }
      return acc
    }, {})

    // Prepend the sum object to the array
    renderData?.length > 0 &&
      renderData.unshift({
        title: '合計',
        ...sum,
      })
  }

  return renderData
}

export const maxBarChart = () => {
  const screenWidth = window.innerWidth
  let maxBar: number
  switch (true) {
    case screenWidth < 1025:
      maxBar = 5
      break
    case screenWidth < 1280:
      maxBar = 5
      break
    case screenWidth < 1368:
      maxBar = 6
      break
    case screenWidth < 1440:
      maxBar = 7
      break
    case screenWidth < 1680:
      maxBar = 8
      break
    default:
      maxBar = 9
      break
  }
  return maxBar
}

export const getLabelShow = (label: string | number, modeView: string) => {
  let labelShow = label + ' ' + '年'

  switch (modeView) {
    case 'day':
      labelShow = label + ' ' + '時'
      break
    case 'month':
      labelShow = label + ' ' + '日'
      break
    case 'year':
      labelShow = label + ' ' + '月'
      break
  }

  return labelShow
}

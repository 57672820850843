import { DatePicker, Form, Input, notification } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { disabledDate } from '../../Utils/Helper/DisableDay'
import ConfirmModal from './ConfirmModal'
import { addNewPurchase } from '../../Api/Purchase'

interface FormAlertProps {
  onClose: () => void
}

const FormNewPurchase: React.FC<FormAlertProps> = ({ onClose }) => {
  const initialValues = { time: dayjs().startOf('month') }

  const [month, setMonth] = useState(
    dayjs(initialValues.time).format('YYYY-MM-01')
  )
  const [isConfirm, setIsConfirm] = useState<boolean>(false)

  const [form] = Form.useForm()
  const storage = JSON.parse(sessionStorage.getItem('secondSelected') as string)

  const handleChangeDate = (date: any) => {
    if (date) {
      const newMonth = dayjs(date).format('YYYY-MM-01')
      setMonth(newMonth)
    }
  }

  const queryClient = useQueryClient()
  const { mutate, isLoading: addNewLoading } = useMutation(addNewPurchase, {
    onSuccess: (data, variables, context) => {
      setIsConfirm(false)
      queryClient.invalidateQueries({ queryKey: ['getPurchase'] })
      onClose()
      notification.success({
        message: '新作成の登録に成功しました。',
        duration: 1,
        placement: 'topRight',
      })
      form.resetFields()
      setMonth(dayjs(initialValues.time).format('YYYY-MM-01'))
    },
    onError: (error: any) => {
      setIsConfirm(false)
      onClose()
      console.log(error)
      if (error?.response?.data?.includes('Duplicate')) {
        notification.error({
          message: (
            <>
              <p>
                同月のデータが存在しているため、入力した情報を作成できません。
              </p>
              <p>別の月にデータを登録してください。</p>
            </>
          ),
          duration: 3,
          placement: 'topRight',
          style: { width: 'unset' },
        })
      } else {
        notification.error({
          message: `${error.message}`,
          duration: 2,
          placement: 'topRight',
        })
      }
    },
  })

  console.log('storage', storage)

  const onOKConfirm = () => {
    const field1 = form.getFieldValue('field1')
    const field2 = form.getFieldValue('field2')
    const plantID = storage?.[1]
    mutate({
      variables: { field1, field2, month, plantID },
    })
  }

  //auto focus
  const inputRef = useRef<any>()
  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef])

  return (
    <>
      <FormNewPurchaseWrapper
        initialValues={initialValues}
        name="Purchase"
        form={form}
        layout="horizontal"
        autoComplete="off"
        onFinish={() => setIsConfirm(true)}
      >
        <CustomFormItem name="time" label="年月" required>
          <DatePicker
            picker="month"
            allowClear={false}
            size={'large'}
            style={{ width: '100%' }}
            format={'YYYY-MM'}
            value={dayjs(month)}
            locale={locale}
            onChange={handleChangeDate}
            disabledDate={disabledDate}
          />
        </CustomFormItem>
        <CustomFormItem
          name="field1"
          label="月毎ピークデマンド（kW）"
          rules={[
            {
              required: true,

              message: '月毎ピークデマンドを入力してください。',
            },
          ]}
        >
          <Input
            ref={inputRef}
            type="number"
            allowClear
            size={'large'}
            placeholder="手入力"
            prefix={false}
          />
        </CustomFormItem>
        <CustomFormItem
          name="field2"
          label="使用電力量（kWh）"
          rules={[
            {
              required: true,
              message: '使用電力量を入力してください。',
            },
          ]}
        >
          <Input type="number" allowClear size={'large'} placeholder="手入力" />
        </CustomFormItem>
      </FormNewPurchaseWrapper>
      <ConfirmModal
        openConfirm={isConfirm}
        onOk={() => onOKConfirm()}
        onCancel={() => setIsConfirm(false)}
        title="確認"
        message="新作成のデータを登録します。よろしいですか。"
        loading={addNewLoading}
      />
    </>
  )
}
export default FormNewPurchase

const FormNewPurchaseWrapper = styled(Form)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const CustomFormItem = styled(Form.Item)`
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-label {
    flex: 2;
    text-align: left;
  }
  .ant-form-item-control {
    flex: 3;
  }
`

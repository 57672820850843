import { Modal, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { deleteNotification } from '../../../Api/Notice'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface ConfirmDeleteAlertProps {
  OnClose: () => void
  IDdelete: string
}
const ConfirmDeleteAlert: React.FC<ConfirmDeleteAlertProps> = ({
  OnClose,
  IDdelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true)

  const queryClient = useQueryClient()

  const { mutate, isLoading: deleteLoading } = useMutation({
    mutationFn: (IDdelete: string) => deleteNotification(IDdelete),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllNotification'] })
      OnClose()
      setIsModalOpen(false)
      notification.success({
        message: 'お知らせを削除しました。',
        duration: 1,
        placement: 'topRight',
      })
    },

    onError: (error: any) => {
      console.log(error)
      notification.error({
        message: `${error.message}`,
        duration: 2,
        placement: 'topRight',
      })
    },
  })

  const handleOk = () => {
    mutate(IDdelete)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    OnClose()
  }

  //auto focus when open modal
  const okButtonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (isModalOpen) {
      okButtonRef?.current?.focus()
    }
  }, [isModalOpen])

  return (
    <ModalConfirmDeleteAlert
      title="削除の確認"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="はい"
      cancelText="キャンセル"
      confirmLoading={deleteLoading}
    >
      <p>お知らせを削除します。よろしいですか。</p>
      <button
        onClick={handleOk}
        ref={okButtonRef}
        style={{ position: 'absolute', opacity: '0' }}
      />
    </ModalConfirmDeleteAlert>
  )
}

export default withErrorBoundary(ConfirmDeleteAlert, {
  FallbackComponent: ErrorComponent,
})

const ModalConfirmDeleteAlert = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
  }
`

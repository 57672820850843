import {
  BellOutlined,
  DollarOutlined,
  FundOutlined,
  HomeOutlined,
  LoginOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons'
import { Button, Menu, MenuProps, Modal } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { TypeAccount } from '../../Context'

type MenuItem = Required<MenuProps>['items'][number]

interface SidebarProps {
  onSelect: (selectedKeys: string) => any
}

function getItem(
  label: React.ReactNode,
  key: React.Key | null,
  icon: React.ReactNode,
  title?: string,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    label,
    key,
    icon,
    children,
    type,
  } as MenuItem
}

const Sidebar: React.FC<SidebarProps> = ({ onSelect }) => {
  const [current, setCurrent] = useState(
    sessionStorage.getItem('MTselectedSidebar') || 'sub1'
  )
  //check type Account
  const typeAccountContext = useContext(TypeAccount)
  const isAdmin = typeAccountContext?.isAdmin

  const Navigate = useNavigate()

  const items: MenuItem[] = [
    getItem('概要', 'sub1', <HomeOutlined />),
    ...(isAdmin
      ? [
          getItem('お知らせ管理', 'sub2', <BellOutlined />),
          getItem('係数入力', 'sub3', <FundOutlined />),
          getItem('余剰電力入力', 'sub4', <ThunderboltOutlined />),
          getItem('買電入力', 'sub5', <DollarOutlined />),
        ]
      : [getItem('買電入力', 'sub5', <DollarOutlined />)]),
    {
      label: 'ログアウト',
      key: 'logout',
      icon: <LoginOutlined />,
    },
  ]

  const handleLogout = () => {
    sessionStorage.clear()
    typeAccountContext.updateValue(undefined)
    Navigate('/')
  }
  const [isModalLogOut, setIsModalLogOut] = useState<boolean>(false)

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      setIsModalLogOut(true)
    } else {
      sessionStorage.setItem('MTselectedSidebar', e.key)
      onSelect(e.key)
      setCurrent(e.key)
    }
  }
  //auto focus

  const btnRef = useRef<any>(null)
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (isModalLogOut) {
      timeoutId = setTimeout(() => {
        btnRef?.current?.focus()
      }, 100)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isModalLogOut])

  return (
    <>
      <Menu
        theme="dark"
        onClick={onClick}
        defaultOpenKeys={['sub1']}
        selectedKeys={[current]}
        mode="inline"
        items={items}
      />
      <Modal
        title="ログアウトします。よろしいですか。"
        open={isModalLogOut}
        onOk={handleLogout}
        onCancel={() => setIsModalLogOut(false)}
        footer={
          <FooterModalWrapper>
            <Button
              ref={btnRef}
              onClick={handleLogout}
              autoFocus
              type="primary"
            >
              はい
            </Button>
            <Button onClick={() => setIsModalLogOut(false)}>キャンセル</Button>
          </FooterModalWrapper>
        }
      />
    </>
  )
}

export default Sidebar

const FooterModalWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`

import { ConfigProvider } from 'antd'
import React from 'react'
import { customizeRenderEmpty } from '../../../Utils/CustomHook/CustomNoData'
import {
  checkChangeUnitElectric,
  formatColumTableElectric,
} from '../../../Utils/Helper/HandleData'
import { TableData } from '../../Styled/MainPage.style'
import { handleDataElectric } from '../HandleDataIchiran'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import { useElementWidth } from '../../../Utils/CustomHook/useGetScreenWidth'

interface yearDetail {
  data?: any
  dataNestChild: any
  nameParent?: string | undefined
}

const IchiranElectricTable: React.FC<yearDetail> = ({
  data,
  dataNestChild,
  nameParent,
}) => {
  const renderData = handleDataElectric(data, dataNestChild, false, nameParent)
  const isChangeUnit = checkChangeUnitElectric(renderData, false)
  const columns = formatColumTableElectric(isChangeUnit, '', true)
  const screenWidth = useElementWidth('ichiranPage')

  return (
    <>
      <div
        style={{
          maxWidth: screenWidth ? screenWidth * 0.37 : 400,
          height: '100%',
        }}
      >
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <TableData
            className="ichiranTable"
            bordered
            columns={columns}
            dataSource={renderData}
            pagination={false}
            scroll={{ x: true, y: 400 }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default withErrorBoundary(IchiranElectricTable, {
  FallbackComponent: ErrorComponent,
})

import { CaretUpOutlined } from '@ant-design/icons'
import { ConfigProvider, Table } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'
import { customizeRenderEmpty } from '../../../Utils/CustomHook/CustomNoData'
import { formatNumber } from '../../../Utils/Helper/FormatNumber'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
interface pageProps {
  data: any
}

const IchiranInvoice: React.FC<pageProps> = ({ data }) => {
  const columns: any = [
    {
      title: <div className="ichiran-table-header">No</div>,
      width: 60,
      fixed: 'left',
      dataIndex: 'index',
      key: 'idx',
      align: 'center' as const,
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>顧客</p>
          <p>ー</p>
        </div>
      ),
      dataIndex: 'companyNameChild',
      key: 'companyNameChild',
      fixed: 'left',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>拠点</p>
          <p>ー</p>
        </div>
      ),
      dataIndex: 'companyNameParent',
      key: 'companyNameParent',
      fixed: 'left',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>契約電力</p>
          <p>kW</p>
        </div>
      ),
      dataIndex: 'contractCoefficient',
      key: '1',
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },
    },
    {
      title: <div className="ichiran-table-header">PV容量</div>,
      dataIndex: 'pv',
      key: '2',
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },
    },
    {
      title: <div className="ichiran-table-header">PCS容量</div>,
      dataIndex: 'pcs',
      key: '3',
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },
    },
    {
      title: <div className="ichiran-table-header">契約種別</div>,
      dataIndex: 'plantContent',
      key: '4',
    },
    {
      title: <div className="ichiran-table-header">検針日</div>,
      dataIndex: 'inspectionDate',
      key: '5',
      align: 'center',
      render: (val: any) => {
        return dayjs(val).isValid() ? dayjs(val).format('YYYY年MM月DD日') : ''
      },
    },
    {
      title: <div className="ichiran-table-header">支払方法</div>,
      dataIndex: 'paymentMethod',
      key: '6',
    },
    {
      title: <div className="ichiran-table-header">住所</div>,
      dataIndex: 'addressParent',
      key: '7',
    },
    {
      title: <div className="ichiran-table-header">系統電力</div>,
      dataIndex: 'electricCoefficient',
      key: '8',
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },
    },
    {
      title: <div className="ichiran-table-header">督促状態</div>,
      dataIndex: 'dunningStatus',
      key: '9',
    },
    {
      title: <div className="ichiran-table-header">請求先番号</div>,
      dataIndex: 'invoiceAddressCode',
      key: '10',
      align: 'left',
    },
    {
      title: <div className="ichiran-table-header">請求先氏名</div>,
      dataIndex: 'nameInvoiceAddress',
      key: '11',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>自家消費分</p>
          <p>kWh</p>
        </div>
      ),
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },

      dataIndex: 'electricityConsumption',
      key: '12',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>電気料金単価</p>
          <p>円/kWh</p>
        </div>
      ),
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },

      dataIndex: 'ppaTanka',
      key: '13',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>電気料金</p>
          <p>円</p>
        </div>
      ),
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },

      dataIndex: 'electricityBill',
      key: '14',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>オプション料</p>
          <p>円</p>
        </div>
      ),
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },

      dataIndex: 'optionFee',
      key: '15',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>割引額</p>
          <p>円</p>
        </div>
      ),
      align: 'right',
      render: (val: string | number) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <CaretUpOutlined style={{ color: 'red', marginLeft: '10px' }} />
            {formatNumber(+val)}
          </div>
        )
      },

      dataIndex: 'discount',
      key: '25',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>請求金額</p>
          <p>円</p>
        </div>
      ),
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },

      dataIndex: 'billPaymentMoney',
      key: '16',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>入金金額</p>
          <p>円</p>
        </div>
      ),
      align: 'right',
      render: (val: string | number) => {
        return formatNumber(+val)
      },

      dataIndex: 'payments',
      key: '17',
    },
    {
      title: (
        <div className="ichiran-table-header">
          <p>収納結果</p>
          <p>ー</p>
        </div>
      ),

      dataIndex: 'cashResult',
      key: '18',
    },
    {
      title: <div className="ichiran-table-header">銀行名/支店名</div>,
      dataIndex: 'bankName',
      key: '19',
    },
    {
      title: <div className="ichiran-table-header">口座番号</div>,
      dataIndex: 'accountNumber',
      key: '20',
    },
    {
      title: <div className="ichiran-table-header">加入申込日</div>,
      dataIndex: 'applicationDate',
      key: '21',
      align: 'left',
      render: (val: any) => {
        return dayjs(val).isValid() ? dayjs(val).format('YYYY年MM月DD日') : val
      },
    },
    {
      title: <div className="ichiran-table-header">施工完了日</div>,
      dataIndex: 'completionDate',
      key: '22',
      align: 'left',
      render: (val: any) => {
        return dayjs(val).isValid() ? dayjs(val).format('YYYY年MM月DD日') : val
      },
    },
    {
      title: <div className="ichiran-table-header">契約開始日</div>,

      dataIndex: 'startDateElectricity',
      key: '23',
      align: 'left',
      render: (val: any) => {
        return dayjs(val).isValid() ? dayjs(val).format('YYYY年MM月DD日') : ''
      },
    },
    {
      title: <div className="ichiran-table-header">解約予定日</div>,
      dataIndex: 'endDateElectricity',
      key: '24',
      align: 'left',
      render: (val: any) => {
        return dayjs(val).isValid() ? dayjs(val).format('YYYY年MM月DD日') : ''
      },
    },
  ]

  const screenHeight = window.innerHeight
  let scrollY: number
  switch (true) {
    case screenHeight <= 720:
      scrollY = 350
      break
    case screenHeight <= 768:
      scrollY = 400
      break
    case screenHeight <= 840:
      scrollY = 450
      break
    default:
      scrollY = 500
      break
  }

  return (
    <>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <TableStyle
          columns={columns}
          dataSource={data}
          scroll={{ y: scrollY, x: 3600 }}
          bordered
          pagination={false}
          tableLayout="auto"
        />
      </ConfigProvider>
    </>
  )
}

export default withErrorBoundary(IchiranInvoice, {
  FallbackComponent: ErrorComponent,
})

const TableStyle = styled(Table)`
  width: 100%;
  min-height: 420px;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-pagination {
        display: none;
      }
    }
  }
  .ichiran-table-header {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
  .ant-table {
    font-size: 12px;
  }

  .ant-table-cell {
    padding: 4px !important;
    min-width: 60px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-table-row:nth-child(odd) {
    td {
      background: #f5f5f5;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      background: #c7cbd3;
    }
  }

  .ant-table-body::-webkit-scrollbar {
    width: 6px;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 10px;
  }

  .ant-table-body::-webkit-scrollbar-track {
    background-color: #eee;
  }
`

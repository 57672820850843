import { ConfigProvider } from 'antd'
import 'dayjs/locale/ja'
import React from 'react'
import { customizeRenderEmpty } from '../../../Utils/CustomHook/CustomNoData'
import {
  checkChangeUnitCo2,
  formatColumTableCo2,
} from '../../../Utils/Helper/HandleData'
import { TableData } from '../../Styled/MainPage.style'
import { handleDataCo2 } from '../HandleDataIchiran'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'
import { useElementWidth } from '../../../Utils/CustomHook/useGetScreenWidth'

interface MonthPowerProps {
  data?: any
  dataNestChild: any
  nameParent?: string | undefined
}

const IchiranCo2Table: React.FC<MonthPowerProps> = ({
  data,
  dataNestChild,
  nameParent,
}) => {
  const renderData = handleDataCo2(data, dataNestChild, false, nameParent)
  const isChangeUnit = checkChangeUnitCo2(renderData, false)
  const columns = formatColumTableCo2(isChangeUnit, '', true)
  const screenWidth = useElementWidth('ichiranPage')

  return (
    <>
      <div
        style={{
          maxWidth: screenWidth ? screenWidth * 0.37 : 400,
          height: '100%',
          fontSize: '13px',
        }}
      >
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <TableData
            className="ichiranTable"
            bordered
            columns={columns}
            dataSource={renderData}
            pagination={false}
            scroll={{ x: true, y: 400 }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default withErrorBoundary(IchiranCo2Table, {
  FallbackComponent: ErrorComponent,
})

import React, { useContext } from 'react'
import styled from 'styled-components'
import ButtonDefault from '../UI/Button/ButtonDefault'
import { TypeAccount } from '../Context'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'

interface pageType {
  onChange: (mode: string) => void
  mode: string
}
const IchiranMode: React.FC<pageType> = React.memo(({ onChange, mode }) => {
  const { isAdmin } = useContext(TypeAccount)

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <HeaderLogo style={{ flex: 2 }}></HeaderLogo>
      <Tab>
        <ButtonDefault
          handleClick={() => onChange('Electric')}
          className={`tab-btn ${mode === 'Electric' ? 'active-tab-btn' : ''}`}
          title="電力"
        />
        <ButtonDefault
          handleClick={() => onChange('Co2')}
          className={`tab-btn ${mode === 'Co2' ? 'active-tab-btn' : ''}`}
          title="CO2"
        />
        <ButtonDefault
          handleClick={() => onChange('Price')}
          className={`tab-btn ${mode === 'Price' ? 'active-tab-btn' : ''}`}
          title="料金"
        />
        {isAdmin && (
          <ButtonDefault
            handleClick={() => onChange('Invoice')}
            className={`tab-btn ${mode === 'Invoice' ? 'active-tab-btn' : ''}`}
            title="請求入金"
          />
        )}
      </Tab>
    </div>
  )
})

export default withErrorBoundary(IchiranMode, {
  FallbackComponent: ErrorComponent,
})

const Tab = styled.div`
  margin-bottom: 20px;
  flex: 5;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .active-tab-btn {
    background-color: var(--theme-color) !important;
  }
`
const HeaderLogo = styled.div`
  flex: 2;
  min-width: 225px;
  @media (max-width: 1024px) {
    display: none;
  }
`

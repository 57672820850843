import { CaretUpOutlined } from '@ant-design/icons'
import { Card, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CalculatorYAxis } from '../../Utils/CustomChart/CalculatorYAxisChart'
import {
  CustomYAxisTickDown,
  CustomYAxisTickUp,
} from '../../Utils/CustomChart/CustomTick'
import { RenderLegend } from '../../Utils/CustomChart/RenderLegend'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import {
  RoundNumberAfterChangeUnit,
  formatNumber,
} from '../../Utils/Helper/FormatNumber'
import {
  checkChangeUnitElectric,
  handleDataForElectricChart,
} from '../../Utils/Helper/HandleData'
import { ChartContainer, NameChart, NoteChart } from '../Styled/MainPage.style'
import ButtonDefault from '../UI/Button/ButtonDefault'
import { getLabelShow } from '../../Utils/Helper/HandleLable'
interface chartProps {
  fetchingOption: boolean
  loadingData: boolean
  data: any
  checkboxValue?: boolean
  loadingCompare?: boolean
  dataCompare?: any
  firstName?: string
  lastName?: string
  modeView: string
}

const ElectricChart: React.FC<chartProps> = React.memo(
  ({
    fetchingOption,
    loadingData,
    data,
    checkboxValue,
    loadingCompare,
    dataCompare,
    firstName,
    lastName,
    modeView,
  }) => {
    const dataChart2 = [
      {
        key: 'field1',
        title: '【参考】電力会社料金',
        value1: data?.totalPowerCompanyFee,
      },
      {
        key: 'field2',
        title: 'MODEL-T',
        value2: data?.totalConsumptionFee,
      },
    ]

    const [activeDataKeys, setActiveDataKeys] = useState([
      'generate',
      'consumption',
      'reverse',
      'compare',
      'nomalElectric',
    ])
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [renderData, setRenderData] = useState<any>()
    const [isChangeUnit, setIsChangeUnit] = useState<boolean>(false)

    const calculateWidthChart = (screenWidth: number) => {
      if (screenWidth < 1025) {
        return screenWidth * 0.85
      } else {
        return screenWidth * 0.52
      }
    }
    const [widthChart, setWidthChart] = useState<number>(
      calculateWidthChart(screenWidth)
    )

    const electricResponses =
      data && handleDataForElectricChart(data.electricResponses)
    const electricResponsesCompare =
      dataCompare && handleDataForElectricChart(dataCompare.electricResponses)

    useEffect(() => {
      const handleResize = () => {
        setTimeout(() => setScreenWidth(window.innerWidth), 100)
      }
      window.addEventListener('resize', handleResize)

      // cleanup event
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    useEffect(() => {
      setWidthChart(calculateWidthChart(screenWidth))
    }, [screenWidth])

    useEffect(() => {
      let processedData = electricResponses

      if (dataCompare) {
        processedData = electricResponses?.map((item: any, index: number) => {
          const compareData = electricResponsesCompare?.[index] || {}
          return {
            ...item,
            generate2: compareData.generate || 0,
            reverse2: compareData.reverse || 0,
            consumption2: compareData.consumption || 0,
            nomalElectric2: compareData.nomalElectric || 0,
            compare:
              (Number(compareData.consumption) || 0) +
              (Number(compareData.nomalElectric) || 0),
          }
        })
      }

      const isChange = checkChangeUnitElectric(processedData, true)
      setIsChangeUnit(isChange)

      let renderData

      if (isChange) {
        renderData = processedData?.map((val: any) => ({
          ...val,
          generate: RoundNumberAfterChangeUnit(Number(val.generate) / 1000),
          reverse: RoundNumberAfterChangeUnit(Number(val.reverse) / 1000),
          consumption: RoundNumberAfterChangeUnit(
            Number(val.consumption) / 1000
          ),
          nomalElectric: RoundNumberAfterChangeUnit(
            Number(val.nomalElectric) / 1000
          ),
          compare: RoundNumberAfterChangeUnit(Number(val.compare) / 1000),
          consumption2: RoundNumberAfterChangeUnit(
            Number(val.consumption2) / 1000
          ),
          generate2: RoundNumberAfterChangeUnit(Number(val.generate2) / 1000),
          nomalElectric2: RoundNumberAfterChangeUnit(
            Number(val.nomalElectric2) / 1000
          ),
          reverse2: RoundNumberAfterChangeUnit(Number(val.reverse2) / 1000),
        }))
      } else {
        renderData = processedData
      }

      setRenderData(renderData)
    }, [data, dataCompare])

    const handleClickLegend = (e: any) => {
      const { dataKey } = e
      const index = activeDataKeys.indexOf(dataKey)

      if (index === -1) {
        setActiveDataKeys([...activeDataKeys, dataKey])
      } else {
        setActiveDataKeys(activeDataKeys.filter((key) => key !== dataKey))
      }
    }

    const isDataKeyActive = (dataKey: any) =>
      activeDataKeys.indexOf(dataKey) !== -1

    const isChangeBar = electricResponses
      ?.map((item: any) => item.reverse)
      .some((val: any) => val < 0)

    const disableChart = fetchingOption || loadingCompare || loadingData

    const [showChart2, setShowChart2] = useState<boolean>(false)

    //set max Y
    const [max1, setMax1] = useState<number>(0)
    const [max2, setMax2] = useState<number>(0)
    const [maxY, setMaxY] = useState<number>(0)

    useEffect(() => {
      max1 >= max2 ? setMaxY(max1) : setMaxY(max2)
    }, [max1, max2])

    const customTooltip = ({ active, payload, label }: any) => {
      if (active && payload) {
        const reversedPayload = [...payload].reverse()

        const labelShow = getLabelShow(label, modeView)

        return (
          <div className="custom-tooltip">
            <p className="label">{labelShow}</p>
            {reversedPayload.map((entry, index) => (
              <p key={`item-${index}`} style={{ color: entry.color }}>
                {`${entry.name}: ${formatNumber(entry.value)}`}
              </p>
            ))}
          </div>
        )
      }

      return null
    }

    return (
      <ChartContainer style={{ opacity: disableChart ? '0.5' : '1' }}>
        {!showChart2 ? (
          <NameChart>
            {firstName} {''}
            {isChangeUnit ? 'MWh ' : 'kWh '}
            {lastName}
          </NameChart>
        ) : (
          <>
            <NameChart>モデル・ティでんき導入の効果</NameChart>
            <NoteChart>
              ※１ ．モデル・ティでんきを導入しなかった場合の電気料金。
            </NoteChart>
          </>
        )}
        {disableChart ? (
          <Spin style={{ position: 'absolute', left: '50%', top: '6%' }} />
        ) : (
          ''
        )}

        {!showChart2 ? (
          <>
            <ComposedChart
              data={renderData}
              barSize={8}
              width={widthChart}
              height={235}
              margin={{ top: 5, right: 5, bottom: 0, left: 35 }}
            >
              <XAxis
                dataKey={(value) => {
                  if (value.startYear) {
                    return value.startYear
                  } else return value.title
                }}
                tickSize={1}
                minTickGap={-40}
                tickLine={false}
                height={1}
                hide={false}
                tick={false}
              />
              <YAxis
                domain={([dataMin, dataMax]) => {
                  setMax1(dataMax)
                  const [min, max] = CalculatorYAxis(maxY)
                  return [min, max]
                }}
                label={{
                  value: isChangeUnit
                    ? '（買電）電力量（MWh）'
                    : '（買電）電力量（kWh）',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -25,
                  style: { textAnchor: 'middle' },
                }}
                tick={<CustomYAxisTickUp />}
              />
              <CartesianGrid
                strokeDasharray="1 1"
                verticalCoordinatesGenerator={(props) => {
                  const left: number = props?.offset?.left || 0
                  const barSize: number = props?.xAxis?.bandSize
                  const items = props?.xAxis?.domain

                  let space = left
                  let grid: any = []
                  items?.forEach((element: any) => {
                    space = space + barSize
                    grid.push(space)
                  })

                  return grid
                }}
              />
              {/* <Tooltip
                formatter={(value, name, props) => {
                  let data = formatNumber(+value)
                  if (isChangeUnit) {
                    data = formatNumber(+value)
                  }
                  return [data, name]
                }}
              /> */}
              <Tooltip content={customTooltip} />
              <Legend
                verticalAlign="top"
                content={
                  <RenderLegend
                    handleClickLegend={handleClickLegend}
                    isDataKeyActive={isDataKeyActive}
                  />
                }
                height={50}
              />
              <Bar
                name="太陽光自家消費"
                dataKey="consumption"
                fill="#001d66"
                stackId="a"
                hide={!isDataKeyActive('consumption')}
              />
              <Bar
                name="買電（系統から）"
                dataKey="nomalElectric"
                fill="#FFC000"
                stackId="a"
                hide={isChangeBar || !isDataKeyActive('nomalElectric')}
              />
              <Bar
                name="余剰電力（系統へ）"
                dataKey="reverse"
                fill="#898989"
                stackId="a"
                hide={true}
              />
              {checkboxValue && electricResponsesCompare?.length > 0 && (
                <Line
                  name="比較"
                  type="linear"
                  dataKey={isChangeBar ? 'consumption2' : 'compare'}
                  stroke="#ab6fe2"
                  hide={!isDataKeyActive('compare')}
                  strokeWidth={2}
                />
              )}
            </ComposedChart>
            <ComposedChart
              data={renderData}
              barSize={8}
              width={widthChart}
              height={200}
              margin={{ top: 0, right: 5, bottom: 5, left: 35 }}
            >
              <XAxis
                dataKey={(value) => {
                  if (value.startYear) {
                    return value.startYear
                  } else return value.title
                }}
                tickSize={4}
                minTickGap={-40}
                hide={false}
                orientation="bottom"
                tick={true}
                height={15}
              ></XAxis>
              <YAxis
                domain={([dataMin, dataMax]) => {
                  setMax2(dataMax)
                  const [min, max] = CalculatorYAxis(maxY)
                  return [min, max]
                }}
                label={{
                  value: isChangeUnit
                    ? '（余剰）電力量（MWh）'
                    : '（余剰）電力量（kWh）',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -25,
                  style: { textAnchor: 'middle' },
                }}
                reversed={true}
                tick={<CustomYAxisTickDown />}
              />
              <CartesianGrid
                strokeDasharray="1 1"
                verticalCoordinatesGenerator={(props) => {
                  const left: number = props?.offset?.left || 0
                  const barSize: number = props?.xAxis?.bandSize
                  const items = props?.xAxis?.domain

                  let space = left
                  let grid: any = []
                  items?.forEach((element: any) => {
                    space = space + barSize
                    grid.push(space)
                  })

                  return grid
                }}
              />

              <Tooltip content={customTooltip} />

              <Bar
                name="余剰電力（系統へ）"
                dataKey="reverse"
                fill="#898989"
                stackId="a"
                hide={isChangeBar || !isDataKeyActive('reverse')}
              />
              <Bar
                name="買電（系統から）"
                dataKey="nomalElectric"
                fill="#FFC000"
                stackId="a"
                hide={!isChangeBar || !isDataKeyActive('nomalElectric')}
              />

              {checkboxValue && electricResponsesCompare?.length > 0 && (
                <Line
                  name="比較"
                  type="linear"
                  data={renderData}
                  dataKey={isChangeBar ? 'nomalElectric2' : 'reverse2'}
                  stroke="#ab6fe2"
                  hide={!isDataKeyActive('compare')}
                  strokeWidth={2}
                />
              )}
            </ComposedChart>
          </>
        ) : (
          <>
            <Card style={{ width: 'fit-content' }}>
              <Card.Grid
                style={{
                  width: '100%',
                  padding: '4px 8px',
                  borderRadius: '10px',
                  color: 'var(--theme-color)',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                {data?.difference < 0 ? (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <CaretUpOutlined
                      style={{ color: 'red', marginLeft: '10px' }}
                    />
                    <div>
                      {formatNumber(Math.abs(+data?.difference || 0))} 千円
                      <span style={{ fontSize: '16px' }}>削減</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    {formatNumber(Math.abs(+data?.difference || 0))} 千円
                    <span style={{ fontSize: '16px' }}>削減</span>
                  </div>
                )}
              </Card.Grid>
            </Card>

            <BarChart
              style={{
                width: '100%',
              }}
              data={dataChart2}
              barSize={20}
              width={widthChart}
              height={380}
              margin={{
                top: 10,
                right: 5,
                bottom: 5,
                left: 8,
              }}
            >
              <XAxis dataKey="title"></XAxis>
              <Tooltip content={customTooltip} />

              <YAxis
                domain={([dataMin, dataMax]) => {
                  setMax2(dataMax)
                  const [min, max] = CalculatorYAxis(dataMax)
                  return [min, max]
                }}
                label={{
                  value: '千円',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 0,
                  style: { textAnchor: 'middle' },
                }}
                width={80}
              />
              <CartesianGrid strokeDasharray="1 1" />

              <Legend
                verticalAlign="bottom"
                fontSize={10}
                onClick={handleClickLegend}
                iconSize={50}
                iconType="plainline"
              />
              <Bar
                name="【参考】電力会社料金"
                dataKey="value1"
                fill="#f4003d"
                stackId="a"
              />
              <Bar
                name="MODEL-T"
                dataKey="value2"
                fill="#000272e6"
                stackId="a"
              />
            </BarChart>
          </>
        )}
        <div className="btn-change-mode-chart">
          <ButtonDefault
            className={showChart2 ? 'inactive' : 'active'}
            title="電力量"
            handleClick={() => setShowChart2(false)}
          />
          <ButtonDefault
            className={showChart2 ? 'active' : 'inactive'}
            title="経済効果 "
            handleClick={() => setShowChart2(true)}
          />
        </div>
      </ChartContainer>
    )
  }
)
export default withErrorBoundary(ElectricChart, {
  FallbackComponent: ErrorComponent,
})

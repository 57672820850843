import { Checkbox, DatePicker, Table } from 'antd'
import styled from 'styled-components'

export const NameChart = styled.p`
  color: #555;
  width: 100%;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  &.NameChartCo2 {
    top: -30px;
  }
  &.NameChartPrice {
    top: 4px;
    position: sticky;
    left: 0;
    transform: none;
  }
  &.ichiranElectric {
    top: -4px;
    position: sticky;
    left: 0;
    transform: none;
  }
  &.IchiranNameChartCo2 {
    top: 4px;
    position: sticky;
    left: 0;
    transform: none;
  }
  @media (max-width: 1024px) {
    top: 0px;
  }
`
export const NoteChart = styled.p`
  color: #555;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  @media (max-width: 1024px) {
    bottom: 0px;
  }
`

export const ChartContainer = styled.div`
  .recharts-legend-item-text {
    font-size: 12px !important;
  }
  .recharts-default-legend {
    justify-content: center !important;
  }
  .btn-change-mode-chart {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    position: absolute;
    right: 0px;
    bottom: -40px;
    button {
      width: fit-content;
    }
  }
  .ant-card {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-30%);
    z-index: 99;
  }
  .custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;

    .field {
      margin-top: 4px;
      display: flex;
    }
  }

  &.ichiranChart {
    overflow-x: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: space-around;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);

    @media (max-width: 1024px) {
      height: 480px;
    }
    &.priceChart .recharts-wrapper {
      height: 420px !important;
      .recharts-default-legend {
        display: flex;
        gap: 20px;
        padding-left: 80px !important;
      }
    }
  }
  &.ichiranChart-Electric {
    display: block;
    overflow-x: auto;
    height: 100%;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);

    @media (max-width: 1024px) {
      height: 480px;
    }
  }
`

export const DatePickerWrapper = styled(DatePicker)`
  width: 36%;
  background: var(--theme-color);
  input {
    color: white !important;
    font-size: 14px !important;
    @media (max-width: 1279px) {
      font-size: 12px !important;
    }
    @media (max-width: 1024px) {
      font-size: 14px !important;
    }
  }
  input::placeholder {
    color: #f5f5f5 !important;
  }
  .ant-picker-suffix {
    color: white;
  }
  &.ant-picker-disabled {
    background: #c7cbd3 !important;
  }
  .ant-picker-month-btn {
    margin-inline-start: 4px !important;
  }
`

export const CheckBoxWrapper = styled(Checkbox)`
  padding-top: 4px;
  span:last-child {
    padding-inline-end: 0px !important;
    font-weight: bold;
  }
`

export const TableData = styled(Table)<{ setFontSize?: boolean }>`
  width: 100%;
  min-height: 420px;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-pagination {
        display: none;
      }
    }
  }
  .ant-table {
    font-size: 14px;
    @media (max-width: 1440px) {
      font-size: 12px;
    }
    @media (max-width: 1280px) {
      font-size: ${(p) => (p.setFontSize ? 8 : 10)}px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }
  /* .ant-table-header {
    table {
      table-layout: auto !important;
    }
  } */

  &.ichiranTable .ant-table-tbody {
    .ant-table-cell {
      border-bottom: 1px dashed #666;
    }
  }
  .ant-table-cell {
    padding: 2px !important;
    text-align: right;

    min-width: 50px;
  }
  /* th.ant-table-cell {
    padding: 0px;
  } */

  .ant-table-thead {
    font-size: 12px;
    padding: 0px;
    .ant-table-cell {
      text-align: center;
      background: #c7cbd3;
    }
  }

  .ant-table-body::-webkit-scrollbar {
    width: 6px;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 10px;
  }

  .ant-table-body::-webkit-scrollbar-track {
    background-color: #eee;
  }

  tr:nth-child(odd) {
    /* background-color: #f2f2f2; */
  }

  p {
    margin: 0px;
  }
  .table-header {
    height: 60px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 12px;

    @media (max-width: 1640px) {
      font-size: 10px;
    }
    @media (max-width: 1368px) {
      font-size: 9px;
    }
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
  .table-header-nonUnit {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .first-col {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
    -webkit-line-clamp: 1;
    height: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`
export const DatePick = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`

export const GraphWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  max-height: 435px;
  overflow-x: scroll;
  overflow-y: hidden;
  .y-axis-wrapper {
    background: white;
    position: sticky;
    left: 0;
    z-index: 1003;
  }
  .recharts-wrapper {
    transform: translateX(-20px);
  }
`

import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spin,
  Statistic,
  Tooltip,
  notification,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { TypeAccount } from '../../Components/Context'
import FormNewPurchase from '../../Components/PurchaseElectrics/AddNew'
import ConfirmModal from '../../Components/PurchaseElectrics/ConfirmModal'
import FormEditPurchase from '../../Components/PurchaseElectrics/Edit'
import { DatePickerWrapper } from '../../Components/Styled/MainPage.style'
import ButtonDefault from '../../Components/UI/Button/ButtonDefault'
import AccountMng from '../../Components/UI/SelectCompanies'
import { deletePurchase, useFetchPurchase } from '../../Api/Purchase'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import { disabledDate } from '../../Utils/Helper/DisableDay'
import { formatNumber } from '../../Utils/Helper/FormatNumber'

const imageLogo = require('../../Publics/Images/Model-T_logo2.jpg')

const PurchasePowerPage = () => {
  //check type account
  const { isAdmin } = useContext(TypeAccount)

  const [childSelected, setChildSelected] = useState<string>('')
  const [fetchOption, SetFetchOption] = useState<boolean>(false)
  const [addNew, setAddNew] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [year, setYear] = useState(dayjs().startOf('year').format('YYYY'))
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const [dateSelect, setDateSelect] = useState<string>()
  const [dataFotEdit, setDataForEdit] = useState()
  const closeDelete = () => {
    setConfirmDelete(false)
  }

  const closeModal = () => {
    setAddNew(false)
  }

  const onFetchOption = (value: boolean) => {
    SetFetchOption(value)
  }

  const setSelectForCallData = (value: any) => {
    setChildSelected(value)
  }

  const { data, isLoading: PurchaseLoading } = useFetchPurchase(
    childSelected,
    year
  )

  const loading = PurchaseLoading || fetchOption

  //delete Purchase
  const queryClient = useQueryClient()
  const { mutate, isLoading: deleteLoading } = useMutation(deletePurchase, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPurchase'] })
      closeDelete()
      notification.success({
        message: 'お知らせを削除しました。',
        duration: 1,
        placement: 'topRight',
      })
    },
    onError: (error: any) => {
      closeDelete()
      console.log(error)
      notification.error({
        message: `${error.message}`,
        duration: 2,
        placement: 'topRight',
      })
    },
  })

  const handleOk = () => {
    mutate({ variables: { childSelected, dateSelect } })
  }

  const currentDay = dayjs()

  return (
    <>
      <HeaderLogo>
        <img src={imageLogo} alt="logo" />
      </HeaderLogo>

      <AccountMng
        option={(value: any) => setSelectForCallData(value)}
        onFetching={onFetchOption}
        page="power"
      />
      <Container>
        <div style={{ display: 'flex', gap: '20px' }}>
          <DatePickerWrapper
            style={{ width: 120 }}
            allowClear={false}
            locale={locale}
            picker="year"
            disabledDate={disabledDate}
            defaultValue={dayjs().startOf('year')}
            onChange={(value: any) =>
              setYear(dayjs(value || currentDay).format('YYYY'))
            }
          />
          {!isAdmin && (
            <ButtonDefault title="新規" handleClick={() => setAddNew(true)} />
          )}
        </div>
        {loading && (
          <Spin style={{ position: 'absolute', left: '50%', top: '40px' }} />
        )}
        <CardWrapper>
          {data?.length > 0
            ? data?.map((card: any, idx: number) => {
                return (
                  <Card
                    key={idx}
                    title={dayjs(card?.time).format('YYYY年MM月')}
                    bordered={false}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Card
                          bordered={false}
                          style={{ boxShadow: '0 0 2px #3f8600' }}
                        >
                          <Tooltip
                            title={formatNumber(
                              card.monthlyPeak >= 10000
                                ? card.monthlyPeak / 1000
                                : card.monthlyPeak
                            )}
                            color="#3f8600"
                          >
                            <Statistic
                              title="月毎ピークデマンド"
                              value={
                                // card.monthlyPeak > 10000
                                //   ? card.monthlyPeak / 1000
                                //   : card.monthlyPeak
                                card.monthlyPeak
                              }
                              valueStyle={{ color: '#3f8600' }}
                              // suffix={card.monthlyPeak > 10000 ? 'MW' : 'kW'}
                              suffix={'kW'}
                            />
                          </Tooltip>
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card
                          bordered={false}
                          style={{ boxShadow: '0 0 2px #cf1322' }}
                        >
                          <Tooltip
                            // title={formatNumber(
                            //   card.contractPower >= 10000
                            //     ? card.contractPower / 1000
                            //     : card.contractPower
                            // )}
                            title={formatNumber(card.contractPower)}
                            color="#cf1322"
                          >
                            <Statistic
                              title="使用電力量"
                              // value={
                              //   card.contractPower >= 10000
                              //     ? card.contractPower / 1000
                              //     : card.contractPower
                              // }
                              value={card.contractPower}
                              valueStyle={{ color: '#cf1322' }}
                              // suffix={
                              //   card.contractPower >= 10000 ? 'MWh' : 'kWh'
                              // }
                              suffix={'kWh'}
                            />
                          </Tooltip>
                        </Card>
                      </Col>
                      {!isAdmin && (
                        <div
                          style={{
                            display: 'flex',
                            gap: '16px',
                            justifyItems: 'flex-end',
                            marginLeft: '8px',
                            marginTop: '20px',
                          }}
                        >
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => {
                              setDataForEdit(card)
                              setIsEdit(true)
                            }}
                          >
                            編集
                          </Button>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              setDateSelect(dayjs(card.time).format('YYYY-MM'))
                              setConfirmDelete(true)
                            }}
                          >
                            削除
                          </Button>
                        </div>
                      )}
                    </Row>
                  </Card>
                )
              })
            : !loading && (
                <NoDataWrapper>
                  <CloudDownloadOutlined style={{ fontSize: 20 }} />
                  <p>データがありません</p>
                </NoDataWrapper>
              )}
        </CardWrapper>
      </Container>

      <ModalWrapper
        open={addNew}
        title="データの新作成"
        onCancel={closeModal}
        width={600}
        centered
        footer={
          <div>
            <Button
              key={'submitNew'}
              form="Purchase"
              htmlType="submit"
              type="primary"
              autoFocus
            >
              登録する
            </Button>
            <Button onClick={closeModal}>キャンセル</Button>
          </div>
        }
      >
        <FormNewPurchase onClose={() => closeModal()} />
      </ModalWrapper>
      <ModalWrapper
        focusTriggerAfterClose={false}
        open={isEdit}
        title="データの編集"
        onCancel={() => setIsEdit(false)}
        width={600}
        centered
        footer={
          <div>
            <Button
              key={'submitEdit'}
              form="EditPurchase"
              htmlType="submit"
              type="primary"
            >
              編集する
            </Button>

            <Button onClick={() => setIsEdit(false)}>キャンセル</Button>
          </div>
        }
      >
        <FormEditPurchase
          dataOld={dataFotEdit}
          onClose={() => setIsEdit(false)}
          isOpen={isEdit}
        />
      </ModalWrapper>
      <ConfirmModal
        openConfirm={confirmDelete}
        onCancel={closeDelete}
        onOk={handleOk}
        title="削除の確認"
        message={`${dayjs(dateSelect).format(
          'YYYY年MM月'
        )}のデータを削除します。よろしいですか。`}
        loading={deleteLoading}
      />
    </>
  )
}

export default withErrorBoundary(PurchasePowerPage, {
  FallbackComponent: ErrorComponent,
})

const HeaderLogo = styled.div`
  img {
    width: auto;
    height: 70px;
  }
`

const Container = styled.div`
  margin-top: 20px;
  background: rgb(240, 242, 245);
  padding: 20px;
  position: relative;
`
const CardWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .ant-card-head-title {
    color: var(--text-color);
  }
  .ant-card-body {
    padding: 18px;
  }
  .ant-statistic-title {
    color: #333;
  }
  .ant-statistic-content {
    display: flex;
    align-items: center;
  }
  .ant-statistic-content-value {
    margin: 4px 0px;
    /* max-width: 100%; */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    line-height: 20px;
    -webkit-line-clamp: 1;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .ant-statistic-content-suffix {
    font-size: 18px;
  }
`
const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    margin-left: 12px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cccccc66;
  }
`
const NoDataWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`

import { DatePicker, Form, Input, notification } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import ConfirmModal from './ConfirmModal'
import { editPurchase } from '../../Api/Purchase'

interface FormAlertProps {
  onClose: () => void
  dataOld?: any
  isOpen: boolean
}

const FormEditPurchase: React.FC<FormAlertProps> = ({
  onClose,
  dataOld,
  isOpen,
}) => {
  const [month, setMonth] = useState<string>()
  const [isConfirm, setIsConfirm] = useState<boolean>(false)

  const [form] = Form.useForm()
  const storage = JSON.parse(sessionStorage.getItem('secondSelected') as string)

  const queryClient = useQueryClient()
  const { mutate, isLoading: editLoading } = useMutation(editPurchase, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['getPurchase'] })
      onClose()
      setIsConfirm(false)
      notification.success({
        message: '編集に成功しました。',
        duration: 1,
        placement: 'topRight',
      })
      form.resetFields()
    },
    onError: (error: any) => {
      setIsConfirm(false)
      onClose()
      console.log(error)
      notification.error({
        message: `${error.message}`,
        duration: 2,
        placement: 'topRight',
      })
    },
  })

  const onOKConfirm = () => {
    const field1 = form.getFieldValue('monthlyPeak')
    const field2 = form.getFieldValue('contractPower')
    const plantID = storage?.[1]
    mutate({
      variables: { field1, field2, month, plantID },
    })
  }

  //set old data
  useEffect(() => {
    const convertData = {
      ...dataOld,
      date: dayjs(dataOld?.time),
    }
    form.setFieldsValue(convertData)
    setMonth(dayjs(dataOld?.time).format('YYYY-MM-DD'))
  }, [dataOld])

  return (
    <>
      <FormEditPurchaseWrapper
        name="EditPurchase"
        form={form}
        layout="horizontal"
        autoComplete="off"
        onFinish={() => setIsConfirm(true)}
      >
        <CustomFormItem name="date" label="年月" required>
          <DatePicker
            picker="month"
            allowClear={false}
            size={'large'}
            style={{ width: '100%' }}
            format={'YYYY-MM'}
            locale={locale}
            disabled={true}
          />
        </CustomFormItem>
        <CustomFormItem
          name="monthlyPeak"
          label="月毎ピークデマンド（kW）"
          rules={[
            {
              required: true,
              message: '月毎ピークデマンドを入力してください。',
            },
          ]}
        >
          <Input type="number" allowClear size={'large'} placeholder="手入力" />
        </CustomFormItem>
        <CustomFormItem
          name="contractPower"
          label="使用電力量（kWh）"
          rules={[
            {
              required: true,
              message: '使用電力量を入力してください。',
            },
          ]}
        >
          <Input type="number" allowClear size={'large'} placeholder="手入力" />
        </CustomFormItem>
      </FormEditPurchaseWrapper>

      <ConfirmModal
        openConfirm={isConfirm}
        onOk={() => onOKConfirm()}
        onCancel={() => setIsConfirm(false)}
        title="確認"
        message="データを編集します。よろしいですか。"
        loading={editLoading}
      />
    </>
  )
}
export default FormEditPurchase

const FormEditPurchaseWrapper = styled(Form)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const CustomFormItem = styled(Form.Item)`
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-label {
    flex: 2;
    text-align: left;
  }
  .ant-form-item-control {
    flex: 3;
  }
`

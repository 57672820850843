import { CaretUpOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { formatNumber } from './FormatNumber'

export const checkChangeUnitElectric = (value: any, isChart: boolean) => {
  const Value = isChart ? value : value?.slice(1)
  const valuesToCheck = Value?.map((item: any) => ({
    generate: Number(item.generate),
    generate2: Number(item.generate2),
    reverse: Number(item.reverse),
    reverse2: Number(item.reverse2),
    consumption: Number(item.consumption),
    consumption2: Number(item.consumption2),
    nomalElectric: Number(item.nomalElectric),
    nomalElectric2: Number(item.nomalElectric2),
  }))

  const isGreaterThan10000 = valuesToCheck?.some(
    (item: any) =>
      Math.abs(item.generate) >= 10000 ||
      Math.abs(item.generate2) >= 10000 ||
      Math.abs(item.reverse) >= 10000 ||
      Math.abs(item.reverse2) >= 10000 ||
      Math.abs(item.consumption) >= 10000 ||
      Math.abs(item.consumption2) >= 10000 ||
      Math.abs(item.nomalElectric) >= 10000 ||
      Math.abs(item.nomalElectric2) >= 10000
  )

  return isGreaterThan10000
}

//check change unit CO2
export const checkChangeUnitCo2 = (value: any, isChart: boolean) => {
  const Value = isChart ? value : value?.slice(1)
  const valuesToCheck = Value?.map((item: any) => ({
    gridPower: Number(item.gridPower),
    gridElectricityConsumption: Number(item.gridElectricityConsumption),
    modelTElectricityUsage: Number(item.modelTElectricityUsage),
    gridPowerCo2Emissions: Number(item.gridPowerCo2Emissions),
    modelTCo2Emissions: Number(item.modelTCo2Emissions),
  }))

  const isGreaterThan10000 = valuesToCheck?.some(
    (item: any) =>
      Math.abs(item.gridPower) >= 10000 ||
      Math.abs(item.gridElectricityConsumption) >= 10000 ||
      Math.abs(item.modelTElectricityUsage) >= 10000 ||
      Math.abs(item.gridPowerCo2Emissions) >= 10000 ||
      Math.abs(item.modelTCo2Emissions) >= 10000
  )

  return isGreaterThan10000
}

//handle data for Electric Chart
export const handleDataForElectricChart = (data: any) => {
  const modifiedResponses = data?.map((obj: any) => {
    const newObj = { ...obj }
    for (const key in newObj) {
      if (newObj[key] === null) {
        newObj[key] = 0
      }
    }
    return newObj
  })

  return modifiedResponses || []
}

//format Table

export const formatColumTableElectric = (
  isChangeUnit: boolean,
  title?: string,
  isIchiran?: boolean
) => {
  const columns: ColumnsType<any> = [
    isIchiran
      ? {
          key: 1,
          title: '',
          dataIndex: 'title',
          align: 'left' as const,
          width: '12%',
          ellipsis: true,
          fixed: 'left',

          render: (value: any) => {
            return (
              <>
                <Tooltip title={value}>
                  <p className="first-col">{value}</p>
                </Tooltip>
              </>
            )
          },
        }
      : {
          key: 2,
          title: title,
          dataIndex: 'title',
          align: 'center' as const,
          width: '12%',
          ellipsis: true,
          fixed: 'left',

          render: (value: any) => {
            if (typeof value === 'string') {
              return value
            } else {
              const formattedDate = value.toString().padStart(2, '0')
              return formattedDate
            }
          },
        },
    {
      key: 3,
      title: (
        <div className="table-header">
          <div className="table-header-nonUnit">
            <p>発電量</p>
          </div>
          {isChangeUnit ? <p>MWh</p> : <p>kWh</p>}
        </div>
      ),
      dataIndex: 'generate',
      ellipsis: true,

      render: (value: any) => {
        if (value === null) {
          return 'ー'
        }
        if (isChangeUnit && value !== 0) {
          return formatNumber((value / 1000).toFixed(3))
        }
        return formatNumber(value)
      },
    },
    {
      key: 4,
      title: (
        <div className="table-header">
          <div className="table-header-nonUnit">
            <span>余剰電力</span>
            <p>（系統へ）</p>
          </div>
          {isChangeUnit ? <p>MWh</p> : <p>kWh</p>}
        </div>
      ),
      dataIndex: 'reverse',
      ellipsis: true,

      render: (value: any) => {
        if (value === null) {
          return 'ー'
        }
        if (isChangeUnit && value !== 0) {
          return formatNumber((value / 1000).toFixed(3))
        }
        return formatNumber(value)
      },
    },
    {
      key: 5,
      title: (
        <div className="table-header">
          <div className="table-header-nonUnit">
            <span>太陽光</span>
            <p>自家消費</p>
          </div>
          {isChangeUnit ? <p>MWh</p> : <p>kWh</p>}
        </div>
      ),
      dataIndex: 'consumption',
      ellipsis: true,

      render: (value: any) => {
        if (value === null) {
          return 'ー'
        }
        if (isChangeUnit && value !== 0) {
          return formatNumber((value / 1000).toFixed(3))
        }
        return formatNumber(value)
      },
    },
    {
      key: 6,
      title: (
        <div className="table-header">
          <div className="table-header-nonUnit">
            <span>モデル・ティ</span>
            <p>でんき料金</p>
          </div>
          <p>円</p>
        </div>
      ),
      dataIndex: 'consumptionFee',
      ellipsis: true,

      render: (value: string | number) => {
        if (value === null) {
          return 'ー'
        } else return formatNumber(value)
      },
    },
    {
      key: 7,
      title: (
        <div className="table-header">
          <div className="table-header-nonUnit">
            <span>料金削減効果</span>
          </div>
          <p>円</p>
        </div>
      ),
      dataIndex: 'consumptionWithDiscount',
      ellipsis: true,

      render: (value: number | null) => {
        if (value === null) {
          return 'ー'
        }
        if (+value < 0) {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <CaretUpOutlined style={{ color: 'red', marginLeft: '0px' }} />
              <div>{formatNumber(Math.abs(+value))}</div>
            </div>
          )
        }
        if (+value === -0) {
          return 0
        } else return <div>{formatNumber(value)}</div>
      },
    },
    {
      key: 8,
      title: (
        <div className="table-header">
          <div className="table-header-nonUnit">
            <span>買電</span>
            <p>（系統から）</p>
          </div>
          {isChangeUnit ? <p>MWh</p> : <p>kWh</p>}
        </div>
      ),
      dataIndex: 'nomalElectric',
      ellipsis: true,

      render: (value: string | number) => {
        if (value === null) {
          return 'ー'
        }
        // if (Number(value) === 0) {
        //   return ''
        // }
        if (isChangeUnit) {
          return formatNumber((+value / 1000).toFixed(3))
        } else {
          return formatNumber(value)
        }
      },
    },
  ]
  return columns
}

export const formatColumTableCo2 = (
  isChangeUnit: boolean,
  title: string,
  isIchiran?: boolean
) => {
  const columns = [
    isIchiran
      ? {
          key: 1,
          title: '',
          dataIndex: 'title',
          ellipsis: true,
          align: 'left' as const,
          width: '12%',

          render: (value: any) => {
            return (
              <>
                <Tooltip title={value}>
                  <p className="first-col">{value}</p>
                </Tooltip>
              </>
            )
          },
        }
      : {
          key: 2,
          title: title,
          dataIndex: 'title',
          ellipsis: true,
          align: 'center' as const,
          render: (value: any) => {
            if (typeof value === 'string') {
              return value
            } else {
              const formattedDate = value.toString().padStart(2, '0')
              return formattedDate
            }
          },
        },
    {
      key: 3,
      title: (
        <div className="table-header">
          <p>合計</p>
          <p>電気使用量</p>
          {isChangeUnit ? <p>MWh</p> : <p>kWh</p>}
        </div>
      ),
      dataIndex: 'gridPower',
      ellipsis: true,

      render: (value: number) => {
        if (value === null) {
          return 'ー'
        }
        if (isChangeUnit && value !== 0) {
          return formatNumber((value / 1000).toFixed(3))
        }
        return formatNumber(value)
      },
    },
    {
      key: 4,
      title: (
        <div className="table-header">
          <p>系統電力</p>
          <p>電気使用量</p>
          {isChangeUnit ? <p>MWh</p> : <p>kWh</p>}
        </div>
      ),
      dataIndex: 'gridElectricityConsumption',
      ellipsis: true,

      render: (value: any) => {
        if (value === null) {
          return 'ー'
        }
        if (isChangeUnit && value !== 0) {
          return formatNumber((value / 1000).toFixed(3))
        }
        return formatNumber(+value)
      },
    },
    {
      key: 5,
      title: (
        <div className="table-header">
          <p>MODEL-T</p>
          <p>電気使用量</p>
          {isChangeUnit ? <p>MWh</p> : <p>kWh</p>}
        </div>
      ),
      ellipsis: true,
      dataIndex: 'modelTElectricityUsage',
      render: (value: any) => {
        if (value === null) {
          return 'ー'
        }
        if (value === -0) {
          return 0
        }
        if (isChangeUnit && value !== 0) {
          return formatNumber((value / 1000).toFixed(3))
        }
        return formatNumber(+value)
      },
    },
    {
      key: 6,
      title: (
        <div className="table-header">
          <p>系統電力</p>
          <p>CO2排出量</p>
          {isChangeUnit ? <p>t-CO2</p> : <p>kg-CO2</p>}
        </div>
      ),
      ellipsis: true,
      dataIndex: 'gridPowerCo2Emissions',
      render: (value: any) => {
        if (value === null) {
          return 'ー'
        }
        if (isChangeUnit && value !== 0) {
          return formatNumber((value / 1000).toFixed(3))
        }
        return formatNumber(+value)
      },
    },
    {
      key: 7,
      title: (
        <div className="table-header">
          <p>MODEL-T</p>
          <p>CO2削減量</p>
          {isChangeUnit ? <p>t-CO2</p> : <p>kg-CO2</p>}
        </div>
      ),
      ellipsis: true,
      dataIndex: 'modelTCo2Emissions',
      render: (value: any) => {
        if (value === null) {
          return 'ー'
        }
        if (value === -0) {
          return 0
        }
        if (isChangeUnit && value !== 0) {
          return formatNumber((value / 1000).toFixed(3))
        }
        return formatNumber(+value)
      },
    },
    {
      key: 8,
      title: (
        <div className="table-header">
          <p>太陽光</p>
          <p>再エネ比率</p>
          <p>％</p>
        </div>
      ),
      ellipsis: true,
      dataIndex: 'photovoltaicRenewableEnergyRatio',
      render: (value: number) => {
        if (value === null) {
          return 'ー'
        } else {
          if (Number.isNaN(value)) {
            return 0
          } else {
            return formatNumber(value?.toFixed(1))
          }
        }
      },
    },
  ]
  return columns
}

import { useQuery } from 'react-query'
import { api } from '../Config/AxiosConfig'
const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL
//get all company
export const useFetchAllCompanies = (isDisabled?: boolean) => {
  const fetchCompanies = async () => {
    const response = await api
      .get(`${ENDPOINT}/Company/All`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['getAllCompanies'],
    queryFn: () => fetchCompanies(),
    staleTime: 10 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: isDisabled,
  })
}

//get parent Companies
export const useFetchCompanies = (
  AccountID: number | string,
  isDisabled?: boolean
) => {
  const fetchCompanies = async () => {
    const response = await api
      .get(`${ENDPOINT}/Company/MyCompanies?contactId=${AccountID}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['companies', AccountID],
    queryFn: () => fetchCompanies(),
    staleTime: 10 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!AccountID && isDisabled,
  })
}
//get nest Companies
export const useFetchNestCompanies = (listID: string[]) => {
  const fetchData = async () => {
    const response = await api
      .post(`${ENDPOINT}/Company/AllChild`, listID)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['NestCompanies', listID],
    queryFn: () => fetchData(),
    staleTime: 30 * 1000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!listID && listID.length > 0,
  })
}

// get children Companies
export const useFetchChildrenCompanies = (firstOption: string | number) => {
  const fetchCompanies = async () => {
    const response = await api
      .post(`${ENDPOINT}/Company/Child?companyId=${firstOption}`)
      .then((response) => response?.data || null)
    return response
  }
  return useQuery({
    queryKey: ['childCompanies', firstOption],
    queryFn: () => fetchCompanies(),
    staleTime: 500,
    enabled: !!firstOption && firstOption !== '選択' && firstOption !== '"',
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

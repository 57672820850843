import { useContext } from 'react'
import { ParentInfo } from '../../Context'
import { Container } from '../../Styled/Pages.style'
import ChartCo2 from './Chart'
import IchiranCo2Table from './TableDetail'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../../Utils/CustomHook/CatchErrorComponent'

interface pageProps {
  modeTime: 'year' | 'month'
  dataNestChild: any
  dataMonth?: any
  dataYear?: any
  month?: string | null
  year?: string | null
}

const IchiranCo2: React.FC<pageProps> = ({
  modeTime,
  dataNestChild,
  dataMonth,
  dataYear,
  month,
  year,
}) => {
  const nameParent: string | undefined = useContext(ParentInfo)
  return (
    <>
      <Container>
        {modeTime === 'month' && (
          <>
            <div className="Power__Detail">
              <IchiranCo2Table
                data={dataMonth}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
              />
            </div>
            <div className="Power__Chart">
              <ChartCo2
                data={dataMonth}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
                monthSelect={month ? `${month}の` : ''}
                firstName="CO2排出量"
                lastName="を表示します。"
                modeView={modeTime}
              />
            </div>
          </>
        )}
        {modeTime === 'year' && (
          <>
            <div className="Power__Detail">
              <IchiranCo2Table
                data={dataYear}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
              />
            </div>
            <div className="Power__Chart">
              <ChartCo2
                data={dataYear}
                dataNestChild={dataNestChild}
                nameParent={nameParent}
                yearSelect={year ? `${year}の` : ''}
                firstName="CO2排出量"
                lastName="を表示します。"
                modeView={modeTime}
              />
            </div>
          </>
        )}
      </Container>
    </>
  )
}

export default withErrorBoundary(IchiranCo2, {
  FallbackComponent: ErrorComponent,
})

export const SESSION_STORAGE_ITEM = Object.freeze({
  TOKEN: 'access.token',
})

export const PATH = Object.freeze({
  NOT_FOUND: '*',
  HOME: '/',
  ELECTRIC: '/electric',
  CO2: '/co2',
  INVOICE: '/invoice',
  ICHIRAN: '/ichiran',
  DOWN_ELECTRIC: '/electric/download',
  RESET_PASS: '/reset-password/:hex',
})

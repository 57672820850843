import { Round } from '../Helper/FormatNumber'

export const CalculatorYAxis = (dataMax: number) => {
  const dataRound = Round(dataMax)
  if (dataRound == 0 || dataRound == -Infinity) {
    return [0, 10]
  }
  const magnitudes = [
    1, 10, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000,
    10000000000, 100000000000, 1000000000000, 10000000000000, 100000000000000,
    1000000000000000,
  ]

  for (let i = 1; i < magnitudes.length; i++) {
    if (dataRound < magnitudes[i]) {
      const quotient = Math.floor(dataRound / magnitudes[i - 1]) + 1
      return [0, quotient * magnitudes[i - 1]]
    }
  }

  const lastMagnitude = magnitudes[magnitudes.length - 1]
  const quotient = Math.floor(dataRound / lastMagnitude) + 1
  return [0, quotient * lastMagnitude]
}

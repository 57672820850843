import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import {
  BsFillArrowDownRightCircleFill,
  BsFillArrowUpRightCircleFill,
} from 'react-icons/bs'
import styled from 'styled-components'
import Header from '../../Components/UI/Header/Header'
import { TypeAccount } from '../../Components/Context'
import Invoice from '../../Components/Invoice'
import Detail from '../../Components/Invoice/Detail'
import ShiTenInvoice from '../../Components/Invoice/ShiTenInvoice'
import {
  useFetchDataChart,
  useGetCheckDateByCompanyId,
  useGetInvoiceHistory,
} from '../../Api/Invoice'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import SelectCompaniesInv from '../../Components/Invoice/SelectCompaniesInvoice'

const handleTable = (dataChart: any[], x: any) => {
  const table = []

  for (var i = 0; i <= 11; i++) {
    let currentDate = dayjs(x)
    currentDate = currentDate.subtract(i, 'month')

    let month = +currentDate.format('MM')
    let year = +currentDate.format('YYYY')
    let line1 = null
    let line2 = null
    let date1 = null
    let date2 = null
    let energy = 0
    let startDay = null
    let endDay = null

    dataChart?.map((item: any) => {
      if (item?.checkMonth == month && item?.checkYear == year) {
        line1 = +item.grossPrice
        energy = item?.consumptionValue || 0
        date1 = dayjs(item.endDay).diff(item.startDay, 'day') + 1
        startDay = item.startDay
        endDay = item.endDay
      } else if (item?.checkMonth == month && item?.checkYear == year - 1) {
        line2 = +item.grossPrice
        date2 = dayjs(item.endDay).diff(item.startDay, 'day') + 1
        energy = item?.consumptionValue || 0
        startDay = item.startDay
        endDay = item.endDay
      }
    })

    if (line1 == line2) {
      table.push({
        month: `${month}月`,
        line1: line1,
        line2: line2,
        date1: date1,
        date2: date2,
        energy: energy,
        icon: <></>,
        startDay: startDay,
        endDay: endDay,
      })
    } else if (line1 > line2) {
      table.push({
        month: `${month}月`,
        line1: line1,
        line2: line2,
        date1: date1,
        date2: date2,
        energy: energy,
        icon: <BsFillArrowUpRightCircleFill color="red" />,
        startDay: startDay,
        endDay: endDay,
      })
    } else {
      table.push({
        month: `${month}月`,
        line1: line1,
        line2: line2,
        date1: date1,
        date2: date2,
        energy: energy,
        icon: <BsFillArrowDownRightCircleFill color="blue" />,
        startDay: startDay,
        endDay: endDay,
      })
    }
  }

  return table
}
const handleCheckDate = (dataCheckDate: any) => {
  const checkDate = dataCheckDate?.[0] || []
  let startDay = dayjs(checkDate?.startDate).format('YYYY/MM/DD')
  let endDay = dayjs(checkDate?.endDate).format('YYYY/MM/DD')
  let checkMonth = dayjs(checkDate?.endDate).format('MM')
  let checkYear = dayjs(checkDate?.endDate).format('YYYY')
  let fullData = checkDate

  return {
    startDay: startDay,
    endDay: endDay,
    checkMonth: checkMonth,
    checkYear: checkYear,
    fullData: fullData,
  }
}

const InvoicePage = () => {
  const [isDetail, setIsDetail] = useState(true)
  const { isAdmin } = useContext(TypeAccount)

  const [companyId, setCompanyId] = useState(
    isAdmin
      ? sessionStorage.getItem('secondSelected')
        ? JSON.parse(sessionStorage.getItem('secondSelected') as string)?.[1]
        : null
      : sessionStorage.getItem('firstSelected')
      ? JSON.parse(sessionStorage.getItem('firstSelected') as string)?.[1]
      : null
  )
  var date = new Date()

  const {
    data: dataAllInvoice,
    isLoading: lineChartLoading,
    isError: lineChartError,
    error: lineChartErrorMessage,
  } = useFetchDataChart(companyId, dayjs(date).format('YYYY-MM-DD'))

  const {
    data: dataCheckDate,
    isLoading: CheckDateLoading,
    isError: CheckDateError,
    error: CheckDateMessage,
  } = useGetCheckDateByCompanyId(companyId, dayjs(date).format('YYYY-MM-DD'))

  const { data: dataInvoiceHistory, isLoading } =
    useGetInvoiceHistory(companyId)

  const dataForCheckDay = CheckDateLoading ? [] : handleCheckDate(dataCheckDate)

  const dataAllInvoiceSort = dataAllInvoice?.sort(
    (a: any, b: any) => a.endDay - b.endDay
  )

  const getCurrentDate = dataAllInvoice && dataAllInvoiceSort?.[0]?.endDay

  const dataChart = handleTable(dataAllInvoice, getCurrentDate).reverse()

  return (
    <WrapperBody>
      <Header defaultActiveBtn={2} />
      <WrapperContent>
        <div
          style={{
            width: '70vw',
          }}
        >
          {isDetail ? (
            <>
              <WrapperSelect>
                <div className="aside"></div>
                <div className="select">
                  <SelectCompaniesInv setId={setCompanyId} />
                </div>
                <div className="aside"></div>
              </WrapperSelect>
              {isAdmin ? (
                <Invoice
                  setIsSelect={setIsDetail}
                  dataChart={dataChart}
                  data={dataAllInvoice}
                  companyId={companyId}
                  dataCheckDate={dataForCheckDay}
                  dataInvoiceHistory={dataInvoiceHistory}
                  lineChartLoading={lineChartLoading}
                />
              ) : (
                <ShiTenInvoice
                  setIsSelect={setIsDetail}
                  dataChart={dataChart}
                  dataInvoiceHistory={dataInvoiceHistory}
                  lineChartLoading={lineChartLoading}
                />
              )}
            </>
          ) : (
            <Detail
              setIsSelect={setIsDetail}
              companyId={companyId}
              data={dataAllInvoice}
            />
          )}
        </div>
      </WrapperContent>
    </WrapperBody>
  )
}

export default withErrorBoundary(InvoicePage, {
  FallbackComponent: ErrorComponent,
})

const WrapperBody = styled.div`
  display: block;
  margin-bottom: 12vh;
  position: relative;
`
const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const WrapperSelect = styled.div`
  display: flex;
  justify-items: center;
  .select {
    flex: 4;
  }
  .aside {
    flex: 1;
  }
`

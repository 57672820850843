import styled from 'styled-components'

export const WrapperBox = styled.div`
  margin-bottom: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 20px 40px;
  border-radius: 10px;
  .content {
    width: 100%;
    border-radius: 10px;
    display: flex;
    .left {
      flex: 1;
      flex-direction: column;
    }
    .right {
      flex: 1;
      flex-direction: column;
      .row {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
`
export const WrapperSelect = styled.div`
  display: flex;
  .select {
    flex: 4;
  }
  .aside {
    flex: 1;
  }
`

import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => navigate('/electric'), 10000)
  }, [])

  return (
    <div className="not-found">
      <p>page not found</p>
      <Link to="/electric" className="link-home">
        Go Home
      </Link>
    </div>
  )
}

export default NotFound

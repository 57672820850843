import {
  CloudDownloadOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { Button, DatePicker, Modal, notification } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import axios from 'axios'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import React, { useEffect, useRef, useState } from 'react'
import CSVReader from 'react-csv-reader'
import styled from 'styled-components'
import SurplusPowerTable from '../../Components/Admin/SurplusPower'
import TimeLine from '../../Components/UI/Header/TimeLine'
import AccountMng from '../../Components/UI/SelectCompanies'
import { useFetchSurplus, useFetchSurplusByYear } from '../../Api/Surplus'
import { disabledDate } from '../../Utils/Helper/DisableDay'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import { useQueryClient } from 'react-query'
import { api } from '../../Api/Config/AxiosConfig'

const ENDPOINT = process.env.REACT_APP_ENDPOINT_URL

const SurplusPower: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loadingUpData, setLoadingUpData] = useState<boolean>(false)
  const [loadingDownTemplate, setLoadingDownTemplate] = useState<boolean>(false)
  const [fetchOption, SetFetchOption] = useState<boolean>(false)

  const [dataReadFile, setDataReadFile] = useState<any>([])
  const [dataSend, setDataSend] = useState<any>([])
  const [modeTime, setModeTime] = useState('month')

  //select from option
  const [childSelected, setChildSelected] = useState<string>('')
  const [date, setDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-01')
  )
  const [year, setYear] = useState(dayjs().startOf('year').format('YYYY'))

  //set for button call data
  const [plantIdSelected, setPlantIdSelected] = useState('')
  const [dateCallData, setdateCallData] = useState('')
  const [yearCallData, setYearCallData] = useState('')

  //auto focus when open modal
  const fileRef = useRef<any>(null)

  const onFetchOption = (value: boolean) => {
    SetFetchOption(value)
  }

  const setSelectForCallData = (value: any) => {
    setChildSelected(value)
  }

  const handleChangeMode = (mode: string) => {
    setModeTime(mode)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    fileRef?.current?.reset()
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  //Call API
  const { data, isLoading } = useFetchSurplus(plantIdSelected, dateCallData)
  const { data: yearData, isLoading: yearLoading } = useFetchSurplusByYear(
    plantIdSelected,
    yearCallData
  )

  const queryClient = useQueryClient()
  const SendData = async () => {
    fileRef?.current?.reset()
    setLoadingUpData(true)
    if (dataReadFile?.[dataReadFile.length - 1]?.time === '') {
      notification.error({
        message: '契約が見つかりません。ご確認ください。',
        duration: 1,
        placement: 'topRight',
        style: { width: 'unset' },
      })
      fileRef?.current?.reset()
      setLoadingUpData(false)
      return
    }
    try {
      await api({
        url: `${ENDPOINT}/ResidualElectric/Insert?plantId=${
          dataReadFile?.[dataReadFile.length - 1]?.time
        }`,
        method: 'post',
        data: dataSend,
      }).then((res) => {
        if (res.data.statusCode === 'BadRequest') {
          notification.error({
            message: '契約が見つかりません。ご確認ください。',
            duration: 1,
            placement: 'topRight',
            style: { width: 'unset' },
          })
          fileRef?.current?.reset()
        } else {
          handleCloseModal()
          notification.success({
            message: 'アップロードに成功しました。',
            duration: 1,
            placement: 'topRight',
          })
        }
      })
      setLoadingUpData(false)
      queryClient.invalidateQueries({ queryKey: ['getSurplus'] })
    } catch (err) {
      alert(err)
      setLoadingUpData(false)
    }
  }

  const downTemplate = async () => {
    setLoadingDownTemplate(true)
    await api({
      method: 'post',
      url: `${ENDPOINT}/ResidualElectric/TemplateDown?plantId=${childSelected}&date=${date}`,
      responseType: 'arraybuffer',
    })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: 'text/csv',
        })
        saveAs(
          blob,
          `余剰電力_買電量30分値入力フォーム${dayjs(date).format('M')}.csv`
        )
      })
      .catch((err) => {
        notification.error({
          message:
            '契約のデータが不足しています。契約のデータを入力してください。' ||
            err,
          duration: 1,
          placement: 'topRight',
        })
      })
      .finally(() => setLoadingDownTemplate(false))
  }

  //customer require button call data
  const handleCallData = () => {
    setPlantIdSelected(childSelected)
    modeTime === 'month' ? setdateCallData(date) : setYearCallData(year)
  }

  //check template
  const CheckTemplate =
    '00:00 - 00:30,00:30 - 01:00,01:00 - 01:30,01:30 - 02:00,02:00 - 02:30,02:30 - 03:00,03:00 - 03:30,03:30 - 04:00,04:00 - 04:30,04:30 - 05:00,05:00 - 05:30,05:30 - 06:00,06:00 - 06:30,06:30 - 07:00,07:00 - 07:30,07:30 - 08:00,08:00 - 08:30,08:30 - 09:00,09:00 - 09:30,09:30 - 10:00,10:00 - 10:30,10:30 - 11:00,11:00 - 11:30,11:30 - 12:00,12:00 - 12:30,12:30 - 13:00,13:00 - 13:30,13:30 - 14:00,14:00 - 14:30,14:30 - 15:00,15:00 - 15:30,15:30 - 16:00,16:00 - 16:30,16:30 - 17:00,17:00 - 17:30,17:30 - 18:00,18:00 - 18:30,18:30 - 19:00,19:00 - 19:30,19:30 - 20:00,20:00 - 20:30,20:30 - 21:00,21:00 - 21:30,21:30 - 22:00,22:00 - 22:30,22:30 - 23:00,23:00 - 23:30,23:30 - 24:00'
  const isTemplate =
    dataReadFile?.[dataReadFile?.length - 1]?.value === CheckTemplate ||
    dataReadFile?.[0]?.value === CheckTemplate

  const isNoData = dataReadFile?.length > 1
  const isNoChildSelect = childSelected === '' || childSelected === '選択'

  const checkNoDataMonth = data?.totalValue === 0
  const checkNoDataYear = yearData?.totalValue === 0

  //handle upload file
  const handleFileUpload = (data: any) => {
    if (data) {
      const dataSlice = data?.slice(0, -1)

      const transformedData = Object.keys(dataSlice[0]).map((key) => {
        const value = dataSlice.map((item: any) => {
          return item[key]
        })
        const replaceEmptyWithNull = (arr: []) => {
          return arr.map((value: string) => {
            return value === '' ? 0 : value
          })
        }
        const x = replaceEmptyWithNull(value)
        const y = x.join(',')
        return {
          time: key,
          value: y,
        }
      })

      setDataReadFile(transformedData)
      setDataSend(transformedData.slice(0, -1))
      showModal()
    }
  }

  // notice err file
  useEffect(() => {
    if (isModalOpen) {
      if (!isTemplate) {
        notification.error({
          message: (
            <div style={{ fontSize: 12 }}>
              <p>
                アップロードしたファイルは正しくありません。ダウンロードボタンを押して、
              </p>
              <p>正しいテンプレートをダウンロードしてください。</p>
            </div>
          ),
          duration: 2,
          placement: 'topRight',
          style: { width: 550 },
        })
        handleCloseModal()
      }
      if (isTemplate && !isNoData) {
        notification.error({
          message: (
            <>
              <p>アップロードしたファイルはデータがありません。</p>
              <p>ファイルをご確認いただき、再度お試しください。</p>
            </>
          ),
          duration: 2,
          placement: 'topRight',
          style: { width: 500 },
        })
        handleCloseModal()
      }
    }
  }, [isTemplate, isNoData, isModalOpen])

  //auto focus send btn
  const btnRef = useRef<any>(null)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (isModalOpen && !loadingUpData) {
      timeoutId = setTimeout(() => {
        btnRef?.current?.focus()
      }, 100)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isModalOpen, loadingUpData])

  return (
    <>
      <UploadWrapper>
        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              style={{ opacity: isNoChildSelect ? 0.5 : 1 }}
              loading={loadingDownTemplate}
              className="UpDownSurplus"
              onClick={() => downTemplate()}
              icon={<DownloadOutlined />}
              disabled={isNoChildSelect}
            >
              ダウンロード
            </Button>
            <div style={{ position: 'relative', flex: 1 }}>
              <Button
                style={{
                  opacity: isNoChildSelect ? 0.5 : 1,
                }}
                className="UpDownSurplus"
                icon={<UploadOutlined />}
                disabled={isNoChildSelect}
              >
                アップロード
              </Button>
              {!isNoChildSelect && (
                <form style={{ cursor: 'pointer' }} ref={fileRef}>
                  <CSVReader
                    inputStyle={{ cursor: 'pointer' }}
                    onFileLoaded={handleFileUpload}
                    parserOptions={{
                      header: true,
                    }}
                  />
                </form>
              )}
            </div>
          </div>
          <AccountMng
            option={(value: any) => {
              setSelectForCallData(value)
              // handleChangeData()
            }}
            onFetching={onFetchOption}
            hideFakeBtn={true}
          />
          <TimeWrapper>
            <TimeLine
              page="Surplus"
              modeTime={modeTime}
              onChange={(mode: string) => {
                handleChangeMode(mode)
              }}
            />
            {modeTime === 'month' ? (
              <DatePicker
                style={{ height: '32px', width: 110 }}
                allowClear={false}
                locale={locale}
                onChange={(value) => {
                  setDate(dayjs(value).format('YYYY-MM-01'))
                  setYear(dayjs(value).format('YYYY'))
                }}
                picker="month"
                disabledDate={disabledDate}
                defaultValue={dayjs().startOf('month')}
              />
            ) : (
              <DatePicker
                style={{ height: '32px', width: 110 }}
                allowClear={false}
                locale={locale}
                onChange={(value) => {
                  setYear(dayjs(value).format('YYYY'))
                  setDate(dayjs(value).format('YYYY-MM-01'))
                }}
                picker="year"
                disabledDate={disabledDate}
                defaultValue={dayjs().startOf('year')}
              />
            )}
          </TimeWrapper>
          <Button
            style={{
              background: 'var(--theme-color)',
              color: '#fff',
              opacity: isNoChildSelect ? 0.4 : 1,
            }}
            loading={fetchOption || isLoading || yearLoading}
            onClick={handleCallData}
            disabled={isNoChildSelect}
          >
            出力
          </Button>
        </div>
      </UploadWrapper>
      {modeTime === 'month' && (
        <>
          {checkNoDataMonth ? (
            <NoDataWrapper>
              <p>
                {dayjs(dateCallData).format('M')}
                月のデータがありません。以下のダウンロードボタンを押して、
                {dayjs(dateCallData).format('M')}
                月のテンプレートをダウンロードしてください。
              </p>
              <Button
                loading={loadingDownTemplate}
                className="UpDownSurplus"
                onClick={() => downTemplate()}
                icon={<DownloadOutlined />}
              >
                ダウンロード
              </Button>
            </NoDataWrapper>
          ) : data ? (
            <SurplusPowerTable
              mode={modeTime}
              data={data}
              month={dateCallData}
            />
          ) : null}
        </>
      )}
      {modeTime === 'year' && (
        <>
          {checkNoDataYear ? (
            <NoDataWrapper>
              <CloudDownloadOutlined style={{ fontSize: 20 }} />
              <p>データがありません</p>
            </NoDataWrapper>
          ) : yearData ? (
            <SurplusPowerTable
              mode={modeTime}
              data={yearData}
              year={yearCallData}
            />
          ) : null}
        </>
      )}
      {isTemplate && isNoData && (
        <Modal
          title={
            <>
              <p>アップロードしたテンプレートのデータにより</p>
              <p>日毎ごとに合計を表示します。</p>
            </>
          }
          width={1000}
          open={isModalOpen}
          onCancel={handleCloseModal}
          maskClosable={!loadingUpData}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            top: '6vh',
          }}
          bodyStyle={{
            maxHeight: '70vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            marginTop: '20px',
          }}
          footer={
            <>
              <Button
                ref={btnRef}
                disabled={!(isTemplate && isNoData)}
                loading={loadingUpData}
                onClick={() => SendData()}
                type="primary"
              >
                確認
              </Button>
              <Button onClick={handleCloseModal}>キャンセル</Button>
            </>
          }
        >
          <div>
            <table style={{ borderCollapse: 'collapse', minWidth: 400 }}>
              <thead
                style={{
                  backgroundColor: 'var(--theme-color)',
                  color: '#fff',
                  position: 'sticky',
                  top: 0,
                }}
              >
                <tr>
                  <th
                    style={{
                      borderRight: '1px solid black',
                      padding: '4px 8px',
                      minWidth: '100px',
                      textAlign: 'center',
                    }}
                  >
                    日
                  </th>
                  <th
                    style={{
                      borderRight: '1px solid black',
                      padding: '4px 8px',
                      minWidth: '100px',
                      textAlign: 'center',
                    }}
                  >
                    価値（合計）
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataReadFile.slice(0, -1).map((item: any, index: number) => {
                  const values = item.value.split(',').map(parseFloat)
                  const sum = values.reduce(
                    (accumulator: number, currentValue: number) =>
                      accumulator + (isNaN(currentValue) ? 0 : currentValue),
                    0
                  )

                  return (
                    <tr key={index}>
                      <td
                        style={{
                          border: '1px solid black',
                          padding: '4px 8px',
                          minWidth: '100px',
                          textAlign: 'center',
                        }}
                      >
                        {dayjs(item.time).format('YYYY年MM月DD日')}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          padding: '4px 8px',
                          minWidth: '100px',
                          textAlign: 'end',
                          background: sum === 0 ? 'yellow' : '',
                        }}
                      >
                        {sum.toFixed(1)}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </>
  )
}

export default withErrorBoundary(SurplusPower, {
  FallbackComponent: ErrorComponent,
})

const UploadWrapper = styled.div`
  display: flex;
  gap: 20px;
  .UpDownSurplus {
    width: 200px;
    background: var(--theme-color) !important;
    color: #fff;
  }
  .csv-reader-input {
    position: absolute;
    opacity: 0;
    top: 0px;
    cursor: pointer;

    input::file-selector-button {
      cursor: pointer;
    }

    .csv-input {
      width: 165px;
      height: 32px;
      cursor: pointer;
    }
  }
`
const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .SurplusTime {
    gap: 10px;
    margin-top: 0px !important;
  }
`
const NoDataWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  .UpDownSurplus {
    margin-top: 10px;
  }
`

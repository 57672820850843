import { FileTextOutlined, RightOutlined } from '@ant-design/icons'
import { useContext } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'
import { ErrorComponent } from '../../Utils/CustomHook/CatchErrorComponent'
import { TypeAccount } from '../Context'
import { WrapperBox } from '../Styled/Invoice.style'
import InvoiceHistory from './InvoiceHistory'
import InvoiceChart from './LineChart'

interface Props {
  setIsSelect: any
  setCompanyId?: any

  dataChart: any
  dataInvoiceHistory?: any
  lineChartLoading: boolean
}

const ShiTenInvoice = ({
  setIsSelect,
  dataChart,

  dataInvoiceHistory,
  lineChartLoading,
}: Props) => {
  const { isAdmin } = useContext(TypeAccount)

  return (
    <>
      <h3>電気料金</h3>
      <div>
        <WrapperBox>
          <InvoiceChart
            dataChart={dataChart}
            isSuperAdmin={isAdmin}
            lineChartLoading={lineChartLoading}
          />
        </WrapperBox>
        <h3>もっとくわしく料金を見る</h3>

        <ViewMoreForm>
          <div onClick={() => setIsSelect(false)}>
            <div>
              <FileTextOutlined /> <span>料金明細</span>
            </div>
            <RightOutlined />
          </div>
        </ViewMoreForm>
        <h3>電気の請求履歴</h3>
        <InvoiceHistory dataInvoice={dataInvoiceHistory} />
        <h3>注意事項</h3>
        <ul>
          <li>
            引越し、名義変更、支払者変更の際は、セキュリティ保護の観点から、以降の請求額や使用量が表示されなくなります。
            myTOKYOGASマイページの「お
            引越しされた場合・お客さま番号の追加」からお客さま番号の再登録をお願いいたします。
          </li>
          <li>電気料金は複数まとめて請求されることがあります。</li>
          <li>
            スマートメーターの通信状況や、送配電事業者からのデータ連携
            により、一部時間帯の電力使用量が一時的に表示されない場合があります。
          </li>
          <li>
            ガス・電気セット割適用の場合、
            電気料金は、電気の計量後､一定期間を経た後に到来するガスの検針日に、
            ガス料金と合算して請求するため、ガス料金
            の確定と同時に表示されます。 詳細はこちら。
          </li>
          <li>
            電気料金の訂正等により、
            料金明細やグラフが正しく表示されない、または検針票と異なる場合があります。
            ただし、使用量･使用期間は以下の方法で 認できます。
          </li>
          <li>
            「請求｣ 「ガス」 「電気」
            ページの下部にある「使用量と請求額をダウンロード」の「CSVダウンロード」。
            .
          </li>
          <li>
            使用期間 料金明細」ページに表示される日付は、原則として、
            ガス･電気合算のお客さまはガス検針日を、
            電気料金が単独で請求されているお客 . さまは電気計量を示しています。
          </li>
          <li>
            2023年2月検針分単価から、政府支援のガス▲30円/m3・低圧電気▲7円/KWhを適用しております。
            適用には条件があります。 詳細はこちらをご 確認ください。
          </li>
        </ul>
      </div>
    </>
  )
}

export default withErrorBoundary(ShiTenInvoice, {
  FallbackComponent: ErrorComponent,
})

const ViewMoreForm = styled.div`
  margin-bottom: 40px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  & > div {
    display: flex;
    /* flex: 1; */
    width: 50%;
    justify-content: space-between;
    text-align: center;
    padding: 12px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
  cursor: pointer;
  .download-invoice-btn {
    border: none;
    width: 100%;
    text-align: start;
    box-shadow: none;
  }
`
